import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

const Profile = ({ userInfo }) => {

  return (
      <div className="container">
          <header className="jumbotron">
              <h3><strong>Profile: </strong>{userInfo.username}</h3>
          </header>
          <p><strong>Id: </strong>{userInfo.uid}</p>
          <p><strong>Email:</strong> {userInfo.profile.email || "Not set"}</p>
          <strong>Authorities:</strong>
          <ul>
              {userInfo.isAdmin && <li>Administrator</li>}
              {userInfo.isStaff && <li>Staff</li>}
              {userInfo.isStudent && <li>Student</li>}
          </ul>
    </div>
  );
};

Profile.propTypes = {
    userInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
