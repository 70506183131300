import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {MdOutlineDeleteOutline} from "react-icons/md";

import {ConnectedForm} from "../../helpers/with-form-hook";
import getBase64 from "../../helpers/convertImageToBase64";

import {
    SET_HOMEWORK_CONFIG,
} from "../../redux/actions/configurator-actions";


class ImageUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image: null,
            maxNumber: 1
        }
    }

    componentDidMount() {
        this.setState({
            image: this.props.config.image ? [{ data_url: this.props.config.image }] : []
        })
    }

    onChange = async (event) => {
        if(event.target.files.length > 0){
            let dataUrl = await getBase64(event.target.files[0])
            this.props.setHomeworkConfig({'image': dataUrl})
        } else {
            this.props.setHomeworkConfig({'image': null})
        }
    };

    removeImage = () => {
        this.props.setHomeworkConfig({'image': null})
    }


    render(){

        return (
            <>
                {this.props.config.image ? (
                    <div className="flex flex-row h-40">
                        <img src={this.props.config.image} alt="" />
                        <div className="flex flex-col ml-2">
                            <MdOutlineDeleteOutline
                                className="cursor-pointer w-6 h-6"
                                onClick={this.removeImage}
                            />
                        </div>
                    </div>
                ): (
                    <ConnectedForm>
                        {({ register, formState: { errors } }) =>
                            <>
                                <label
                                    className="w-64 flex flex-col items-center px-4 py-6 bg-white text-brandBlue rounded-lg shadow-lg tracking-wide uppercase border border-brandBlue cursor-pointer hover:bg-brandBlue hover:text-white">
                                    <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20">
                                        <path
                                            d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"/>
                                    </svg>
                                    <span className="mt-2 text-base leading-normal">Select a file</span>
                                    <input
                                        className="hidden"
                                        type="file"
                                        name="image"
                                        {...register("image", {
                                            required: this.props.config.withMedia &&  this.props.config.isVideo,
                                            onChange: (e) => this.onChange(e),
                                        })}
                                    />
                                </label>
                                <label className="label">
                                    {errors.image && <span className="label-text-alt">Upload file</span>}
                                </label>
                            </>
                        }
                    </ConnectedForm>
                )}

            </>
        );
    }
}

ImageUpload.propTypes = {
    config: PropTypes.object,
    image: PropTypes.string,
    tab: PropTypes.string,
    setHomeworkConfig: PropTypes.func,
}

const mapStateToProps = (state) => ({
    config: state.config.config,
    image: state.config.image

})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfig: (params) => dispatch({ type: SET_HOMEWORK_CONFIG, payload: params }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload)
