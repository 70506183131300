import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import {
    CREATE_SPRINT,
    CLEAR_CREATE_SPRINT_ERROR,
} from '../../../redux/actions/sprint-actions'

import {
    HIDE_MODAL
} from "../../../redux/actions/common-actions";

import {withUseFormHook} from "../../../helpers/with-form-hook";
import moment from "moment/moment";


class CreateSprintModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.loadingCreateSprint && !this.props.loadingCreateSprint && this.props.createSprintError === null ) {
            this.props.hideModal('createSprint')
        }
    }

    onSubmit = (data) => {
        // data['week'] = moment(data.startDate, "YYYY-MM-DD").isoWeek();
        this.props.createSprint(data)
    }

    onCancel = (e) => {
        e.preventDefault()
        this.props.hideModal('createSprint')
        this.props.clearCreateSprintError()
    }

    render() {
        const modalStyle = this.props.modal ? 'modal modal-open' : "modal"
        let loadingCategories = this.props.loadingCategories
        let loadingCreateSprint = this.props.loadingCreateSprint
        let getCategoriesError = this.props.getCategoriesError
        let createSprintError = this.props.createSprintError

        const { register, handleSubmit, formState: { errors } } = this.props.form;

        return (
            <React.Fragment>
                <div className={modalStyle}>
                    <div className="modal-box">
                        <div className={"text-xl font-bold"}>Add a new sprint</div>
                        <div className="divider dark" />
                        { loadingCategories ? (<p> Loading... </p>) : (
                            getCategoriesError ? (
                                <p>{ getCategoriesError }</p>
                            ) : (
                                <form>
                                    <div className="form-control mb-2">
                                        <label className="label">
                                            <span className="label-text">Title</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="title"
                                            placeholder="What needs to be done..."
                                            className={`input input-bordered ${errors.title && 'input-error'}`}
                                            {...register("title", { required: true })}
                                        />
                                        <label className="label">
                                            {errors.title && <span className="label-text-alt">Data is incorrect</span>}
                                        </label>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="form-control w-1/2 mr-2">
                                            <label className="label">
                                                <span className="label-text">Start Date</span>
                                            </label>
                                            <input
                                                type="date"
                                                name="startDate"
                                                className={`w-full input input-bordered ${errors.startDate && 'input-error'}`}
                                                {...register("startDate", { required: true })}
                                            />
                                            <label className="label">
                                                {errors.startDate &&<span className="label-text-alt">Data is incorrect</span>}
                                            </label>
                                        </div>
                                        <div className="form-control w-1/2 ml-2">
                                            <label className="label">
                                                <span className="label-text">End Date</span>
                                            </label>
                                            <input
                                                type="date"
                                                name="endDate"
                                                className={`w-full input input-bordered ${errors.endDate && 'input-error'}`}
                                                {...register("endDate", { required: true })}
                                            />
                                            <label className="label">
                                                {errors.endDate && <span className="label-text-alt">Data is incorrect</span>}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-control my-2">
                                        <label className="label">
                                            <span className="label-text">Category</span>
                                        </label>
                                        <select
                                            name="categoryId"
                                            className={`select select-bordered ${errors.categoryId && 'select-error'}`}
                                            defaultValue={'DEFAULT'}
                                            {...register("categoryId", { required: true,  validate: (value) => value !== "DEFAULT" })}
                                        >
                                            <option value="DEFAULT" disabled>Choose sprint type</option>
                                            {this.props.categories.map((category, index) => {
                                                return <option key={'category-'+index} value={category.id}>{category.title}</option>
                                            })}
                                        </select>
                                        <label className="label">
                                            {errors.categoryId && <span className="label-text-alt">Data is incorrect</span>}
                                        </label>
                                    </div>

                                </form>
                            )
                        )}
                        <div className="divider dark" />
                        {createSprintError ? (
                            <div className="alert shadow-lg alert-error my-4">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                         fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                    <span className="ml-4">Error! { createSprintError }.</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="modal-action">
                            {!getCategoriesError && !loadingCategories ? (
                                <button onClick={handleSubmit(this.onSubmit)} className={`btn btn-primary ${loadingCreateSprint ? 'loading' : ''}`}>Submit</button>
                            ) : null}
                            <button onClick={this.onCancel} className="btn">Close</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CreateSprintModal.propTypes = {
    loadingCategories: PropTypes.bool,
    loadingCreateSprint: PropTypes.bool,
    getCategoriesError: PropTypes.string,
    createSprintError: PropTypes.string,
    form: PropTypes.any,
    categories: PropTypes.array,
    modal: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
    createSprint: PropTypes.func.isRequired,
    clearCreateSprintError: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    loadingCategories: state.sprint.loadingCategories,
    loadingCreateSprint: state.sprint.loadingCreateSprint,
    getCategoriesError: state.sprint.getCategoriesError,
    createSprintError: state.sprint.createSprintError,
    categories: state.sprint.categories,
    modal: state.common.modal
})

const mapDispatchToProps = (dispatch) => ({
    hideModal: (modalType) => dispatch({ type: HIDE_MODAL, payload: modalType }),
    createSprint: (sprint) => dispatch({ type: CREATE_SPRINT, payload: sprint }),
    clearCreateSprintError: () => dispatch({ type: CLEAR_CREATE_SPRINT_ERROR }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(CreateSprintModal))
