import React from "react";
import PropTypes from "prop-types";
import {SHOW_MODAL} from "../redux/actions/common-actions";
import {connect} from "react-redux";
import Stat from "../components/elements/Stat";
import StudentsTable from "../components/Students/StudentsTable";
import {GET_STUDENTS, RESET_STUDENTS_DATA} from "../redux/actions/students-actions";
import Pagination from "../components/elements/pagination/Pagination";

class StudentsContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchValue: '',
            filterValue: '',
            currentPage: 1,
        }
    }

    componentWillUnmount() {
        this.props.resetStudentsData()
    }

    setSearchValue = (event) => {
        this.setState({
            searchValue: event.target.value
        })
    }

    setFilterValue = (event) => {
        this.setState({
            filterValue: event.target.value
        })

        const query = {
            'search': this.state.searchValue,
            'profile__status': event.target.value,
            'is_student': true,
            'page_size': this.props.pageSize,
            'page': 1
        }

        this.props.getStudents(query)
    }

    searchFetch = () => {
        const query = {
            'search': this.state.searchValue,
            'profile__status': this.state.filterValue,
            'is_student': true,
            'page_size': this.props.pageSize,
            'page': 1
        }

        this.props.getStudents(query)
    }

    onPageChanged = data => {
        const { currentPage, totalPages, pageLimit } = data;

        const query = {
            'search': this.state.searchValue,
            'profile__status': this.state.filterValue,
            'is_student': true,
            'page_size': pageLimit,
            'page': currentPage
        }
        this.props.getStudents(query)
        this.setState({ currentPage, totalPages });
    }

    render() {

        const loading = this.props.loadingStudents

        return (
            <>
                <div className="flex flex-col drawer-content">
                    <h1 className="prose-2xl mb-2">Stat</h1>
                    <div className="w-full shadow stats mb-10">
                        <Stat />
                        <Stat />
                        <Stat />
                    </div>
                    <div className={"flex flex-row items-center"}>
                        <h1 className="prose-2xl mb-2 mr-3">Students</h1>
                    </div>
                    <div className={"flex flex-row justify-start items-end mb-3"}>
                        <div className="form-control">
                            <div className="input-group">
                                <input
                                    type="text"
                                    placeholder="Search…"
                                    className="input input-bordered"
                                    onChange={this.setSearchValue}
                                />
                                <button
                                    className={`btn btn-square ${loading ? 'loading' : ''}`}
                                    onClick={this.searchFetch}
                                >
                                    {loading ? null : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="form-control w-full max-w-xs ml-4">
                            <span className="label-text">Filter by status</span>
                            <select
                                defaultValue={''}
                                className="select select-bordered"
                                onChange={this.setFilterValue}
                            >
                                <option disabled>Filter by status</option>
                                <option value={''}>All</option>
                                <option value={'active'}>Active</option>
                                <option value={'freeze'}>Freeze</option>
                            </select>
                        </div>
                    </div>

                    <StudentsTable />

                    <div className="flex flex-grow-1 justify-center mt-4">
                        <Pagination
                            totalRecords={this.props.studentsTotalRecords}
                            pageLimit={this.props.pageSize}
                            pageNeighbours={1}
                            onPageChanged={this.onPageChanged}
                        />
                    </div>
                </div>
            </>

        );
    }
}

StudentsContainer.propTypes = {
    modal: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    loadingStudents: PropTypes.bool,
    resetStudentData: PropTypes.func,
    getStudents: PropTypes.func.isRequired,
    studentsTotalRecords: PropTypes.number,
    pageSize: PropTypes.number
}

const mapStateToProps = (state) => ({
    modal: state.common.modal,
    loadingStudents: state.students.loadingStudents,
    studentsTotalRecords: state.pagination.studentsTotalRecords,
    pageSize: state.pagination.pageSize
})


const mapDispatchToProps = (dispatch) => ({
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
    getStudents: (query) => dispatch({ type: GET_STUDENTS, payload: query }),
    resetStudentsData: () => dispatch({ type: RESET_STUDENTS_DATA })
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentsContainer)
