import {
    SET_FTF_PAIR_ID,
    UPDATE_FTF_PAIR_REQUEST,
    UPDATE_FTF_PAIR_SUCCESS,
    UPDATE_FTF_PAIR_ERROR,
    GET_FTF_PAIRS_SUCCESS,
    GET_FTF_PAIRS_REQUEST,
    GET_FTF_PAIRS_ERROR,
    CREATE_FTF_PAIRS_REQUEST,
    CREATE_FTF_PAIRS_SUCCESS,
    CREATE_FTF_PAIRS_ERROR,
} from "../actions/ftf-pairs-actions";

const initialState = {
    loadingFtfPairs: false,
    loadingCreateFtfPairs: false,
    getFtfPairsError: null,
    createFtfPairsError: null,
    pairs: null,
    hasPairs: false,
    ftfPairId: ''
}

export default function ftfPairsReducer(state = initialState, {type, payload}) {

    switch(type) {

        case GET_FTF_PAIRS_REQUEST:
            return {
                ...state,
                loadingFtfPairs: true,
                getFtfPairsError: false,
            }

        case GET_FTF_PAIRS_SUCCESS:
            return {
                ...state,
                loadingFtfPairs: false,
                pairs: payload.length > 0 ? payload : null,
                getFtfPairsError: null,
                hasPairs: payload.length > 0
            }

        case GET_FTF_PAIRS_ERROR:
            return {
                ...state,
                loadingFtfPairs: false,
                pairs: null,
                getFtfPairsError: 'Can`t get ftf pairs. Try again later',
                hasPairs: false
            }

        case CREATE_FTF_PAIRS_REQUEST:
            return {
                ...state,
                loadingCreateFtfPairs: true,
                createFtfPairsError: null,
            }

        case CREATE_FTF_PAIRS_SUCCESS:
            return {
                ...state,
                loadingCreateFtfPairs: false,
                pairs: payload.length > 0 ? payload : null,
                createFtfPairsError: null,
                hasPairs: payload.length > 0
            }

        case CREATE_FTF_PAIRS_ERROR:
            return {
                ...state,
                loadingCreateFtfPairs: false,
                pairs: null,
                createFtfPairsError: 'Can`t create ftf pairs. Try again later',
                hasPairs: false
            }

        case UPDATE_FTF_PAIR_REQUEST:
            return {
                ...state,
                loadingUpdateFtfPair: true,
                updateFtfPairError: null,
            }

        case UPDATE_FTF_PAIR_SUCCESS:
            return {
                ...state,
                pairs: state.pairs.map((pair) => {
                    if(pair.id === payload.id) {
                        pair = payload
                    }
                    return pair
                }),
                loadingUpdateFtfPair: false,
                updateFtfPairError: null
            }

        case UPDATE_FTF_PAIR_ERROR:
            return {
                ...state,
                loadingUpdateFtfPair: false,
                updateFtfPairError: 'Can`t update ftf pair. Try again later'
            }

        case SET_FTF_PAIR_ID:
            return {
                ...state,
                ftfPairId: payload
            }

        default:
            return state
    }
}
