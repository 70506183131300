import {homeworkApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const homeworkClient = client(`${homeworkApiUrl}/api`)

// Get FTF
export const getFtfBySprint = async (id) => {
    try {
        const response = await homeworkClient.get(`/ftf/${id}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const createNewFtf = async (ftf) => {
    try {
        let data = snakecaseKeys(ftf)
        const response = await homeworkClient.post('/ftf/', data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const updateExistFtf = async (ftf) => {
    try {
        let data = snakecaseKeys(ftf)
        const ftfData = await homeworkClient.put(`/ftf/${ftf.id}/`, data)
        return camelcaseKeys(ftfData.data, {deep: true})
    } catch(err) {
        return console.error(err)
    }
}

export const deleteExistedFtf = async (id) => {
    try {
        await homeworkClient.delete(`/ftf/${id}/`)
        return id
    } catch(err) {
        return console.error(err)
    }
}
