import {homeworkApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const homeworkClient = client(`${homeworkApiUrl}/api`)

// Get All Homeworks
export const getAllHomeworksBySprint = async (id) => {
    try {
        const response = await homeworkClient.get(`/sprints/${id}/homeworks/`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

// Get Homework By Id
export const getHomeworkById = async (id) => {
    try {
        const response = await homeworkClient.get(`/homeworks/${id}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

// Create New Homework
export const createNewHomework = async (homework) => {
    try {
        let data = snakecaseKeys(homework)
        const response = await homeworkClient.post('/homeworks/', data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

// Update exist Homework
export const updateExistHomework = async (homework) => {
    try {
        let data = snakecaseKeys(homework)
        const response = await homeworkClient.put(`/homeworks/${homework.id}/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

// Delete existed Homework
export const deleteExistedHomework = async (id) => {
    try {
        await homeworkClient.delete(`/homeworks/${id}/`)
        return id
    } catch(err) {
        return console.error(err)
    }
}

// Get Answers By HomeworkId
export const getAnswers = async (homeworkId) => {
    try {
        const response = await homeworkClient.get(`/answers/?homework=${homeworkId}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}
