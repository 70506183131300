import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {UPDATE_OPTION} from "../../../redux/actions/preference-actions";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

const SprintScheduleConfirmationModal = ({ openModal, setOpenModalState, enabled, setEnabled, option, updateOption, loadingUpdateOption }) => {

    const prevLoadingState = usePrevious(loadingUpdateOption)

    const handleButton = () => {
        updateOption({id: option.id, value: Number(!enabled).toString(), code:option.code})
    }

    useEffect(()=>{
        if(prevLoadingState === true && loadingUpdateOption === false){
            setOpenModalState(false)
        }
    }, [loadingUpdateOption])

    const content = [
        // Confirm Sprints Cancellation
        <>
            <h3 className="font-bold text-lg">Confirm Sprints Cancellation</h3>
            <p className={"py-4"}>
                <span className={"text-error font-bold"}>Warning</span>:
                If you turn off the sprints, it will stop all student subscriptions. Subscriptions will resume when this switch is turned on
            </p>
        </>,
        // Confirm Sprints Started
        <>
            <h3 className="font-bold text-lg">Confirm Sprints Started</h3>
            <p className={"py-4"}>
                <span className={"text-error font-bold"}>Warning</span>:
                If you turn on the sprints, it will resume all student subscriptions. Subscriptions will stop when this switch is turned off
            </p>
        </>
    ]

    return (
        <React.Fragment>
            {/* Put this part before </body> tag */}
            <div className={`${openModal ? 'modal modal-open' : "modal"}`}>
                <div className="modal-box">
                    {enabled ? content[0] : content[1]}
                    <div className={"flex flex-row justify-end space-x-3"}>
                        <div className="modal-action">
                            <label
                                htmlFor="my-modal"
                                className={`btn ${loadingUpdateOption ? 'loading' : null}`}
                                onClick={handleButton}
                            >
                                Confirm
                            </label>
                        </div>
                        <div
                            className="modal-action"
                        >
                            <label
                                htmlFor="my-modal"
                                className="btn"
                                onClick={() => {
                                    setOpenModalState(false)
                                }}
                            >
                                Cancel
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

SprintScheduleConfirmationModal.propTypes = {
    openModal: PropTypes.bool,
    setOpenModalState: PropTypes.func,
    enabled: PropTypes.bool,
    setEnabled: PropTypes.func,
    option: PropTypes.object,
    loadingUpdateOption: PropTypes.bool
}

const mapStateToProps = (state) => ({
    loadingUpdateOption: state.preference.loadingUpdateOption
})

const mapDispatchToProps = (dispatch) => ({
    updateOption: (option) => dispatch({ type: UPDATE_OPTION, payload: option }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SprintScheduleConfirmationModal)