// TODO: REMOVE AFTER FINISH
export const SET_LOADING = "SET_LOADING"

// TODO: COMPLETE
export const GET_SPRINTS = "GET_SPRINTS"
export const GET_SPRINTS_REQUEST = "GET_SPRINTS_REQUEST"
export const GET_SPRINTS_SUCCESS = "GET_SPRINTS_SUCCESS"
export const GET_SPRINTS_ERROR = "GET_SPRINTS_ERROR"

// TODO: COMPLETE
export const GET_SPRINT = "GET_SPRINT"
export const GET_SPRINT_REQUEST = "GET_SPRINT_REQUEST"
export const GET_SPRINT_SUCCESS = "GET_SPRINT_SUCCESS"
export const GET_SPRINT_ERROR = "GET_SPRINT_ERROR"

// TODO: COMPLETE
export const UPDATE_SPRINT = "UPDATE_SPRINT"
export const UPDATE_SPRINT_REQUEST = "UPDATE_SPRINT_REQUEST"
export const UPDATE_SPRINT_SUCCESS = "UPDATE_SPRINT_SUCCESS"
export const UPDATE_SPRINT_ERROR = "UPDATE_SPRINT_ERROR"

export const GET_CATEGORIES = "SET_CATEGORIES"
export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR"

export const CREATE_SPRINT = "CREATE_SPRINT"
export const CREATE_SPRINT_REQUEST = "CREATE_SPRINT_REQUEST"
export const CREATE_SPRINT_SUCCESS = "CREATE_SPRINT_SUCCESS"
export const CREATE_SPRINT_ERROR = "CREATE_SPRINT_ERROR"
export const CLEAR_CREATE_SPRINT_ERROR = "CLEAR_CREATE_SPRINT_ERROR"

// TODO: IN PROGRESS
export const DELETE_SPRINT = "DELETE_SPRINT"
export const DELETE_SPRINT_REQUESTED = "DELETE_SPRINT_REQUESTED"

export const SET_CURRENT_SPRINT = "SET_CURRENT_SPRINT"
export const SET_CURRENT_SPRINT_REQUESTED = "SET_CURRENT_SPRINT_REQUESTED"
