import {
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
    SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA,
    SET_HOMEWORK_CONFIG,
    SET_EMPTY_HOMEWORK_CONFIG,
    GET_CONFIG_BY_HOMEWORK_REQUEST,
    GET_CONFIG_BY_HOMEWORK_SUCCESS,
    GET_CONFIG_BY_HOMEWORK_ERROR,
    CREATE_HOMEWORK_CONFIG_REQUEST,
    CREATE_HOMEWORK_CONFIG_SUCCESS,
    CREATE_HOMEWORK_CONFIG_ERROR,
    UPDATE_HOMEWORK_CONFIG_SUCCESS,
    UPDATE_HOMEWORK_CONFIG_ERROR,
    UPDATE_HOMEWORK_CONFIG_REQUEST
} from '../actions/configurator-actions'

const initialState = {
    loadingGetHomeworkConfig: false,
    getHomeworkConfigError: null,
    loadingCreateUpdateHomeworkConfig: false,
    createUpdateHomeworkConfigError: null,
    config: {
        title: '',
        type: 'fill',
        videoLink: null,
        startTimeMin: 0,
        startTimeSec: 0,
        startTime: 0,
        endTimeMin: 0,
        endTimeSec: 0,
        endTime: 0,
        image: null,
        background: null,
        withMedia: true,
        isImage: false,
        isVideo: true,
        isExist: false
    },
    assignment: [],
    editorRawData: { blocks: [], entityMap: {} },
    homeworkId: '',
}

export default function configReducer(state = initialState, {type, payload}) {
    let isVideo = false;
    let isImage = false;

    switch(type) {

        case GET_CONFIG_BY_HOMEWORK_REQUEST:
            return {
                ...state,
                loadingGetHomeworkConfig: true,
                getHomeworkConfigError: null
            }

        case GET_CONFIG_BY_HOMEWORK_SUCCESS:
            isVideo = false;
            isImage = false;

            if(payload.media !== null && payload.media.type === 'video') {
                isVideo = true
            }
            if(payload.media !== null && payload.media.type === 'image') {
                isImage = true
            }

            return {
                ...state,
                config: {
                    title: payload.homework.title,
                    type: payload.type,
                    videoLink: isVideo ? payload.media.videoLink : null,
                    startTimeMin: isVideo ? payload.media.startTimeMin : 0,
                    startTimeSec: isVideo ? payload.media.startTimeSec : 0,
                    startTime: isVideo ? payload.media.startTimeMin * 60 + payload.media.startTimeSec : 0,
                    endTimeMin: isVideo ? payload.media.endTimeMin : 0,
                    endTimeSec: isVideo ? payload.media.endTimeSec : 0,
                    endTime: isVideo ? payload.media.endTimeMin * 60 + payload.media.endTimeSec : 0,
                    image: isImage ? payload.media.image : null,
                    background: payload.background,
                    withMedia: isImage || isVideo,
                    isImage: isImage,
                    isVideo: isVideo,
                    isExist: true,
                },
                assignment: payload ? payload.assignment : initialState.assignment,
                editorRawData: payload ? payload.editorRawData : initialState.editorRawData,
                loadingGetHomeworkConfig: false,
                getHomeworkConfigError: null,
                loadingCreateUpdateHomeworkConfig: false,
                createUpdateHomeworkConfigError: null,
            }

        case GET_CONFIG_BY_HOMEWORK_ERROR:
            return {
                ...state,
                loadingGetHomeworkConfig: false,
                getHomeworkConfigError: 'Can`t get homework config. Try again later'
            }

        case SET_EMPTY_HOMEWORK_CONFIG:
            console.log('set empty homework config')
            return {
                ...state,
                config: {
                    title: null,
                    type: 'fill',
                    videoLink: null,
                    startTimeMin: 0,
                    startTimeSec: 0,
                    startTime: 0,
                    endTimeMin: 0,
                    endTimeSec: 0,
                    endTime: 0,
                    image: null,
                    background: null,
                    withMedia: true,
                    isImage: false,
                    isVideo: true,
                    isExist: false,
                },
                assignment: initialState.assignment,
                editorRawData: initialState.editorRawData,
                loadingGetHomeworkConfig: false,
                getHomeworkConfigError: null,
                loadingCreateUpdateHomeworkConfig: false,
                createUpdateHomeworkConfigError: null,
            }

        case SET_HOMEWORK_CONFIG:
            return {
                ...state,
                config: {...state.config, ...payload}
            }

        case SET_HOMEWORK_CONFIG_ASSIGNMENT:
            return {
                ...state,
                assignment: payload
            }

        case SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA:

            return {
                ...state,
                editorRawData: payload
            }

        case CREATE_HOMEWORK_CONFIG_REQUEST:
            return {
                ...state,
                loadingCreateUpdateHomeworkConfig: true,
                createUpdateHomeworkConfigError: null,
            }

        case CREATE_HOMEWORK_CONFIG_SUCCESS:
            isVideo = false;
            isImage = false;

            if(payload.media !== null && payload.media.type === 'video') {
                isVideo = true
            }
            if(payload.media !== null && payload.media.type === 'image') {
                isImage = true
            }

            return {
                ...state,
                config: {
                    title: payload.homework.title,
                    type: payload.type,
                    videoLink: isVideo ? payload.media.videoLink : null,
                    startTimeMin: isVideo ? payload.media.startTimeMin : 0,
                    startTimeSec: isVideo ? payload.media.startTimeSec : 0,
                    startTime: isVideo ? payload.media.startTimeMin * 60 + payload.media.startTimeSec : 0,
                    endTimeMin: isVideo ? payload.media.endTimeMin : 0,
                    endTimeSec: isVideo ? payload.media.endTimeSec : 0,
                    endTime: isVideo ? payload.media.endTimeMin * 60 + payload.media.endTimeSec : 0,
                    image: isImage ? payload.media.image : null,
                    background: payload.background,
                    withMedia: isImage || isVideo,
                    isImage: isImage,
                    isVideo: isVideo,
                    isExist: true,
                },
                assignment: payload ? payload.assignment : initialState.assignment,
                editorRawData: payload ? payload.editorRawData : initialState.editorRawData,
                loadingCreateUpdateHomeworkConfig: false,
                createUpdateHomeworkConfigError: null,
            }

        case CREATE_HOMEWORK_CONFIG_ERROR:
            return {
                ...state,
                loadingCreateUpdateHomeworkConfig: false,
                createUpdateHomeworkConfigError: 'Can`t create homework config. Try again later'
            }

        case UPDATE_HOMEWORK_CONFIG_REQUEST:
            return {
                ...state,
                loadingCreateUpdateHomeworkConfig: true,
                createUpdateHomeworkConfigError: null,
            }

        case UPDATE_HOMEWORK_CONFIG_SUCCESS:

            return {
                ...state,
                loadingCreateUpdateHomeworkConfig: false,
                createUpdateHomeworkConfigError: null,
            }

        case UPDATE_HOMEWORK_CONFIG_ERROR:
            return {
                ...state,
                loadingCreateUpdateHomeworkConfig: false,
                createUpdateHomeworkConfigError: 'Can`t update homework config. Try again later'
            }

        default:
            return state
    }
}
