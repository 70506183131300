import React from 'react'
import {connect} from "react-redux";
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import {getBackground} from "../../helpers/getBackground";

import {
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
} from "../../redux/actions/configurator-actions";


import {
    FiCheck,
} from "react-icons/fi";

import Player from "./Player";
import Image from "./Image";

class FillPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

    }

    handleSave = ({ name, value, previousValue }) => {
        const parseName = name.split('-')
        const rowIndex = parseInt(parseName[1])
        const elementIndex = parseInt(parseName[2])

        let answers = [...this.props.assignment]

        if (value.toLowerCase().trim() !== answers[rowIndex][elementIndex].text.toLowerCase().trim()) {
            answers[rowIndex][elementIndex].color = 'red'
            this.props.setHomeworkConfigAssignment(answers)
        }
    };

    // handleChange = (value, rowIndex, elementIndex) => {
    //     let results = [...this.props.assignment]
    //     results[rowIndex][elementIndex].studentAnswer = value.toLowerCase().trim()
    //     results[rowIndex][elementIndex].result = value
    //     this.props.setHomeworkConfigAssignment(results)
    // }

    render() {

        let assignment = this.props.assignment ? this.props.assignment : []

        return (
            <React.Fragment>

                <div className="pt-10 px-4 flex flex-col">
                    <>
                        { this.props.config.isVideo ? (
                            <Player />
                        ) : null }

                        { this.props.config.isImage ? (
                            <Image />
                        ) : null }
                    </>
                </div>

                {this.props.config.withMedia ? (<div className="divider h-2" />) : null}

                {/*{ this.props.config.type === 'fill' && this.props.config.isVideo ?*/}
                {/*    (*/}
                {/*        <article className="px-4 prose prose-slate prose-xl">*/}
                {/*            <h4>Listen and fill in the gaps</h4>*/}
                {/*        </article>*/}
                {/*    ) : null*/}
                {/*}*/}

                {/*{ this.props.config.type === 'fill' && this.props.config.isImage ?*/}
                {/*    (*/}
                {/*        <article className="px-4 prose prose-slate prose-xl">*/}
                {/*            <h4>Find the words that mean</h4>*/}
                {/*        </article>*/}
                {/*    ) : null*/}
                {/*}*/}

                {/*{ this.props.config.type === 'fill' && !this.props.config.isImage && !this.props.config.isVideo?*/}
                {/*    (*/}
                {/*        <article className={`px-4 prose prose-slate prose-xl ${this.props.config.withMedia ? '' : 'mt-10'}`}>*/}
                {/*            <h4>Read and fill in the gaps</h4>*/}
                {/*        </article>*/}
                {/*    ) : null*/}
                {/*}*/}


                <div className={"mt-5 pb-10 flex flex-col text-justify px-4"}>
                    { assignment.map((row, rowIndex) => {
                        return <div key={`row-${rowIndex}`} className={"break-words inline-block text-xl"}>
                            {row.map((element, elementIndex) => {
                                if (element.isAnswer) {
                                    return <EditText
                                        key={`element-${rowIndex}-${elementIndex}`}
                                        name={`element-${rowIndex}-${elementIndex}`}
                                        type="text"
                                        style={{
                                            width: element.result.length > 2 ? element.result.length.toString() + 'ch' : '3ch',
                                            color: element.result !== '' ? element.color : 'teal',
                                            opacity: 0.8,
                                            border: "none",
                                            paddingLeft: 3,
                                            paddingRight: 0
                                        }}
                                        placeholder={this.props.type === 'put' ? element.correct : 'type here'}
                                        value={element.result}
                                        onSave={this.handleSave}
                                        onChange={value => this.props.handleChange(value, rowIndex, elementIndex)}
                                        inline
                                    />
                                } else {
                                    return element.text
                                }
                            })}
                        </div>
                    })}
                </div>
            </React.Fragment>
        );
    }
}

FillPreview.propTypes = {
    config: PropTypes.object,
    assignment: PropTypes.array,
    setHomeworkConfigAssignment: PropTypes.func,
    handleChange: PropTypes.func
}

const mapStateToProps = (state) => ({
    config: state.config.config,
    assignment: state.config.assignment,
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfigAssignment: (assignment) => dispatch({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: assignment }),
})

export default connect(mapStateToProps, mapDispatchToProps)(FillPreview)
