import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import moment from "moment";

const SprintItem = ({ sprint }) => {
    const startDate = moment(sprint.startDate).format('LL')
    const endDate = moment(sprint.endDate).format('LL')
    const category = sprint.category.title || ''

    return (
        <tr>
            <th>
                <label>
                    <input type="checkbox" className="checkbox"  />
                </label>
            </th>
            <td>
                <div className="flex items-center space-x-3">
                    <div>
                        <div className="font-bold">{sprint.title}</div>
                    </div>
                </div>
            </td>
            <td>{category}</td>
            <td>{startDate + ' - ' + endDate}</td>
            <th>
                <Link to={`/sprints/${sprint.id}`}>
                    <button className="btn btn-ghost btn-xs">details</button>
                </Link>
            </th>
        </tr>
    )
}

SprintItem.propTypes = {
    sprint: PropTypes.object.isRequired,
}

export default SprintItem
