import {
    GET_BALANCE_HISTORY_ERROR,
    GET_BALANCE_HISTORY_REQUEST,
    GET_BALANCE_HISTORY_SUCCESS,
    GET_STUDENT_ERROR,
    GET_STUDENT_REQUEST,
    GET_STUDENT_SUCCESS,
    GET_STUDENTS_ERROR,
    GET_STUDENTS_REQUEST,
    GET_STUDENTS_SUCCESS,
    RESET_STUDENT_DATA,
    RESET_STUDENTS_DATA,
    UPDATE_STUDENT_BALANCE_ERROR,
    UPDATE_STUDENT_BALANCE_REQUEST,
    UPDATE_STUDENT_BALANCE_SUCCESS,
    UPDATE_STUDENT_PROFILE_ERROR,
    UPDATE_STUDENT_PROFILE_REQUEST,
    UPDATE_STUDENT_PROFILE_SUCCESS,
    WRITE_OFF_BALANCES_ERROR,
    WRITE_OFF_BALANCES_REQUEST,
    WRITE_OFF_BALANCES_SUCCESS,
    WRITE_OFF_PROGRESS

} from "../actions/students-actions";
import {toast} from "react-toastify";
import ToastMessage from "../../components/elements/toast";

const initialState = {
    students: null,
    student: null,
    writeOfProgress: null,
    loadingStudents: false,
    loadingUpdateProfile: false,
    loadingUpdateBalance: false,
    loadingBalanceHistory: false,
    balanceHistory: null,
    getStudentsError: null,
    updateStudentProfileError: null,
    updateStudentBalanceError: null,
}

export default function studentsReducer(state = initialState, {type, payload}) {

    switch(type) {

        case GET_STUDENTS_REQUEST: {
            return {
                ...state,
                loadingStudents: true,
                getStudentsError: null
            }
        }

        case GET_STUDENTS_SUCCESS:
            return {
                ...state,
                students: payload,
                loadingStudents: false,
                getStudentsError: null
            }

        case GET_STUDENTS_ERROR:
            return {
                ...state,
                students: null,
                loadingStudents: false,
                getStudentsError: 'Can`t get students. Try again later'
            }

        case GET_STUDENT_REQUEST: {
            return {
                ...state,
                loadingStudent: true,
                getStudentError: null
            }
        }

        case GET_STUDENT_SUCCESS:
            return {
                ...state,
                student: payload,
                loadingStudent: false,
                getStudentError: null
            }

        case GET_STUDENT_ERROR:
            return {
                ...state,
                student: null,
                loadingStudent: false,
                getStudentError: 'Can`t get student info. Try again later'
            }

        case UPDATE_STUDENT_PROFILE_REQUEST:
            return {
                ...state,
                loadingUpdateProfile: true,
                updateStudentProfileError: null
            }

        case UPDATE_STUDENT_PROFILE_SUCCESS:
            return {
                ...state,
                student: {
                    ...state.student,
                    profile: payload
                },
                loadingUpdateProfile: false,
                updateStudentProfileError: null
            }

        case UPDATE_STUDENT_PROFILE_ERROR:
            return {
                ...state,
                loadingUpdateProfile: false,
                updateStudentProfileError: 'Can`t update student info. Try again later'
            }

        case UPDATE_STUDENT_BALANCE_REQUEST:
            return {
                ...state,
                loadingUpdateBalance: true,
                updateStudentBalanceError: null
            }

        case UPDATE_STUDENT_BALANCE_SUCCESS:
            return {
                ...state,
                student: {
                    ...state.student,
                    balance: payload
                },
                loadingUpdateBalance: false,
                updateStudentBalanceError: null
            }

        case UPDATE_STUDENT_BALANCE_ERROR:
            return {
                ...state,
                loadingUpdateBalance: false,
                updateStudentBalanceError: 'Can`t update student info. Try again later'
            }

        case WRITE_OFF_BALANCES_REQUEST:
            return {
                ...state,
                writeOffError: null
            }

        case WRITE_OFF_BALANCES_SUCCESS:
            return {
                ...state,
                writeOffError: null
            }

        case WRITE_OFF_BALANCES_ERROR:
            return {
                ...state,
                writeOffError: 'Can`t write off balances for students. Contact with support'
            }

        case WRITE_OFF_PROGRESS:
            return {
                ...state,
                writeOfProgress: payload,
            }

        case RESET_STUDENT_DATA:
            return {
                ...state,
                student: null
            }

        case RESET_STUDENTS_DATA:
            return {
                ...state,
                students: null
            }

        case GET_BALANCE_HISTORY_REQUEST: {
            return {
                ...state,
                loadingBalanceHistory: true,
            }
        }

        case GET_BALANCE_HISTORY_SUCCESS: {
            return {
                ...state,
                loadingBalanceHistory: false,
                balanceHistory: payload
            }
        }

        case GET_BALANCE_HISTORY_ERROR: {
            toast.error(<ToastMessage text={payload.text} code={payload.code} />)
            return {
                ...state,
                loadingBalanceHistory: false,
            }
        }


        default:
            return state
    }
}
