import {
    put,
    call,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects'

import {
    SET_FTF_PAIRS,
    SET_FTF_PAIRS_REQUESTED,
    SET_FTF_PAIR_ID,
    SET_FTF_PAIR_ID_REQUESTED,
    UPDATE_FTF_PAIR_REQUEST,
    UPDATE_FTF_PAIR_SUCCESS,
    UPDATE_FTF_PAIR_ERROR,
    UPDATE_FTF_PAIR,
    GET_FTF_PAIRS,
    GET_FTF_PAIRS_REQUEST,
    GET_FTF_PAIRS_SUCCESS,
    GET_FTF_PAIRS_ERROR,
    CREATE_FTF_PAIRS,
    CREATE_FTF_PAIRS_REQUEST,
    CREATE_FTF_PAIRS_SUCCESS,
    CREATE_FTF_PAIRS_ERROR,
} from "../actions/ftf-pairs-actions";

import {
    BulkCreateFtfPairs,
    getPairsByFtf,
    updateFtfPair
} from "../api/ftf-pairs-api";

function* getFtfPairs({ payload }) {

    yield put({ type: GET_FTF_PAIRS_REQUEST })

    const response = yield call(getPairsByFtf, payload)

    if (response.status === 200) {
        yield put({ type: GET_FTF_PAIRS_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: GET_FTF_PAIRS_ERROR })
    }
}

function* createFtfPairs({ payload }){

    yield put({ type: CREATE_FTF_PAIRS_REQUEST })

    const response = yield call(BulkCreateFtfPairs, payload)

    if (response.status === 200) {
        yield put({ type: CREATE_FTF_PAIRS_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: CREATE_FTF_PAIRS_ERROR })
    }
}

function* updatePair({ payload }) {
    yield put({ type: UPDATE_FTF_PAIR_REQUEST })

    const response = yield call(updateFtfPair, payload)

    if (response.status === 200) {
        yield put({ type: UPDATE_FTF_PAIR_SUCCESS, payload: payload.ftfId})
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: UPDATE_FTF_PAIR_ERROR })
    }
}

function* setFtfPairs({ payload }) {
    yield put({ type: SET_FTF_PAIRS, payload })
}

function* setFtfPairId({ payload }) {
    yield put({type: SET_FTF_PAIR_ID, payload: payload})
}

export default function* ftfPairsSaga() {
    yield takeEvery(GET_FTF_PAIRS, getFtfPairs)
    yield takeLatest(CREATE_FTF_PAIRS, createFtfPairs)
    yield takeLatest(UPDATE_FTF_PAIR, updatePair)
    yield takeLatest(SET_FTF_PAIRS_REQUESTED, setFtfPairs)
    yield takeLatest(SET_FTF_PAIR_ID_REQUESTED, setFtfPairId)

}
