import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {ConnectedForm} from "../../helpers/with-form-hook";
import {UPDATE_STUDENT_PROFILE} from "../../redux/actions/students-actions";
import Spinner from "../Spinner";

class ProfileCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            talks: this.props.talks,
            edit: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loadingUpdateProfile === true && this.state.edit && !this.props.loadingUpdateProfile){
            this.setState({ edit: !this.state.edit })
        }
    }

    handleEdit(values) {
        if (this.state.edit === true) {
            this.props.updateStudentProfile({
                user: this.props.uid,
                instagram: values.instagram,
                email: values.email,
                status: values.status,
                talks: values.talks,
            })
        }
    }

    render(){

        return (
            <div className="w-full bg-base-100">
                <ConnectedForm>
                    {({ register, getValues, formState: { errors } }) =>
                        <>
                            <div className={"flex flex-row justify-start items-center mb-4 space-x-1"}>
                                <h2 className="inline mr-2">Profile</h2>
                                <p
                                    onClick={this.state.edit ? () => this.handleEdit(getValues()) : () => this.setState({edit: !this.state.edit})}
                                    className="inline link link-primary">
                                    {this.state.edit ? 'Save' : 'Edit'}
                                </p>
                                {this.props.loadingUpdateProfile ? (
                                    <div className={"inline"}>
                                        <Spinner />
                                    </div>
                                ) : null}

                            </div>
                            <div className={"grid grid-cols-2 gap-4"}>
                                <div className="form-control">
                                    <label className="input-group input-group-vertical w-full max-w-xs">
                                        <span>Instagram</span>
                                        <input
                                            type="text"
                                            className={`input ${this.state.edit ? 'input-bordered' : 'input-ghost' } w-full max-w-xs`}
                                            disabled={!this.state.edit}
                                            {...register("instagram", {
                                                required: true,
                                                value: this.props.instagram
                                            })}

                                        />
                                        <label className="label">
                                            {errors.instagram && <span className="label-text-alt">Data is incorrect</span>}
                                        </label>
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="input-group input-group-vertical w-full max-w-xs">
                                        <span>Email</span>
                                        <input
                                            type="text"
                                            className={`input ${this.state.edit ? 'input-bordered' : 'input-ghost' } w-full max-w-xs`}
                                            disabled={!this.state.edit}
                                            {...register("email", {
                                                required: true,
                                                value: this.props.email
                                            })}
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="input-group input-group-vertical w-full max-w-xs">
                                        <span>Status</span>
                                        <select
                                            className={`select ${this.state.edit ? 'select-bordered' : 'select-ghost' } w-full max-w-xs`}
                                            disabled={!this.state.edit}
                                            {...register("status", {
                                                required: true,
                                                value: this.props.status
                                            })}
                                        >
                                            <option value={'active'}>Active</option>
                                            <option value={'freeze'}>Freeze</option>
                                            <option value={'archived'}>Archived</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="form-control w-full max-w-xs">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Talks</span>
                                        <input
                                            type="checkbox"
                                            className={`toggle ${this.state.talks ? 'toggle-accent': '' }`}
                                            disabled={!this.state.edit}
                                            {...register("talks", {
                                                required: true,
                                                value: this.props.talks,
                                                onChange: ()=>this.setState({talks: !this.state.talks})
                                            })}
                                        />
                                    </label>
                                </div>
                            </div>
                        </>
                    }
                </ConnectedForm>
            </div>
        );
    }
}

ProfileCard.propTypes = {
    uid: PropTypes.string,
    instagram: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    talks: PropTypes.bool,
    loadingUpdateProfile: PropTypes.bool,
    updateStudentProfile: PropTypes.func
}

const mapStateToProps = (state) => ({
    loadingUpdateProfile: state.students.loadingUpdateProfile
})


const mapDispatchToProps = (dispatch) => ({
    updateStudentProfile: (profile) => dispatch({ type: UPDATE_STUDENT_PROFILE, payload: profile })
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard)
