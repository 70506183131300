import {
    SET_USER_INFO,
    RESET_USER_DATA,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_ERROR,
} from "../actions/user-actions";
import { toast } from "react-toastify";
import React from "react";

import ToastMessage from "../../components/elements/toast";

const initialState = {
    userInfo: null,
};

export default function userReduser(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: payload,
            }

        case GET_USER_INFO_ERROR:
            toast.error(<ToastMessage text={"Couldn't get your profile info."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                userInfo: null,
                userInfoError: true
            }

        case SET_USER_INFO:
            return {
                ...state,
                userInfo: payload
            }

        case RESET_USER_DATA:
            return {
                ...state,
                userInfo: null,
                userInfoError: null,
            }

        default:
            return state;
    }
}
