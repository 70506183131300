const navigation = [
    // {name: 'Login', route: '/login'},
    // {name: 'Register', route: '/register'},
    // {name: 'Profile', route: '/profile'},
    {name: 'Dashboard', route: '/dashboard', disabled: false, position: 'main'},
    {name: 'Sprints', route: '/sprints', disabled: false, position: 'main'},
    {name: 'Students', route: '/students', disabled: false, position: 'main'},
    // {name: 'Editor', route: '/editor', disabled: true, position: 'main'},
    {name: 'Profile', route: '/profile', disabled: false, position: 'settings'},
    // {name: 'Settings', route: '/settings', disabled: false, position: 'settings'},
    {name: 'Logout', route: '/logout', disabled: false, position: 'settings'},
];

export const mainNav = navigation.filter(item => item.position === 'main')
export const settingsNav = navigation.filter(item => item.position === 'settings')
