import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import readXlsxFile from "read-excel-file";

import {
    HIDE_MODAL
} from "../../../redux/actions/common-actions";

import {
    ENROLL_STUDENTS
} from "../../../redux/actions/sprint-students-actions";

class EnrollStudentsModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            duplicates: [],
            studentsWithoutDuplicates: []
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if( prevProps.loadingEnrollStudents && !this.props.loadingEnrollStudents && this.props.enrollStudentsError === null ) {
            this.props.hideModal('enrollStudents')
        }
    }

    handleChange = (files) => {
        readXlsxFile(files[0]).then((rows) => {

            const students = rows.filter((_, index) => index !== 0).map((student) => {
                return student[0]
            });

            let tempArray = [...students]

            let tempDuplicates = []
            let tempStudentsWithoutDuplicates = []

            tempArray = tempArray.map((item) => {
                if(item !== undefined){
                    item.replace(/^@/, "").toLowerCase().trim();
                }
                return item
            }).sort()

            for (let i = 0; i < tempArray.length; i++) {

                let first = tempArray[i + 1]
                let second = tempArray[i]

                if (first === second) {
                    tempDuplicates.push(second)
                } else {
                    tempStudentsWithoutDuplicates.push(second)
                }
            }

            this.setState({
                duplicates: tempDuplicates,
                studentsWithoutDuplicates: tempStudentsWithoutDuplicates

            })
        })
    }

    onSubmit = () => {
        this.props.enrollSprintStudents({
            accounts: this.state.studentsWithoutDuplicates,
            sprint: this.props.currentSprint.id
        })
    }

    onCancel = (e) => {
        e.preventDefault()
        this.props.hideModal('enrollStudents')
        this.setState({
            duplicates: [],
            studentsWithoutDuplicates: []
        })
    }

    render() {
        const modalStyle = this.props.modal ? 'modal modal-open' : "modal"
        let duplicates = this.state.duplicates
        let studentsWithoutDuplicates = this.state.studentsWithoutDuplicates
        let loading = this.props.loadingEnrollStudents
        let error = this.props.enrollStudentsError

        return (
            <React.Fragment>
                <div className={modalStyle}>
                    <div className="modal-box">
                        <div className={"text-xl font-bold"}>Enroll students to Sprint</div>
                        <div className="divider dark" />
                        <form>
                            <div className="form-control mb-2">
                                <input className={"input"} onChange={ (e) => this.handleChange(e.target.files) } type={"file"} />
                            </div>
                        </form>
                        { studentsWithoutDuplicates.length > 0 ? (
                            <div className="ml-4">
                                <p>Без учета дубликатов: {studentsWithoutDuplicates.length}</p>
                                <p>Количество дубликатов: {duplicates.length}</p>
                                <span>Все дубликаты будут удалены из списка</span>
                            </div>
                        ) : null }
                        <div className="divider dark" />
                        {error ? (
                            <div className="alert shadow-lg alert-error my-4">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                         fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                    <span className="ml-4">Error! { error }.</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="modal-action">
                            <button
                                onClick={this.onSubmit}
                                className={`btn 
                                    ${studentsWithoutDuplicates.length === 0 ? 'btn-disabled btn-brandGray': 'btn-primary' } 
                                    ${loading ? 'loading' : ''}`
                                }
                            >
                                Submit
                            </button>
                            <button onClick={this.onCancel} className="btn">Close</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

EnrollStudentsModal.propTypes = {
    modal: PropTypes.bool.isRequired,
    loadingEnrollStudents: PropTypes.bool.isRequired,
    enrollStudentsError: PropTypes.string,
    currentSprint: PropTypes.object.isRequired,
    enrollSprintStudents: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    modal: state.common.modal,
    currentSprint: state.sprint.currentSprint,
    loadingEnrollStudents: state.sprintStudents.loadingEnrollStudents,
    enrollStudentsError: state.sprintStudents.enrollStudentsError
})

const mapDispatchToProps = (dispatch) => ({
    enrollSprintStudents: (students) => dispatch({ type: ENROLL_STUDENTS, payload: students }),
    hideModal: (modalType) => dispatch({ type: HIDE_MODAL, payload: modalType }),
})

export default connect(mapStateToProps, mapDispatchToProps)(EnrollStudentsModal)
