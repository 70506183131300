import React from 'react'
import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw} from 'draft-js'
import PropTypes from "prop-types";
import {
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
    SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA_REQUESTED,
} from "../../redux/actions/configurator-actions";
import {connect} from "react-redux";
import {assignmentConvertor} from "../../helpers/assignmentConvertor";

class TextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        if (this.props.editorRawData && this.props.editorRawData.blocks) {
            let content = convertFromRaw(this.props.editorRawData)
            this.state = { editorState: EditorState.createWithContent(content)};
        }

        this.handleKeyCommand = (command) => this._handleKeyCommand(command);
        this.onTab = (e) => this._onTab(e);
        this.toggleBlockType = (type) => this._toggleBlockType(type);
        this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.loading === true && this.props.loading === false && (this.props.editorRawData && this.props.editorRawData.blocks)) {
            let content = convertFromRaw(this.props.editorRawData)
            this.setState({
                editorState: EditorState.createWithContent(content)
            })
        }
    }

    componentWillUnmount() {
        this.setState({
            editorState: EditorState.createEmpty()
        })
    }

    _saveContent = (content) => {
        const contentToRaw = convertToRaw(content)
        const convertToAssignment = assignmentConvertor(contentToRaw, this.props.config.type)
        this.props.setHomeworkConfigTextEditor(contentToRaw)
        this.props.setHomeworkConfigAssignment(convertToAssignment)
    }

    _onChange = (editorState) => {
        const contentState = editorState.getCurrentContent();
        this._saveContent(contentState);
        this.setState({editorState})
    };

    _handleKeyCommand(command) {
        const {editorState} = this.state;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this._onChange(newState);
            return true;
        }
        return false;
    }

    _onTab(e) {
        const maxDepth = 4;
        this._onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    }

    _toggleBlockType(blockType) {
        this._onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this._onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    render() {
        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';

        let contentState = this.state.editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className="RichEditor-root">
                <BlockStyleControls
                    editorState={this.state.editorState}
                    onToggle={this.toggleBlockType}
                />
                {this.props.config.type === 'voice' ? null : (
                    <>
                        <InlineStyleControls
                            editorState={this.state.editorState}
                            onToggle={this.toggleInlineStyle}
                            type={this.props.config.type}
                        />
                        <div className={"divider mb-0"} />
                    </>
                )}

                <div className={className} >
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={this.state.editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this._onChange}
                        onTab={this.onTab}
                        // ref={this.setEditor}
                        spellCheck={true}
                    />
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    FORM: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    POSITIVE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    NEGATIVE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
              {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
];

const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

const InlineStyleControls = (props) => {

    let INLINE_STYLES = []

    if (props.type === 'put') {
         INLINE_STYLES = [
             {label: 'Form', style: 'FORM'},
             {label: 'Positive', style: 'POSITIVE'},
             {label: 'Negative', style: 'NEGATIVE'},
        ];
    } else if (props.type === 'fill') {
        INLINE_STYLES = [
            {label: 'Hide word', style: 'CODE'},
        ];
    }

    let currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

TextEditor.propTypes = {
    config: PropTypes.object,
    assignment: PropTypes.array,
    editorRawData: PropTypes.object,
    setHomeworkConfigAssignment: PropTypes.func,
    setHomeworkConfigTextEditor: PropTypes.func
}

const mapStateToProps = (state) => ({
    config: state.config.config,
    assignment: state.config.assignment,
    editorRawData: state.config.editorRawData
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfigAssignment: (assignment) => dispatch({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: assignment }),
    setHomeworkConfigTextEditor: (content) => dispatch({ type: SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA_REQUESTED, payload: content }),
})

// To make those two function works register it using connect
export default connect(mapStateToProps, mapDispatchToProps)(TextEditor)
