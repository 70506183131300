import React from 'react'
import PropTypes from "prop-types";

class ProgressBar extends React.Component {

    render() {
        return (
            <React.Fragment>
                <progress className="progress progress-accent w-42" value={this.props.value} max="100"></progress>
            </React.Fragment>
        );
    }
}

ProgressBar.propTypes = {
    value: PropTypes.number
}

export default ProgressBar
