// Import the redux-saga/effects
import {
    put,
    takeLatest,
} from 'redux-saga/effects'

import {
    CLEAR_MESSAGE,
    CLEAR_MESSAGE_REQUESTED, SET_MESSAGE, SET_MESSAGE_REQUESTED,
} from "../actions/message-actions";

function* clearMessage() {
    yield put({ type: CLEAR_MESSAGE })
}

function* setMessage({ payload }) {
    yield put({ type: SET_MESSAGE, payload: payload })
}

export default function* messageSaga() {
    yield takeLatest(CLEAR_MESSAGE_REQUESTED, clearMessage)
    yield takeLatest(SET_MESSAGE_REQUESTED, setMessage)
}
