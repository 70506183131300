import {
    SET_LOADING,
    GET_FTF_BY_SPRINT_REQUEST,
    GET_FTF_BY_SPRINT_SUCCESS,
    GET_FTF_BY_SPRINT_ERROR,
    CREATE_FTF_REQUEST,
    CREATE_FTF_SUCCESS,
    CREATE_FTF_ERROR, CLEAR_CREATE_FTF_ERROR,
} from "../actions/ftf-actions";

const initialState = {
    loadingFtf: false,
    loadingCreateFtf: false,
    getFtfError: null,
    createFtfError: null,
    ftf: null,
}

export default function ftfReducer(state = initialState, {type, payload}) {

    switch(type) {

        case SET_LOADING:
            return {
                ...state,
                loading: payload
            }

        case GET_FTF_BY_SPRINT_REQUEST: {
            return {
                ...state,
                loadingFtf: true,
                getFtfError: null
            }
        }

        case GET_FTF_BY_SPRINT_SUCCESS:
            return {
                ...state,
                ftf: Object.keys(payload).length > 0 ? payload : null,
                loadingFtf: false,
                getFtfError: null
            }

        case GET_FTF_BY_SPRINT_ERROR:
            return {
                ...state,
                ftf: null,
                loadingFtf: false,
                getFtfError: 'Cant get sprints. Try again later'
            }

        case CREATE_FTF_REQUEST:
            return {
                ...state,
                loadingCreateFtf: true,
                createFtfError: null,
            }

        case CREATE_FTF_SUCCESS:
            return {
                ...state,
                ftf: Object.keys(payload).length > 0 ? payload : null,
                loadingCreateFtf: false,
                createFtfError: null
            }

        case CREATE_FTF_ERROR:
            return {
                ...state,
                loadingCreateFtf: false,
                createFtfError: 'Can`t create ftf. Try again later'
            }

        case CLEAR_CREATE_FTF_ERROR:
            return {
                ...state,
                createFtfError: null
            }

        default:
            return state
    }
}
