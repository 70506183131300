import {
    put,
    call,
    takeEvery, takeLatest,
} from 'redux-saga/effects'

import {
    GET_BALANCE_HISTORY,
    GET_BALANCE_HISTORY_ERROR,
    GET_BALANCE_HISTORY_REQUEST,
    GET_BALANCE_HISTORY_SUCCESS,
    GET_STUDENT,
    GET_STUDENT_ERROR,
    GET_STUDENT_REQUEST,
    GET_STUDENT_SUCCESS,
    GET_STUDENTS,
    GET_STUDENTS_ERROR,
    GET_STUDENTS_REQUEST,
    GET_STUDENTS_SUCCESS,
    UPDATE_STUDENT_BALANCE,
    UPDATE_STUDENT_BALANCE_ERROR,
    UPDATE_STUDENT_BALANCE_REQUEST,
    UPDATE_STUDENT_BALANCE_SUCCESS,
    UPDATE_STUDENT_PROFILE,
    UPDATE_STUDENT_PROFILE_ERROR,
    UPDATE_STUDENT_PROFILE_REQUEST,
    UPDATE_STUDENT_PROFILE_SUCCESS,
    WRITE_OFF_BALANCES,
    WRITE_OFF_BALANCES_ERROR,
    WRITE_OFF_BALANCES_REQUEST,
    WRITE_OFF_BALANCES_SUCCESS, WRITE_OFF_PROGRESS
} from "../actions/students-actions";

import studentsApi from "../api/students-api";
import {UPDATE_SPRINT} from "../actions/sprint-actions";
import {SET_STUDENTS_PAGINATION_TOTAL_RECORDS} from "../actions/pagination-actions";


function* getStudents({ payload }) {

    yield put({ type: GET_STUDENTS_REQUEST })

    const response = yield call(studentsApi.getStudents, payload)

    if(response.status === 200) {
        yield put({ type: GET_STUDENTS_SUCCESS, payload: response.data.results })
        yield put({ type: SET_STUDENTS_PAGINATION_TOTAL_RECORDS, payload: response.data.count })
    } else if (response.status === 404) {
        yield put({ type:GET_STUDENTS_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_STUDENTS_ERROR })
    }
}

function* getStudentById({ payload }) {

    yield put({ type: GET_STUDENT_REQUEST })

    const response = yield call(studentsApi.getStudentById, payload)

    if(response.status === 200) {
        yield put({ type: GET_STUDENT_SUCCESS, payload: response.data })
    } else if (response.status === 404) {
        yield put({ type:GET_STUDENT_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_STUDENT_ERROR })
    }
}

function* updateStudentProfile({ payload }) {

    yield put({ type: UPDATE_STUDENT_PROFILE_REQUEST })

    const response = yield call(studentsApi.updateStudentProfile, payload)

    if(response.status === 200) {
        yield put({ type: UPDATE_STUDENT_PROFILE_SUCCESS, payload: response.data })
    } else {
        yield put({ type: UPDATE_STUDENT_PROFILE_ERROR })
    }
}

function* updateStudentBalance({ payload }) {

    yield put({ type: UPDATE_STUDENT_BALANCE_REQUEST })

    const response = yield call(studentsApi.updateStudentBalance, payload)

    if(response.status === 200) {
        yield put({ type: UPDATE_STUDENT_BALANCE_SUCCESS, payload: response.data })
    } else {
        yield put({ type: UPDATE_STUDENT_BALANCE_ERROR })
    }
}

function* writeOffBalances({ payload }) {

    yield put({ type: WRITE_OFF_BALANCES_REQUEST })

    const response = yield call(studentsApi.writeOffBalances, payload)

    if (response.status === 201) {
        yield put({ type: WRITE_OFF_BALANCES_SUCCESS })

        const data = {
            taskId: response.data.taskId,
            sprintId: payload.sprintId
        }

        yield call(getWriteOffProgress, {payload: data})

    } else {
        yield put({ type: WRITE_OFF_BALANCES_ERROR })
    }
}

function* getWriteOffProgress({ payload }) {

    const response = yield call(studentsApi.getWriteOffProgress, payload.taskId)
    if(response) {
        yield put({ type: WRITE_OFF_PROGRESS, payload: { taskId: payload.taskId, ...response.data } })
        let complete = response.data.complete

        if (!complete) {
            yield call(getWriteOffProgress, {payload: payload})
        } else {
            const  updateData = {
                data: {writeOff: true},
                id: payload.sprintId
            }

            yield put({ type: UPDATE_SPRINT, payload: updateData });
        }
    }
}

function* getBalanceHistory({ payload }) {

    yield put({ type: GET_BALANCE_HISTORY_REQUEST })

    const response = yield call(studentsApi.getBalanceHistory, payload)

    if(response.status === 200) {

        yield put({ type: GET_BALANCE_HISTORY_SUCCESS, payload: response.data.results })
    } else {
        yield put({ type: GET_BALANCE_HISTORY_ERROR, payload: {text: 'Error', code: response.status} })
    }
}




export default function* studentsSaga() {
    yield takeEvery(GET_STUDENTS, getStudents)
    yield takeLatest(GET_STUDENT, getStudentById)
    yield takeLatest(UPDATE_STUDENT_PROFILE, updateStudentProfile)
    yield takeLatest(UPDATE_STUDENT_BALANCE, updateStudentBalance)
    yield takeLatest(WRITE_OFF_BALANCES, writeOffBalances)
    yield takeLatest(GET_BALANCE_HISTORY, getBalanceHistory)
}
