import {useParams} from "react-router-dom";
import React from "react";
import {useMediaQuery} from "react-responsive";

export const withRouter = WrappedComponent => props => {
    const params = useParams();
    const isDesktop = useMediaQuery({ minWidth: 1224 })

    return (
        <WrappedComponent
            {...props}
            params={params}
            isDesktop={isDesktop}
        />
    );
};
