import {FaTrashAlt, FaExclamationCircle} from "react-icons/fa";
import useRecordingsList from "../../hooks/use-recordings-list";

export default function RecordingsList({ audio }) {
  const { recordings, deleteAudio } = useRecordingsList(audio);

  return (
    <div>
      <div className={"divider"}></div>
      {recordings.length > 0 ? (
        <>
          <h1>Your recordings</h1>
          {recordings.map((record) => (
              <div className="flex flex-row items-center justify-between" key={record.key}>
                <audio controls src={record.audio} style={{width: '80%'}}/>
                <button
                    title="Delete this audio"
                    onClick={() => deleteAudio(record.key)}
                >
                  <FaTrashAlt />
                </button>
              </div>
          ))}
        </>
      ) : (
        <div className={"flex flex-row items-center"}>
          <FaExclamationCircle color="#f2ea02" />
          <span className={"ml-5"}>You don't have records</span>
        </div>
      )}
    </div>
  );
}
