import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import VoiceRecorder from "./recorder"
import {withRouter} from "../../helpers/with-router";
import {EditText} from "react-edit-text";

const VoicePreview = ({ config, handleChange, assignment }) => {

    return (
        <>
            <div className={"pt-10 mt-5 pb-5 flex flex-col text-justify px-4"}>
                { assignment.map((row, rowIndex) => {
                    return <div key={`row-${rowIndex}`} className={"break-words inline-block text-xl"}>
                        {row.map((element, elementIndex) => {
                            if (element.isAnswer) {
                                return <EditText
                                    key={`element-${rowIndex}-${elementIndex}`}
                                    name={`element-${rowIndex}-${elementIndex}`}
                                    type="text"
                                    style={{
                                        width: element.result.length > 2 ? element.result.length.toString() + 'ch' : '3ch',
                                        color: element.result !== '' ? element.color : 'teal',
                                        opacity: 0.8,
                                        border: "none",
                                        paddingLeft: 3,
                                        paddingRight: 0
                                    }}
                                    placeholder={this.props.type === 'put' ? element.correct : 'type here'}
                                    value={element.result}
                                    onSave={this.handleSave}
                                    onChange={value => this.props.handleChange(value, rowIndex, elementIndex)}
                                    inline
                                />
                            } else {
                                return element.text
                            }
                        })}
                    </div>
                })}
            </div>
            <div className="pt-3 px-4 flex flex-col">
                Record and send a voice message
            </div>
            <div className="pt-10 px-4 flex flex-col">
                <div className={"w-full"}>
                    <VoiceRecorder />
                </div>
            </div>
        </>
    )

}

VoicePreview.propTypes = {
    config: PropTypes.object,
    assignment: PropTypes.array,
    setHomeworkConfigAssignment: PropTypes.func,
    handleChange: PropTypes.func
}

const mapStateToProps = (state) => ({
    config: state.config.config,
    assignment: state.config.assignment,
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(VoicePreview)
