import {accountApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const accountClient = client(`${accountApiUrl}/api/v1`)

export const getStudents = async (params) => {

    const queryString = new URLSearchParams(params).toString();

    try {
        const response = await accountClient.get(`/users/?${queryString}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const getStudentById = async (id) => {

    try {
        const response = await accountClient.get(`/users/${id}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const updateStudentProfile = async (profile) => {
    try {
        const data = snakecaseKeys(profile)
        const response = await accountClient.put(`/profile/${profile.user}/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const updateStudentBalance = async (profile) => {
    try {
        const data = snakecaseKeys(profile)
        const response = await accountClient.put(`/balance/${profile.user}/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const writeOffBalances = async (sprintId) => {

    try {
        const data = snakecaseKeys(sprintId)
        const response = await accountClient.post(`/balance/enrolled/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const getWriteOffProgress = async (taskId) => {

    try {
        const response = await accountClient.get(`/celery-progress/?task_id=${taskId}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const getBalanceHistory = async (userUid) => {

    try {
        const response = await accountClient.get(`/history/?user__uid=${userUid}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}


const studentsApi = {
    getStudents,
    getStudentById,
    updateStudentProfile,
    updateStudentBalance,
    writeOffBalances,
    getWriteOffProgress,
    getBalanceHistory
}

export default studentsApi
