import {
  LOGOUT,
  REFRESH_TOKEN,
} from "../actions/auth-actions";

const initialState = {
  isLoggedIn: false,
  accessToken: null,
  loadingLogin: false,
  loginWithProvider: false
};

export default function authReduser(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: initialState.isLoggedIn,
        loginWithProvider: false,
        loadingLogin: false,
        accessToken: initialState.accessToken,
      };

      /* REFRESH TOKEN */
    case REFRESH_TOKEN:
      return {
        ...state,
        accessToken: payload,
      };

    default:
      return state;
  }
}
