import React, {useEffect, useState} from 'react'
import 'react-edit-text/dist/index.css';
import {FormProvider, useForm} from "react-hook-form";
import BackgroundButton from "./BackgroundButton";
import TextEditor from "./TextEditor";
import SaveButton from "./SaveButton";
import PublishButton from "./PublishButton";
import Media from "./Media";

import PropTypes from "prop-types";

import {connect} from "react-redux";
import {withUseFormHook} from "../../helpers/with-form-hook";
import {
    SET_EMPTY_HOMEWORK_CONFIG,
    SET_HOMEWORK_CONFIG, SET_HOMEWORK_CONFIG_ASSIGNMENT,
    SET_HOMEWORK_CONFIG_MANY,
} from "../../redux/actions/configurator-actions";
import Loading from "../elements/loading/Loading";
import {SET_CURRENT_HOMEWORK} from "../../redux/actions/homework-actions";
import {form} from "react-validation/build/main";

const Configurator = ({
                          currentHomework,
                          setHomeworkConfigAssignment,
                          setCurrentHomework,
                          setEmptyHomeworkConfig,
                          form,
                          setHomeworkConfig,
                          config,
                          loadingGetHomeworkConfig,
                          createUpdateHomeworkConfigError
}) => {

    const methods = form

    const [title, setTitle] = useState(currentHomework.title)

    const cleanComponent = () => {

        methods.reset({})
        setHomeworkConfigAssignment([])
        setCurrentHomework({})
        setEmptyHomeworkConfig()
    }

    const types = {
        fill: 'Fill in the gaps',
        voice: 'Voice'
    }

    const options = Object.entries(types).map(([key,value])=>{
        return (
            <option key={key} value={key}>{value.toString()}</option>
        );
    })

    let loading = loadingGetHomeworkConfig
    let error = createUpdateHomeworkConfigError

    const handleChange = (event, reset) => {
        setHomeworkConfig({withMedia: event.target.checked})

        if(event.target.checked === true){
            if(!config.isVideo && !this.props.config.isImage){
                setHomeworkConfig({
                    isImage: false,
                    isVideo: true
                })
            }
        }
        if(event.target.checked === false){
            setHomeworkConfig({
                videoLink: null,
                image: null,
                isImage: false,
                isVideo: false,
                startTimeMin: 0,
                startTimeSec: 0,
                startTime: 0,
                endTimeMin: 0,
                endTimeSec: 0,
                endTime: 0
            })

            reset({
                videoLink: null,
                image: null,
                isImage: false,
                isVideo: false,
                startTimeMin: 0,
                startTimeSec: 0,
                startTime: 0,
                endTimeMin: 0,
                endTimeSec: 0,
                endTime: 0,
            })
        }
    }

    useEffect(()=>{
        return () => {
            cleanComponent()
        }
    }, [])

    useEffect(()=>{
        if(config){
            methods.reset({
                videoLink: config.videoLink,
                image: config.image,
                isImage: config.isImage,
                isVideo: config.isVideo,
                startTimeMin: config.startTimeMin,
                startTimeSec: config.startTimeSec,
                startTime: config.startTime,
                endTimeMin: config.endTimeMin,
                endTimeSec: config.endTimeSec,
                endTime: config.endTime,
            })
        }
    }, [config])

    return (
        <React.Fragment>
            {loading ? (
                <div className="flex flex-col flex-1 lg:max-w-laptop justify-center items-center h-screen bg-brandYellow">
                    <Loading />
                </div>
            ) : (
                <div className={"mx-6"}>
                    <div className={"flex flex-row justify-between"}>
                        <h1 className={"my-10 text-2xl"}>Configurator</h1>
                        {currentHomework.status === 'published' ? (
                            <h1 className={"my-10 text-2xl"}> Published </h1>
                        ) : (
                            <h1 className={"my-10 text-2xl"}> In Progress </h1>
                        )}
                    </div>
                    <FormProvider {...methods} >
                        <div className={"form-control mb-10"}>
                            <select
                                className="bg-brandYellow mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black "
                                defaultValue={config.type}
                                name="type"
                                {...methods.register("type", {
                                    required: true,
                                    onChange: (event) => setHomeworkConfig({type:event.target.value})
                                })}
                            >
                                { options }
                            </select>
                            <label className="label">
                                {methods.formState.errors.type && <span className="label-text-alt">Choose homework type</span>}
                            </label>
                        </div>
                        <div className="form-control mb-10">
                            <input
                                className="bg-brandYellow mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                type="text"
                                name="title"
                                placeholder="Title"
                                defaultValue={title}
                                {...methods.register("title", {
                                    required: true,
                                    onChange: (event) => setHomeworkConfig({title: event.target.value})
                                })}
                            />
                            <label className="label">
                                {methods.formState.errors.title && <span className="label-text-alt">Set homework title</span>}
                            </label>
                        </div>

                        {config.type !== 'voice' ? (
                            <>
                                <div className={`flex flex-row items-center justify-between w-full ${!config.withMedia ? "mb-10" : "mb-3" }`}>
                                    <h1>Media</h1>
                                    <label htmlFor="toggleB" className="flex items-center cursor-pointer">
                                        <div className="relative">
                                            <input
                                                defaultChecked={config.withMedia}
                                                type="checkbox"
                                                id="toggleB"
                                                name="withMedia"
                                                className="sr-only"
                                                {...methods.register("withMedia", {
                                                    onChange: (event) => {
                                                        handleChange(event, methods.reset)
                                                    }
                                                })} />
                                            <div className="block bg-gray-600 w-10 h-6 rounded-full" />
                                            <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition" />
                                        </div>
                                    </label>
                                </div>
                                {config.withMedia ? (
                                    <Media />
                                ) : null}
                            </>
                        ) : null}

                        <h1 className={"my-5"}>Background</h1>
                        <div className={"flex flex-row justify-start mb-10"}>
                            <div className={"flex flex-row flex-1 items-center"} >
                                <BackgroundButton buttonType="color" />
                                <h2 className={"ml-3"}>Color</h2>
                            </div>
                            <div className={"flex flex-row flex-1 items-center"} >
                                <BackgroundButton buttonType="gradient" />
                                <h2 className={"ml-3"}>Gradient</h2>
                            </div>
                            <div className={"flex flex-row flex-1 items-center"}>
                                <BackgroundButton buttonType="image" />
                                <h2 className={"ml-3"}>Image</h2>
                            </div>
                        </div>
                        <div className="form-control mb-10">
                            <label className={"mb-5"}>Text Editor</label>
                            <TextEditor />
                        </div>
                    </FormProvider>
                    <div className={"flex flex-row justify-between mb-5"}>
                        <SaveButton />
                        <PublishButton />
                    </div>
                    { error ? (
                        <div className="alert shadow-lg alert-error mb-5">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                     fill="none" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                                <span>Error! { error }</span>
                            </div>
                        </div>
                    ) : null }

                </div>
            )}

        </React.Fragment>
    );
}

Configurator.propTypes = {
    loadingGetHomeworkConfig: PropTypes.bool,
    createUpdateHomeworkConfigError: PropTypes.string,
    config: PropTypes.object,
    currentHomework: PropTypes.object,
    setHomeworkConfigSingle: PropTypes.func,
    setHomeworkConfigMany: PropTypes.func,
    form: PropTypes.object,
    setHomeworkConfig: PropTypes.func,
    setHomeworkConfigAssignment: PropTypes.func,
    setCurrentHomework: PropTypes.func,
    setEmptyHomeworkConfig: PropTypes.func
}

const mapStateToProps = (state) => ({
    loadingGetHomeworkConfig: state.config.loadingGetHomeworkConfig,
    createUpdateHomeworkConfigError: state.config.createUpdateHomeworkConfigError,
    config: state.config.config,
    currentHomework: state.homework.currentHomework
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfig: (params) => dispatch({ type: SET_HOMEWORK_CONFIG, payload: params }),
    setHomeworkConfigAssignment: (assignment) => dispatch({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: assignment }),
    setCurrentHomework: (currentHomework) => dispatch({ type: SET_CURRENT_HOMEWORK, payload: currentHomework }),
    setEmptyHomeworkConfig: () => dispatch({type: SET_EMPTY_HOMEWORK_CONFIG})
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(Configurator))
