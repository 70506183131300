import React from 'react'
import {BsPlusLg} from "react-icons/bs";
import ReactGPicker from "react-gcolor-picker";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import ImagePatternPicker from "../elements/ImagePatternPicker";
import {ConnectedForm} from "../../helpers/with-form-hook";

import {
    SET_HOMEWORK_CONFIG,
} from "../../redux/actions/configurator-actions";

class BackgroundButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hiddenTooltip: true,
        }

    }

    render() {
        let appStyle = {}

        if (this.props.config.background && this.props.config.background.type === this.props.buttonType) {
            appStyle = {
                background: this.props.config.background.source
            }
        } else {
            appStyle = {}
        }
        return (
            <React.Fragment>
                <>
                    { this.props.buttonType !== 'image' ? (
                        <>
                            <ConnectedForm>
                                {({ register, setValue }) =>
                                    <>
                                        <label>
                                            <button
                                                data-tip={true}
                                                data-event='click'
                                                data-for={this.props.buttonType}
                                                style={ appStyle }
                                                className="relative btn btn-circle"
                                            >
                                                <BsPlusLg />
                                            </button>
                                            <input
                                                className="hidden"
                                                type="text"
                                                name="background"
                                                {...register("background")}
                                            />
                                        </label>
                                        <ReactTooltip
                                            id={this.props.buttonType}
                                            clickable={true}
                                            globalEventOff="click"
                                            place="right"
                                            effect="solid"
                                            type="light"
                                        >
                                            <ReactGPicker
                                                solid={this.props.buttonType === 'color'}
                                                gradient={this.props.buttonType === 'gradient'}
                                                onChange={(value) => {
                                                    this.props.setHomeworkConfig({
                                                        background: {type: this.props.buttonType, source: value}
                                                    })
                                                    setValue("background", value)
                                                }}
                                            />
                                        </ReactTooltip>
                                    </>
                                }

                            </ConnectedForm>

                        </>
                        ) : (<ImagePatternPicker />) }
                </>

            </React.Fragment>
        );
    }
}

BackgroundButton.propTypes = {
    config: PropTypes.object,
    setHomeworkConfig: PropTypes.func,
}

const mapStateToProps = (state) => ({
    config: state.config.config,
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfig: (params) => dispatch({ type: SET_HOMEWORK_CONFIG, payload: params })
})

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundButton)
