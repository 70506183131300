import React, {createContext, useContext, useEffect, useState} from "react";
import {connect} from "react-redux";
import {ToastContainer, Slide} from "react-toastify";

const ToastContext = createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

const ToastProvider = ({ children }) => {

    const value = {};

    return (
        <ToastContext.Provider value={value}>
            {children}
            <ToastContainer position="top-center"
                            transition={Slide}
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={true}
                            closeOnClick
                            rtl={false}
                            limit={2}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
            />
        </ToastContext.Provider>
    );
};

ToastProvider.propTypes = {

}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ToastProvider)
