import React from "react";
import PropTypes from "prop-types";
import {SHOW_MODAL} from "../redux/actions/common-actions";
import {connect} from "react-redux";

class DashboardContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    openModal = () => {
        this.props.showModal('createSprint')
    }

    render() {
        return (
            <>
                <div className="flex flex-col drawer-content">
                    <iframe title="dashboard" className="w-100 h-screen" src="https://datalens.yandex/b3374z6w9mc63?_embedded=1" frameBorder={0} />
                </div>
            </>

        );
    }
}

DashboardContainer.propTypes = {
    modal: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired
}

// Get state to props
const mapStateToProps = (state) => ({
    modal: state.common.modal
})


// Get dispatch / function to props
const mapDispatchToProps = (dispatch) => ({
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
