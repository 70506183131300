import {mainNav, settingsNav} from "../data/navigation";

export const getNavTitle = (payload) => {

    let object = {}

    if (payload) {
        object = mainNav.find(obj => {
            return obj.route === payload
        })

        if (object === undefined) {
            object = settingsNav.find(obj => {
                return obj.route === payload
            })

            if (object === undefined) {
                return ''
            }
        }

        return object.name

    } else {
        return ''
    }
}
