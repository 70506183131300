import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
    HIDE_MODAL
} from "../../../redux/actions/common-actions";

import {
    UPDATE_SPRINT_STUDENT
} from "../../../redux/actions/sprint-students-actions";
import {withUseFormHook} from "../../../helpers/with-form-hook";

class RenameStudentModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.loadingUpdateStudent && !this.props.loadingUpdateStudent && this.props.updateStudentError === null ) {
            this.props.hideModal('renameStudent')
        }
    }

    onSubmit = (data) => {
        this.props.renameSprintStudent({
            account: data.account,
            id: this.props.currentStudent.id
        })
    }

    onCancel = (e) => {
        e.preventDefault()
        this.props.hideModal('renameStudent')
    }

    render() {
        const modalStyle = this.props.modal ? 'modal modal-open' : "modal"
        let loading = this.props.loadingUpdateStudent
        let error = this.props.updateStudentError

        const { register, handleSubmit, formState: { errors } } = this.props.form;

        return (
            <React.Fragment>
                <div className={modalStyle}>
                    <div className="modal-box">
                        <div className={"text-xl font-bold"}>Enroll students to Sprint</div>
                        <div className="divider dark" />
                        <form>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Instagram</span>
                                </label>
                                <input
                                    type="text"
                                    name="account"
                                    placeholder="What needs to be done..."
                                    className={`input input-bordered ${errors.account && 'input-error'}`}
                                    defaultValue={this.props.currentStudent.account}
                                    {...register("account", { required: true })}
                                />
                                <label className="label">
                                    {errors.account && <span className="label-text-alt">Data is incorrect</span>}
                                </label>
                            </div>
                        </form>
                        <div className="divider dark" />
                        {error ? (
                            <div className="alert shadow-lg alert-error my-4">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                         fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                    <span className="ml-4">Error! { error }.</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="modal-action">
                            <button
                                onClick={handleSubmit(this.onSubmit)}
                                className={`btn btn-primary ${loading ? 'loading' : ''}`}
                            >
                                Submit
                            </button>
                            <button onClick={this.onCancel} className="btn">Close</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

RenameStudentModal.propTypes = {
    modal: PropTypes.bool.isRequired,
    currentStudent: PropTypes.object,
    loadingUpdateStudent: PropTypes.bool.isRequired,
    updateStudentError: PropTypes.string,
    renameSprintStudent: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    modal: state.common.modal,
    currentStudent: state.sprintStudents.currentStudent,
    loadingUpdateStudent: state.sprintStudents.loadingUpdateStudent,
    updateStudentError: state.sprintStudents.updateStudentError
})

const mapDispatchToProps = (dispatch) => ({
    renameSprintStudent: (student) => dispatch({ type: UPDATE_SPRINT_STUDENT, payload: student }),
    hideModal: (modalType) => dispatch({ type: HIDE_MODAL, payload: modalType }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(RenameStudentModal))
