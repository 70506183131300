import Pattern1 from '../assets/patterns/pattern-1.png'
import Pattern2 from '../assets/patterns/pattern-2.png'
import Pattern3 from '../assets/patterns/pattern-3.png'
import Pattern4 from '../assets/patterns/pattern-4.png'
import Pattern5 from '../assets/patterns/pattern-5.png'
import Pattern6 from '../assets/patterns/pattern-6.png'
import Pattern7 from '../assets/patterns/pattern-7.png'
import Pattern8 from '../assets/patterns/pattern-8.png'
import Pattern9 from '../assets/patterns/pattern-9.png'
import Pattern10 from '../assets/patterns/pattern-10.png'
import Pattern11 from '../assets/patterns/pattern-11.png'
import Pattern12 from '../assets/patterns/pattern-12.png'
import Pattern13 from '../assets/patterns/pattern-13.png'
import Pattern14 from '../assets/patterns/pattern-14.png'
import Pattern15 from '../assets/patterns/pattern-15.png'
import Pattern16 from '../assets/patterns/pattern-16.png'
import Pattern17 from '../assets/patterns/pattern-17.png'

export const patterns = [
    {
        image: Pattern1,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-1.png?alt=media'
    },
    {
        image: Pattern2,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-2.png?alt=media'
    },
    {
        image: Pattern3,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-3.png?alt=media'
    },
    {
        image: Pattern4,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-4.png?alt=media'
    },
    {
        image: Pattern5,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-5.png?alt=media'
    },
    {
        image: Pattern6,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-6.png?alt=media'
    },
    {
        image: Pattern7,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-7.png?alt=media'
    },
    {
        image: Pattern8,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-8.png?alt=media'
    },
    {
        image: Pattern9,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-9.png?alt=media'
    },
    {
        image: Pattern10,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-10.png?alt=media'
    },
    {
        image: Pattern11,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-11.png?alt=media'
    },
    {
        image: Pattern12,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-12.png?alt=media'
    },
    {
        image: Pattern13,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-13.png?alt=media'
    },
    {
        image: Pattern14,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-14.png?alt=media'
    },
    {
        image: Pattern15,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-15.png?alt=media'
    },
    {
        image: Pattern16,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-16.png?alt=media'
    },
    {
        image: Pattern17,
        url: 'https://firebasestorage.googleapis.com/v0/b/english-in-stories-dev.appspot.com/o/patterns%2Fpattern-17.png?alt=media'
    },
]

export const getBackground = (background, type) => {

    let appStyle = {}
    const index = background.index

    if(background.type === 'image') {
        appStyle = {
            backgroundImage: `url(${patterns[index].image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }

    }

    if(background.type !== 'image' && type === 'preview'){
        appStyle = {
            background: background.source,
        }
    }

    return appStyle
}
