import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { FormProvider } from "react-hook-form";

import PropTypes from "prop-types";
import ProfileCard from "../components/Student/ProfileCard";
import UserCard from "../components/Student/UserCard";
import BalanceCard from "../components/Student/BalanceCard";
import {withRouter} from "../helpers/with-router";
import {GET_STUDENT, RESET_STUDENT_DATA} from "../redux/actions/students-actions";
import {withUseFormHook} from "../helpers/with-form-hook";
import BalanceHistory from "../components/Student/BalanceHistory";

class StudentContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.props.getStudentById(this.props.params.id)
    }

    componentWillUnmount() {
        this.props.resetStudentData()
    }


    render(){

        let methods = this.props.form

        const student = this.props.student
        const profile = student ? student.profile : null
        const balance = student ? student.balance : null
        const uid = student ? student.uid : null

        return (
            <>
                {student ? (
                    <div className="flex flex-col drawer-content">
                        <div className={"flex flex-row items-center"}>
                            <div className={"flex flex-row justify-between items-center"}>
                                <div className="text-sm breadcrumbs mb-5">
                                    <ul>
                                        <li>
                                            <Link to={"/students"}>Students</Link>
                                        </li>
                                        <li>{student && profile ? profile.instagram || profile.email || student.username : ''}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <FormProvider {...methods} >
                            <UserCard
                                uid={student.uid}
                                username={student.username}
                                firstName={profile.firstName}
                                lastName={profile.lastName}
                                country={profile.country}
                                currency={profile.currency}
                                dateJoined={student.dateJoined}
                                lastPaymentDate={balance.lastPaymentDate}
                                isStudent={student.isStudent}
                                isAdmin={student.isAdmin}
                                isStaff={student.isStaff}
                                isActive={student.isActive}
                            />
                            <div className={"divider"} />
                            <ProfileCard
                                uid={student.uid}
                                instagram={profile.instagram}
                                email={profile.email}
                                status={profile.status}
                                talks={profile.talks}
                            />
                            <div className={"divider"} />
                            <BalanceCard
                                uid={student.uid}
                                amount={balance.amount}
                                weeks={balance.weeks}
                            />
                            <div className={"divider"} />
                            <BalanceHistory student={student} />
                        </FormProvider>
                    </div>
                ) : null}
            </>
         );
     }
}

StudentContainer.propTypes = {
    params: PropTypes.object,
    getStudentById: PropTypes.func,
    resetStudentData: PropTypes.func
}

const mapStateToProps = (state) => ({
    student: state.students.student,
})


const mapDispatchToProps = (dispatch) => ({
    getStudentById: (id) => dispatch({ type: GET_STUDENT, payload: id }),
    resetStudentData: () => dispatch({ type: RESET_STUDENT_DATA })
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withUseFormHook(StudentContainer)))
