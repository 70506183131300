import {
    SET_CURRENT_SPRINT,
    DELETE_SPRINT,
    GET_SPRINTS_SUCCESS,
    GET_SPRINTS_ERROR,
    GET_SPRINTS_REQUEST,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_ERROR,
    CREATE_SPRINT_REQUEST,
    CREATE_SPRINT_SUCCESS,
    CREATE_SPRINT_ERROR,
    CLEAR_CREATE_SPRINT_ERROR,
    GET_SPRINT_REQUEST,
    GET_SPRINT_SUCCESS,
    GET_SPRINT_ERROR,
    UPDATE_SPRINT_REQUEST,
    UPDATE_SPRINT_SUCCESS, UPDATE_SPRINT_ERROR,
} from '../actions/sprint-actions'

const initialState = {
    loadingSprints: false,
    loadingCategories: false,
    loadingCreateSprint: false,
    sprints: null,
    categories: [],
    currentSprint: null,
    getSprintsError: null,
    createSprintError: null,
    getCategoriesError: null
}

export default function sprintReducer(state = initialState, {type, payload}) {
    switch(type) {

        case GET_SPRINTS_REQUEST: {
            return {
                ...state,
                loadingSprints: true,
                getSprintsError: null
            }
        }

        case GET_SPRINTS_SUCCESS:
            return {
                ...state,
                sprints: payload,
                loadingSprints: false,
                sprintError: null
            }

        case GET_SPRINTS_ERROR:
            return {
                ...state,
                sprints: null,
                loadingSprints: false,
                sprintError: 'Cant get sprints. Try again later'
            }

        case GET_SPRINT_REQUEST: {
            return {
                ...state,
                loadingSprint: true,
                getSprintsError: null
            }
        }

        case GET_SPRINT_SUCCESS:
            return {
                ...state,
                currentSprint: payload,
                loadingSprint: false,
                sprintError: null
            }

        case GET_SPRINT_ERROR:
            return {
                ...state,
                currentSprint: null,
                loadingSprint: false,
                sprintError: 'Cant get sprint. Try again later'
            }

        case UPDATE_SPRINT_REQUEST: {
            return {
                ...state,
                loadingSprint: true,
                getSprintsError: null
            }
        }

        case UPDATE_SPRINT_SUCCESS:
            return {
                ...state,
                currentSprint: payload,
                loadingSprint: false,
                sprintError: null,
            }

        case UPDATE_SPRINT_ERROR:
            return {
                ...state,
                currentSprint: null,
                loadingSprint: false,
                sprintError: 'Cant get sprint. Try again later'
            }

        case GET_CATEGORIES_REQUEST: {
            return {
                ...state,
                loadingCategories: true,
                getCategoriesError: null
            }
        }

        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: payload,
                loadingCategories: false,
                categoriesError: null
            }

        case GET_CATEGORIES_ERROR:
            return {
                ...state,
                categories: null,
                loadingCategories: false,
                categoriesError: 'Can`t create sprint without sprint categories. Try again later'
            }

        case SET_CURRENT_SPRINT:
            return {
                ...state,
                currentSprint: state.sprints.find((sprint) => sprint.id === payload)
            }

        case CREATE_SPRINT_REQUEST:
            return {
                ...state,
                loadingCreateSprint: true,
                createSprintError: null,
            }

        case CREATE_SPRINT_SUCCESS:
            return {
                ...state,
                sprints: [payload, ...state.sprints],
                loadingCreateSprint: false,
                createSprintError: null
            }

        case CREATE_SPRINT_ERROR:
            return {
                ...state,
                loadingCreateSprint: false,
                createSprintError: 'Cant create sprint. Try again later'
            }

        case CLEAR_CREATE_SPRINT_ERROR:
            return {
                ...state,
                createSprintError: null
            }

        case DELETE_SPRINT:
            return {
                ...state,
                sprints: state.sprints.filter(sprint => sprint.id !== payload),
                loadingCreateSprint: false
            }

        default:
            return state
    }
}
