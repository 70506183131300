import React, {useEffect} from "react";
import Device from "../components/elements/Device";
import Configurator from "../components/Configurator/Configurator";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {
    GET_CONFIG_BY_HOMEWORK,
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
} from "../redux/actions/configurator-actions";

import {
    SET_CURRENT_HOMEWORK
} from "../redux/actions/homework-actions";
import {withRouter} from "../helpers/with-router";
import {useMediaQuery} from "react-responsive";

const EditorContainer = ({
                             assignment,
                             setHomeworkConfigAssignment,
                             setCurrentHomework,
                             getHomeworkConfig,
                             params
                         }) => {

    const isDesktop = useMediaQuery({ minWidth: 1224 })


    useEffect(()=>{
        if (assignment === null || assignment === undefined) {
            setHomeworkConfigAssignment([])
        }
        getHomeworkConfig(params.id)
    }, [])

    return (
        <div className="flex flex-row drawer-content">
            <div className={`${isDesktop ? 'w-3/5' : 'w-full'} bg-brandYellow`}>
                <Configurator />
            </div>
            {isDesktop ? (
                <div className={"flex flex-col items-center bg-blue-500 w-2/5 bg-brandBlue"}>
                    <Device />
                </div>
            ) : null}
        </div>
    )
}

EditorContainer.propTypes = {
    loading: PropTypes.bool,
    params: PropTypes.any,
    currentHomework: PropTypes.object,
    getHomeworkConfig: PropTypes.func,
    setHomeworkConfigAssignment: PropTypes.func,
    setCurrentHomework: PropTypes.func
}

const mapStateToProps = (state) => ({
    loading: state.config.loading,
    currentHomework: state.homework.currentHomework,
})

const mapDispatchToProps = (dispatch) => ({
    getHomeworkConfig: (homeworkId) => dispatch({ type: GET_CONFIG_BY_HOMEWORK, payload: homeworkId }),
    setHomeworkConfigAssignment: (assignment) => dispatch({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: assignment }),

})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditorContainer))