import {accountApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const accountClient = client(`${accountApiUrl}/api/v1`)

export const updateUserProfile = async (params) => {
  try {
    let data = snakecaseKeys(params)
    const response = await accountClient.put(`/profile/${params.user}/`, data)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const getUserInfo = async (uid) => {

  try {
    const response = await accountClient.get(`/users/${uid}/`)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

const userApi = {
  updateUserProfile,
  getUserInfo
};

export default userApi;
