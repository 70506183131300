import React from 'react'
import PropTypes from "prop-types";

class Stat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <div className="stat place-items-center place-content-center">
                    {this.props.number ? (
                        <>
                            <div className="stat-title">{this.props.title}</div>
                            <div className="stat-value text-success">{ this.props.number }</div>
                            <div className="stat-desc"> {this.props.desc} </div>
                        </>

                    ) : (
                        <div className="stat-value text-success">No Data</div>
                    )}

                </div>
            </React.Fragment>
        );
    }
}

Stat.propTypes = {
    title: PropTypes.string,
    number: PropTypes.number,
    desc: PropTypes.string,
}

export default Stat
