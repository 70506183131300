import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";

import PropTypes from "prop-types";
import {ConnectedForm} from "../../helpers/with-form-hook";
import Spinner from "../Spinner";
import {GET_BALANCE_HISTORY, UPDATE_STUDENT_BALANCE} from "../../redux/actions/students-actions";
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

const BalanceCard = ({ loadingUpdateBalance, updateStudentBalance, userInfo, amount, weeks, uid, getBalanceHistory }) => {

    const { id } = useParams();

    const {
        resetField,
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const prevLoadingState = usePrevious(loadingUpdateBalance)

    const {profile, balance, ...user} = userInfo;

    const [edit, setEdit] = useState(false)
    const [showDescription, setShowDescription] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(false)



    useEffect(()=>{
        if(prevLoadingState === true && edit && !loadingUpdateBalance) {
            setEdit(!edit)
            setShowDescription(false)
            resetField('description');
            getBalanceHistory(id)
        }
    }, [loadingUpdateBalance, edit])


    const handleEdit = (data, e) => {
        if (edit === true && needUpdate) {
            updateStudentBalance({
                user: uid,
                amount: amount,
                weeks: data.weeks,
                source: 'staff',
                metadata: {
                    user: user,
                    description: data.description
                }
            })
        } else {
            setEdit(false)
        }
    }

    const handleBalanceChanges = (event) => {
        const value = parseInt(event.target.value)

        if(value !== weeks) {
            setShowDescription(true)
            setNeedUpdate(true)
        } else {
            setShowDescription(false)
            setNeedUpdate(false)
        }
    }

    return (
        <div className="w-full bg-base-100">
            <>
                <div className={"flex flex-row justify-start items-center mb-4 space-x-1"}>
                    <h2 className="inline mr-2">Balance</h2>
                    <p
                        onClick={edit ? handleSubmit(handleEdit) : () => setEdit(!edit)}
                        className="inline link link-primary">
                        {edit ? 'Save' : 'Edit'}
                    </p>
                    {loadingUpdateBalance ? (
                        <div className={"inline"}>
                            <Spinner />
                        </div>
                    ) : null}
                </div>
                <div className={"grid grid-cols-2 gap-4"}>
                    <div className="form-control">
                        <label className="input-group input-group-vertical w-full max-w-xs">
                            <span>Weeks</span>
                            <input
                                type="number"
                                className={`input ${edit ? 'input-bordered' : 'input-ghost' } w-full max-w-xs`}

                                disabled={!edit}
                                {...register("weeks", {
                                    required: true,
                                    value: weeks,
                                    onChange: (e) => handleBalanceChanges(e)
                                })}
                            />
                        </label>
                    </div>
                    {showDescription &&
                        <div className="form-control">
                            <label className="input-group input-group-vertical w-full max-w-xs">
                                <span>Description</span>
                                <input
                                    type="text"
                                    className={`textarea ${edit ? 'textarea-bordered' : 'textarea-ghost' } ${errors.description && 'textarea-error'} w-full max-w-xs`}
                                    placeholder={"Why are you changing it?"}
                                    {...register("description", {
                                        required: "The field should not be empty"
                                    })}
                                />
                            </label>
                            {errors.description && (
                                <label className="label">
                                    <span className="label-text-alt">{errors.description.message}</span>
                                </label>
                            )}
                        </div>
                    }
                </div>
            </>
        </div>
    );

}

BalanceCard.propTypes = {
    uid: PropTypes.string,
    amount: PropTypes.number,
    weeks: PropTypes.number,
    loadingUpdateBalance: PropTypes.bool,
    updateStudentBalance: PropTypes.func,
    getBalanceHistory: PropTypes.func,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    loadingUpdateBalance: state.students.loadingUpdateBalance,
    userInfo: state.user.userInfo
})


const mapDispatchToProps = (dispatch) => ({
    updateStudentBalance: (balance) => dispatch({ type: UPDATE_STUDENT_BALANCE, payload: balance }),
    getBalanceHistory: (uid) => dispatch({ type: GET_BALANCE_HISTORY, payload: uid })
})

export default connect(mapStateToProps, mapDispatchToProps)(BalanceCard)
