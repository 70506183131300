import nlp from "compromise";

export const assignmentConvertor = (content, assignmentType) => {

    return content.blocks.map((block) => {

        const intoPairs = xs => xs.slice(1).map((x, i) => [xs[i], x])
        const breakAt = (places, str) => intoPairs([0, ...places, str.length]).map(
            ([a, b]) => str.substring(a, b)
        )

        const breakWhere = (words, str) => {
            return breakAt(
                words.reduce((a, {offset, length, style}) => [...a, offset, offset + length], []),
                str,
            )
        }

        const createNodes = (links, str) => {
            const sortedLinks = links.slice(0).sort(({offset: o1}, {offset: o2}) => o1 - o2)

            if (assignmentType === 'fill' || assignmentType === 'voice') {
                return breakWhere(sortedLinks, str).map((s, i) => i % 2 === 0
                    ? {text: s, isAnswer: false}
                    : {text: s, isAnswer: true, color: 'teal', result: '', studentAnswer: ''}
                ).filter(({text}) => text.length > 0)
            }

            if (assignmentType === 'put') {
                return breakWhere(sortedLinks, str).map((s, i) => {
                    if (i % 2 === 0) {
                        return {text: s, isAnswer: false}
                    } else {
                        let text = nlp(s)
                        text.verbs().toInfinitive()
                        return {
                            text: s,
                            isAnswer: true,
                            color: 'teal',
                            result: '',
                            studentAnswer: '',
                            correct: text.text()
                        }
                    }
                }).filter(({text}) => text.length > 0)
            }

            // return breakWhere(sortedLinks, str).map((s, i) => i % 2 == 0
            //     ? {text: s, isAnswer: false}
            //     : {text: s, isAnswer: true, color: 'teal', result: '', studentAnswer: ''}
            // ).filter(({text}) => text.length > 0)
        }

        const str = block.text

        const links = block.inlineStyleRanges

        return createNodes(links, str)

    })
}
