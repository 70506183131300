import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
    BsImageFill, BsPeople,
} from "react-icons/bs";
import {
    FiSettings,
} from "react-icons/fi";

import PropTypes from "prop-types";
import {LOGOUT_REQUESTED} from "../redux/actions/auth-actions";
import {connect} from "react-redux";
import {AiOutlineDashboard} from "react-icons/ai";
import {useAuth} from "../context/AuthContext";

const Sidebar = ({ mainNav, settingsNav, userInfo }) => {

    const {
        currentUser
    } = useAuth();

    const [currentItem, setCurrentItem] = useState('Sprints')

    const renderIcon = ((element) => {
        if(element === 'Students') {
            return <BsPeople className="inline-block w-6 h-6 mr-2 stroke-current" />
        } else if(element === 'Dashboard') {
            return <AiOutlineDashboard className="inline-block w-6 h-6 mr-2 stroke-current"/>
        } else if(element === 'Login') {
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 mr-2 stroke-current">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
                    />
                </svg>;
        } else if(element === 'Logout') {
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 mr-2 stroke-current"
            >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>;
        } else if(element === 'Register') {
            return <BsImageFill />;
        } else if(element === 'Profile') {
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 mr-2 fill-current"
            >
                <path d="M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z M4,4 L4,20 L20,20 L20,4 L4,4 Z M14.7999209,15 L9.19992091,15 L8,18 L6,18 L11,6 L13,6 L18,18 L16,18 L14.7999209,15 Z M13.9998682,13 L11.9997364,8 L9.99986818,13 L13.9998682,13 Z" />
            </svg>;
        } else if(element === 'Sprints') {
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 mr-2 stroke-current"
            >
                <path
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                />
            </svg>;
        } else if(element === 'Editor') {
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 mr-2 stroke-current"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
            </svg>;
        } else if(element === 'Settings') {
            return <FiSettings className="inline-block w-6 h-6 mr-2 stroke-current" />
        }
    });

    return (
        <>
            {currentUser && userInfo ? (
                <React.Fragment>
                    <div className="drawer-side h-screen">
                        <label htmlFor="main-menu" className="drawer-overlay" />
                        <aside className="flex flex-col justify-start border-r border-base-200 bg-base-100 text-base-content w-80 h-screen">
                            <div className="sticky inset-x-0 top-0 z-50 hidden w-full py-1 transition duration-200 ease-in-out border-b lg:block border-base-200 bg-base-100">
                                <div className="mx-auto space-x-1 navbar max-w-none">
                                    <div className="flex items-center flex-none">
                                        <a href="/" className="px-2 flex-0 btn btn-ghost md:px-4 nuxt-link-active" aria-label="Homepage">
                                            <div className="inline-block text-3xl font-title text-primary"><span className="lowercase">homework</span>
                                                <span className="uppercase text-base-content">NINJA</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul className="menu flex flex-col p-4 pt-2 compact">
                                    <li className="mt-4 menu-title"><span>Main</span></li>
                                    {mainNav.map((element, index) => (
                                        <li className={`${ element.disabled ? "text-gray-400" : ""}`} key={index}>
                                            <Link
                                                to={element.route}
                                                className={`${ element.disabled ? "btn-disabled btn-ghost" : null} capitalize ${currentItem === element.name ? 'active' : null}`}
                                                onClick={()=> setCurrentItem(element.name)}
                                            >
                                                {renderIcon(element.name)} {element.name}
                                            </Link>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                            <div className="flex flex-col justify-end border-r border-base-200 bg-base-100 text-base-content w-80">
                                <div>
                                    <ul className="menu flex flex-col p-4 pt-2 compact">
                                        <li className="mt-4 menu-title"><span>Settings</span></li>
                                        {settingsNav.map((element, index) => (
                                            <li key={index}>
                                                <Link
                                                    to={element.route}
                                                    className={`capitalize ${currentItem === element.name ? 'active' : null}`}
                                                    onClick={()=> setCurrentItem(element.name)}
                                                >
                                                    {renderIcon(element.name)} {element.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </aside>
                    </div>
                </React.Fragment>
            ) : null}
        </>
    );
};

Sidebar.propTypes = {
    logOut: PropTypes.func,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo
})

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: LOGOUT_REQUESTED }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
