import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {UPDATE_STUDENT_BALANCE} from "../../redux/actions/students-actions";
import ReactTable, {
    ColumnDef,
    flexRender,
    getSortedRowModel,
    SortingState,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'
import moment from "moment/moment";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";

const BalanceHistoryTable = ({ balanceHistory, student }) => {

    const columns = [
        {
            accessorFn: row => row.source,
            id: 'source',
            cell: info => <i>{info.getValue()}</i>,
            header: () => <span>Source</span>,
            footer: info => info.column.id,
        },
        {
            accessorKey: 'weeks',
            cell: info => info.getValue(),
            footer: info => info.column.id,
        },
        {
            id: 'createAt',
            accessorFn: row => moment(row.createAt),
            header: () => 'Create At',
            sortType: 'datetime',
            cell: value => <div>{value.getValue().format('DD-MM-YYYY HH:mm')}</div>,
            footer: info => info.column.id,
        },
        {
            id: 'context',
            accessorFn: row => {
                let uid = row.metadata?.user.uid;
                let user = row.metadata?.user.username || 'not set'
                let description
                if(uid === student.uid){
                    user = student.profile.instagram
                }
                if(row.source === 'staff') {
                    description = row.metadata?.description
                }
                return {user: user, description: description}
            },
            cell: (value) => {
                const values = value.getValue()

                return (
                    <div className="flex flex-col justify-start items-start">
                        <h2 className="prose text-semibold text-md">{values.user}</h2>
                        <p className="prose text-xs">{values.description}</p>
                    </div>
                )
            },
            header: () => <span>Context</span>,
            footer: info => info.column.id,
        },
    ]

    const [data, setData] = useState([])
    const [sorting, setSorting] = useState([{ id: "createAt", desc: true }])

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })

    useEffect(()=>{
        if(balanceHistory){
            setData(() => [...balanceHistory])
        }
    }, [balanceHistory])

    return (
        <div className={"overflow-hidden"}>
            <table className="table table-fixed w-full">
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th className={"whitespace-normal"} key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div
                                            {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none flex flex-row items-center'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            { header.column.getCanSort() ?
                                                {asc: <TiArrowSortedUp className={"w-4 h-4"} />,desc: <TiArrowSortedDown className={"w-4 h-4"} />,}
                                                    [header.column.getIsSorted()] ?? <TiArrowUnsorted className={"w-4 h-4"}/> : ''
                                            }
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody className={""}>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td className={"break-words whitespace-normal"} key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
                <tfoot>
                {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                        {footerGroup.headers.map(header => (
                            <th className={"whitespace-normal"} key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </tfoot>
            </table>
        </div>

    )
}

BalanceHistoryTable.propTypes = {
    balanceHistory: PropTypes.array,
    student: PropTypes.object
}

const mapStateToProps = () => ({
})


const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(BalanceHistoryTable)
