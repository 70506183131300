import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

import duckAnimation from "./loading.json";
import {useAuth} from "../context/AuthContext";

const Loading = () => {
    const anime = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: anime.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: duckAnimation,
        });
        return () => lottie.stop();
    }, []);
    return <div className="flex flex-col justify-center items-center bg-brandYellow min-h-screen"><div style={{height: 250, width: 300}} ref={anime}/></div>;
};

export default Loading;
