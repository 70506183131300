// eslint-disable-next-line no-useless-escape
const isValidEmail = email =>

    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );



export function handleEmailValidation(email, errors) {

    const isValid = isValidEmail(email);

    const validityChanged =
        (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
}

export const stringToBoolean = (stringValue) => {
    switch(stringValue?.toLowerCase()?.trim()){
        case "true":
        case "yes":
        case "1":
            return true;

        case "false":
        case "no":
        case "0":
        case null:
        case undefined:
            return false;

        default:
            return JSON.parse(stringValue);
    }
}
