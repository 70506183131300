import {homeworkApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const homeworkClient = client(`${homeworkApiUrl}/api`)

export const getConfigByHomeworkId = async (id) => {
    try {
        const response = await homeworkClient.get(`/configuration/${id}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const createNewHomeworkConfig = async (config) => {
    try {
        let data = snakecaseKeys(config)
        const response = await homeworkClient.post(`/configuration/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const updateExistHomeworkConfig = async (config) => {
    try {
        let data = snakecaseKeys(config)
        const response = await homeworkClient.put(`/configuration/${config.homework}/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}
