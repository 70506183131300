import React from "react";
import RecorderControls from "./components/recorder-controls";
import RecordingsList from "./components/recordings-list";
import useRecorder from "./hooks/useRecorder";
import {connect} from "react-redux";

const VoiceRecorder = () => {
    const { recorderState, ...handlers } = useRecorder();
    const { audio } = recorderState;

    return (
        <div className="recorder-container">
            <RecorderControls recorderState={recorderState} handlers={handlers} />
            <RecordingsList audio={audio} />
        </div>
    );
}

VoiceRecorder.propTypes = {

}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRecorder)
