import {
    put,
    call,
    takeLatest,
    takeEvery
} from 'redux-saga/effects'

import {
    GET_SPRINTS,
    GET_SPRINTS_REQUEST,
    GET_SPRINTS_SUCCESS,
    GET_SPRINTS_ERROR,
    GET_CATEGORIES,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_ERROR,
    CREATE_SPRINT,
    CREATE_SPRINT_REQUEST,
    CREATE_SPRINT_SUCCESS,
    CREATE_SPRINT_ERROR,
    DELETE_SPRINT,
    DELETE_SPRINT_REQUESTED,
    GET_SPRINT_REQUEST,
    GET_SPRINT_SUCCESS,
    GET_SPRINT_ERROR,
    UPDATE_SPRINT_REQUEST,
    UPDATE_SPRINT_SUCCESS, UPDATE_SPRINT_ERROR, GET_SPRINT, UPDATE_SPRINT,
} from '../actions/sprint-actions'

import sprintApi from '../api/sprint-api'

function* getSprints() {

    yield put({ type: GET_SPRINTS_REQUEST })

    const response = yield call(sprintApi.getAllSprints)

    if(response.status === 200) {
        yield put({type: GET_SPRINTS_SUCCESS, payload: response.data })
    } else if(response.status === 404) {
        yield put({type: GET_SPRINTS_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_SPRINTS_ERROR })
    }
}

function* getCategories() {
    yield put({ type: GET_CATEGORIES_REQUEST })

    const response = yield call(sprintApi.getSprintCategories)

    if (response.status === 200) {
        yield put({type: GET_CATEGORIES_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: GET_CATEGORIES_ERROR })
    }
}

function* createSprint({ payload }) {

    yield put({ type: CREATE_SPRINT_REQUEST })

    const response = yield call(sprintApi.createNewSprint, payload)

    if (response.status === 200) {
        yield put({type: CREATE_SPRINT_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: CREATE_SPRINT_ERROR })
    }
}

function* getSprint({ payload }) {

    yield put({ type: GET_SPRINT_REQUEST })

    const response = yield call(sprintApi.getSprint, payload)

    if (response.status === 200) {
        yield put({type: GET_SPRINT_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: GET_SPRINT_ERROR })
    }
}

function* updateSprint({ payload }) {

    yield put({ type: UPDATE_SPRINT_REQUEST })

    const response = yield call(sprintApi.updateSprint, payload)

    if (response.status === 200) {
        yield put({type: UPDATE_SPRINT_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: UPDATE_SPRINT_ERROR })
    }
}

function* deleteSprint({ payload }) {

    const sprint = yield call(sprintApi.deleteExistedSprint, payload)

    yield put({ type: DELETE_SPRINT, payload: sprint })
}

export default function* sprintSaga() {
    yield takeEvery(GET_SPRINTS, getSprints)
    yield takeEvery(GET_CATEGORIES, getCategories)
    yield takeLatest(CREATE_SPRINT, createSprint)
    yield takeLatest(GET_SPRINT, getSprint)
    yield takeLatest(UPDATE_SPRINT, updateSprint)
    yield takeEvery(DELETE_SPRINT_REQUESTED, deleteSprint)
}
