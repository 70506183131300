export const SET_LOADING = "SET_LOADING"

export const GET_FTF_BY_SPRINT = "GET_FTF_BY_SPRINT"
export const GET_FTF_BY_SPRINT_REQUEST = "GET_FTF_BY_SPRINT_REQUEST"
export const GET_FTF_BY_SPRINT_SUCCESS = "GET_FTF_BY_SPRINT_SUCCESS"
export const GET_FTF_BY_SPRINT_ERROR = "GET_FTF_BY_SPRINT_ERROR"

export const CREATE_FTF = "CREATE_FTF"
export const CREATE_FTF_REQUEST = "CREATE_FTF_REQUEST"
export const CREATE_FTF_SUCCESS = "CREATE_FTF_SUCCESS"
export const CREATE_FTF_ERROR = "CREATE_FTF_ERROR"

export const DELETE_FTF_REQUESTED = "DELETE_FTF_REQUESTED"

export const SET_FTF = "SET_FTF"

export const CLEAR_CREATE_FTF_ERROR = "CLEAR_CREATE_FTF_ERROR"
