import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects'

import {
    GET_USER_INFO,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_REQUEST,
    GET_USER_INFO_SUCCESS,
} from '../actions/user-actions'

import userApi from '../api/user-api'

function* getUserInfo({ payload }) {

    yield put({ type: GET_USER_INFO_REQUEST })

    let response = yield call(userApi.getUserInfo, payload)

    if ( response.status === 200 ) {
        yield put({ type: GET_USER_INFO_SUCCESS, payload: response.data })
    } else if( response.status === 400 ){
        yield put({ type: GET_USER_INFO_ERROR, payload: response.status })
    } else {
        yield put({ type: GET_USER_INFO_ERROR, payload: response.status })
    }
}

export default function* userSaga() {
    yield takeLatest(GET_USER_INFO, getUserInfo)
}
