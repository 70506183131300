import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import StudentItem from "./StudentItem";
import StudentSkeleton from "./StudentSkeleton";
import {withRouter} from "../../helpers/with-router";

class StudentsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        let students = this.props.students
        let loading = this.props.loadingStudents
        let error = this.props.getStudentsError

        return (
            <React.Fragment>
                <div className={"overflow-x-auto"}>
                    <table className="table table-compact w-full">
                        <thead>
                        <tr>
                            <th>
                                <label>
                                    <input type="checkbox" className="checkbox" />
                                </label>
                            </th>
                            <th>Instagram</th>
                            <th>Username</th>
                            <th>Balance</th>
                            <th>Data Joined</th>
                            <th>Last Activity</th>
                        </tr>
                        </thead>

                        <tbody>

                        {loading ? (
                                <>
                                    {[...Array(5)].map((x, i) =>
                                        <StudentSkeleton key={i} />
                                    )}
                                </>
                            ) :
                            <>
                                {students && !error ? (
                                    students.length > 0 ? students.map((student) => (
                                        <StudentItem student={student} id={student.uid} key={student.uid} />
                                    )) : (
                                        <tr>
                                            <td colSpan={5}><h1 className="my-10 text-2xl text-center">No Data</h1></td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={5}><h1 className="my-10 text-2xl text-center">{ error }</h1></td>
                                    </tr>
                                )}
                            </>
                        }
                        </tbody>
                        <tfoot>
                        <tr>
                            <th></th>
                            <th>Instagram</th>
                            <th>Username</th>
                            <th>Balance</th>
                            <th>Data Joined</th>
                            <th>Last Activity</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}

StudentsTable.propTypes = {
    loadingStudents: PropTypes.bool,
    params: PropTypes.any,
    getStudentsError: PropTypes.string,
    students: PropTypes.array,

}

const mapStateToProps = (state) => ({
    loadingStudents: state.students.loadingStudents,
    getStudentsError: state.students.getStudentsError,
    students: state.students.students,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StudentsTable))
