import { Popover } from '@headlessui/react'
import React from 'react'


import {BsPlusLg, BsCheckLg} from "react-icons/bs";

import {SET_HOMEWORK_CONFIG} from "../../redux/actions/configurator-actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {
    getBackground,
    patterns
} from '../../helpers/getBackground'
import {ConnectedForm} from "../../helpers/with-form-hook";

class ImagePatternPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentImage: null,
        };
    }

    componentDidMount() {

    }


    handleClick = async (index, setValue) => {

        this.setState({
            currentImage: index,
        })

        setValue('background', patterns[index].url)

        this.props.setHomeworkConfig({
            background: {
                type: 'image',
                source: patterns[index].url,
                index: index
            }
        })
    }

    render() {

        let appStyle = {}

        if (this.props.config.background && this.props.config.background.type === 'image') {
            appStyle = getBackground(this.props.config.background, 'configurator')
        } else {
            appStyle = {}
        }

        return (
            <Popover className="relative">
                {({ open }) => (
                    <>
                        <ConnectedForm>
                            {({ register, setValue }) =>
                                <>
                                    <Popover.Button
                                        style={ appStyle }
                                        className={`btn btn-circle px-3 py-2`}
                                    >
                                        <BsPlusLg />
                                    </Popover.Button>
                                    <input
                                        className="hidden"
                                        type="text"
                                        name="background"
                                        {...register("background")}
                                    />
                                    <Popover.Panel className="w-screen absolute z-100 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-xl">
                                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                            <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-5">
                                                {patterns.map((pattern, index) => (
                                                    <div key={'image-'+index} className="relative flex items-center justify-center flex-shrink-0 w-14 h-14 text-white">
                                                        <img alt="pattern" src={pattern.image} className="border border-gray-500 object-cover w-14 h-14" onClick={() => this.handleClick(index, setValue)} />
                                                        {this.state.currentImage === index ? (
                                                            <div className="absolute bottom-0 right-0">
                                                                <BsCheckLg color="green" />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Popover.Panel>
                                </>
                            }
                        </ConnectedForm>

                    </>
                )}
            </Popover>
        )
    }


}

ImagePatternPicker.propTypes = {
    setHomeworkConfig: PropTypes.func,
    config: PropTypes.object,
}

const mapStateToProps = (state) => ({
    config: state.config.config,
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfig: (params) => dispatch({ type: SET_HOMEWORK_CONFIG, payload: params }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImagePatternPicker)
