import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
    HIDE_MODAL
} from "../../../redux/actions/common-actions";

import {
    ENROLL_STUDENTS
} from "../../../redux/actions/sprint-students-actions";
import {withUseFormHook} from "../../../helpers/with-form-hook";

class EnrollStudentsModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if( prevProps.loadingEnrollStudents && !this.props.loadingEnrollStudents && this.props.enrollStudentsError === null ) {
            this.props.hideModal('enrollStudent')
        }
    }

    onSubmit = (data) => {
        this.props.enrollSprintStudents({
            accounts: [data.account],
            sprint: this.props.currentSprint.id
        })
    }

    onCancel = (e) => {
        e.preventDefault()
        this.props.hideModal('enrollStudent')
    }

    render() {
        const modalStyle = this.props.modal ? 'modal modal-open' : "modal"
        let loading = this.props.loadingEnrollStudents
        let error = this.props.enrollStudentsError

        const { register, handleSubmit, formState: { errors } } = this.props.form;

        return (
            <React.Fragment>
                <div className={modalStyle}>
                    <div className="modal-box">
                        <div className={"text-xl font-bold"}>Enroll students to Sprint</div>
                        <div className="divider dark" />
                        <form>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Instagram</span>
                                </label>
                                <input
                                    type="text"
                                    name="account"
                                    placeholder="What needs to be done..."
                                    className={`input input-bordered ${errors.account && 'input-error'}`}
                                    {...register("account", { required: true })}
                                />
                                <label className="label">
                                    {errors.account && <span className="label-text-alt">Data is incorrect</span>}
                                </label>
                            </div>
                        </form>
                        <div className="divider dark" />
                        {error ? (
                            <div className="alert shadow-lg alert-error my-4">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                         fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                    <span className="ml-4">Error! { error }.</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="modal-action">
                            <button
                                onClick={handleSubmit(this.onSubmit)}
                                className={`btn btn-primary ${loading ? 'loading' : ''}`}
                            >
                                Submit
                            </button>
                            <button onClick={this.onCancel} className="btn">Close</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

EnrollStudentsModal.propTypes = {
    modal: PropTypes.bool.isRequired,
    loadingEnrollStudents: PropTypes.bool.isRequired,
    enrollStudentsError: PropTypes.string,
    currentSprint: PropTypes.object.isRequired,
    enrollSprintStudents: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    modal: state.common.modal,
    currentSprint: state.sprint.currentSprint,
    loadingEnrollStudents: state.sprintStudents.loadingEnrollStudents,
    enrollStudentsError: state.sprintStudents.enrollStudentsError
})

const mapDispatchToProps = (dispatch) => ({
    enrollSprintStudents: (students) => dispatch({ type: ENROLL_STUDENTS, payload: students }),
    hideModal: (modalType) => dispatch({ type: HIDE_MODAL, payload: modalType }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(EnrollStudentsModal))
