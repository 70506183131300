import React from "react";
import StudentsTable from "./StudentsTable";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";

import {
    ENROLL_STUDENTS,
    GET_SPRINT_STUDENTS,
    GET_STUDENTS_FOR_ENROLL,
} from "../../../../redux/actions/sprint-students-actions";
import {WRITE_OFF_BALANCES} from "../../../../redux/actions/students-actions";
import ProgressBar from "../../../elements/ProgressBar";


class StudentsTab extends React.Component {
    constructor(props) {
        super(props);

        const headers = [
            { label: "Instagram", key: "instagram" },
            { label: "Balance", key: "balance" },
            { label: "Last Payment Date", key: "lastPaymentDate" }
        ];

        this.state = {
            csvData: []
        }

    }

    componentDidMount() {

        if (this.props.currentSprint.enrolled) {
            this.props.getSprintStudents(this.props.currentSprint.id)
        } else {
            this.props.getStudentsForEnroll()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((!prevProps.currentSprint.enrolled && this.props.currentSprint.enrolled) || (!prevProps.currentSprint.writeOff && this.props.currentSprint.writeOff)) {
            this.props.getSprintStudents(this.props.currentSprint.id)
        }
    }

    enrollStudents = () => {
        this.props.enrollStudents({
            sprint: this.props.currentSprint.id,
        })
    }

    writeOff = () => {
        if (this.props.currentSprint.enrolled) {
            this.props.writeOffBalances({
                sprintId: this.props.currentSprint.id,
            })
        }
    }

    getCsvData = () => {

        const data = this.props.students.map(student => {
            return {
                instagram: student.profile.instagram,
                balance: student.balance.weeks,
                latsPaymentDate: student.balance.lastPaymentDate
            }
        })

        this.setState({ csvData: data })

    }

    render(){

        const enrolled = this.props.currentSprint.enrolled
        const writeOff = this.props.currentSprint.writeOff
        const progress = this.props.writeOfProgress

        return (
            <React.Fragment>
                <div className={ `${this.props.tab === 'tab-2' ? '' : 'hidden'} mt-5` }>
                    {this.props.enrolled ? (
                        <div className="alert shadow-lg mb-4">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     className="stroke-info-content flex-shrink-0 w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span className="ml-4">After enrollment availabe in read-only mode</span>
                            </div>
                        </div>
                    ) : null}
                    <div className={"flex flex-row items-center space-x-4"}>
                        <button
                            className={`btn btn-primary btn-xs mb-2 ${this.props.enrolled ? 'btn-disabled' : ''} ${this.props.loadingEnrollStudents ? 'loading' : ''}`}
                            onClick={this.enrollStudents}
                        >
                            Enroll students
                        </button>
                        <button
                            className={`btn btn-primary btn-xs mb-2 ${(enrolled && writeOff) || (!enrolled && !writeOff) ? 'btn-disabled' : ''}`}
                            onClick={this.writeOff}
                        >
                            Write off
                        </button>
                        <CSVLink
                            className={`btn btn-primary btn-xs mb-2`}
                            filename={'enrolled_students.csv'}
                            data={this.state.csvData}
                            headers={this.headers}
                            onClick={this.getCsvData}
                        >
                            Download Csv
                        </CSVLink>

                    </div>
                    {progress?.progress && !progress?.complete && (
                        <ProgressBar value={progress.progress?.percent} />
                    )}
                    <StudentsTable />
                    {/*<div className="flex flex-grow-1 justify-center mt-4">*/}
                    {/*    <PaginationBack />*/}
                    {/*</div>*/}

                </div>
            </React.Fragment>

        );
    }

}

StudentsTab.propTypes = {
    tab: PropTypes.string,
    enrollStudents: PropTypes.func.isRequired,
    loadingEnrollStudents: PropTypes.bool,
    writeOffBalances: PropTypes.func.isRequired,
    writeOfProgress: PropTypes.object,
    students: PropTypes.array,
    currentSprint: PropTypes.object,
    enrolled: PropTypes.bool
}

const mapStateToProps = (state) => ({
    students: state.sprintStudents.students,
    loadingEnrollStudents: state.sprintStudents.loadingEnrollStudents,
    currentSprint: state.sprint.currentSprint,
    writeOfProgress: state.students.writeOfProgress
})


const mapDispatchToProps = (dispatch) => ({
    enrollStudents: (students) => dispatch({ type: ENROLL_STUDENTS, payload: students }),
    writeOffBalances: (accounts) => dispatch({ type: WRITE_OFF_BALANCES, payload: accounts }),
    getStudentsForEnroll: () => dispatch({ type: GET_STUDENTS_FOR_ENROLL }),
    getSprintStudents: (sprintId) => dispatch({ type: GET_SPRINT_STUDENTS, payload: sprintId }),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentsTab)
