export const SET_FTF_PAIR_ID = "SET_FTF_PAIR_ID"

export const GET_FTF_PAIRS = "GET_FTF_PAIRS"
export const GET_FTF_PAIRS_REQUEST = "GET_FTF_PAIRS_REQUEST"
export const GET_FTF_PAIRS_SUCCESS = "GET_FTF_PAIRS_SUCCESS"
export const GET_FTF_PAIRS_ERROR = "GET_FTF_PAIRS_ERROR"

export const CREATE_FTF_PAIRS = "CREATE_FTF_PAIRS"
export const CREATE_FTF_PAIRS_REQUEST = "CREATE_FTF_PAIRS_REQUEST"
export const CREATE_FTF_PAIRS_SUCCESS = "CREATE_FTF_PAIRS_SUCCESS"
export const CREATE_FTF_PAIRS_ERROR = "CREATE_FTF_PAIRS_ERROR"

export const UPDATE_FTF_PAIR = "UPDATE_FTF_PAIR"
export const UPDATE_FTF_PAIR_REQUEST = "UPDATE_FTF_PAIR_REQUEST"
export const UPDATE_FTF_PAIR_SUCCESS = "UPDATE_FTF_PAIR_SUCCESS"
export const UPDATE_FTF_PAIR_ERROR = "UPDATE_FTF_PAIR_ERROR"

export const SET_FTF_PAIRS = "SET_FTF_PAIRS"
export const SET_FTF_PAIRS_REQUESTED = "SET_FTF_PAIRS_REQUESTED"

export const SET_FTF_PAIR_ID_REQUESTED = "SET_FTF_PAIR_ID_REQUESTED"
