import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import PaginationBack from "../../../elements/pagination/Pagination";
import {SHOW_MODAL} from "../../../../redux/actions/common-actions";
import HomeworksTable from "./HomeworksTable";

class HomeworkTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    openModal(modalType) {
        this.props.showModal(modalType)
    }

    render(){
        return (
            <React.Fragment>
                <div className={ `${this.props.tab === 'tab-1' ? '' : 'hidden'} mt-5` }>
                        <div className={"flex flex-row items-center"}>
                            <button
                                onClick={() => this.openModal('createHomework')}
                                className={"btn btn-primary btn-xs mb-2"}
                            >
                                Add homework
                            </button>
                        </div>

                        <div className={"shadow"}>
                            <HomeworksTable />
                        </div>
                        <div className="flex flex-grow-1 justify-center mt-4">
                            <PaginationBack />
                        </div>
                    </div>
            </React.Fragment>

        );
    }

}

HomeworkTab.propTypes = {
    tab: PropTypes.string,
    showModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
})


const mapDispatchToProps = (dispatch) => ({
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeworkTab)
