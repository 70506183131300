import React from 'react'

import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";

import {connect} from "react-redux";


class Image extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

    }

    render() {

        return (
            <React.Fragment>
                <div className="w-full aspect-video self-center">
                    {this.props.config.image ? (
                        <img alt="homework" src={this.props.config.image} className={"object-cover shadow-lg"} />
                    ) : null}

                </div>
            </React.Fragment>
        );
    }
}

Image.propTypes = {
    loading: PropTypes.bool,
    config: PropTypes.object
}

const mapStateToProps = (state) => ({
    loading: state.config.loading,
    config: state.config.config
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Image)
