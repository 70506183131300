import {homeworkApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const homeworkClient = client(`${homeworkApiUrl}/api`)

export const getPairsByFtf = async (id) => {
    try {
        const response = await homeworkClient.get(`/ftf-pairs/?ftf_id=${id}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const updateFtfPair = async (pair) => {
    try {
        let data = snakecaseKeys(pair)
        const response = await homeworkClient.put(`/ftf-pairs/${pair.id}/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const BulkCreateFtfPairs = async (pairs) => {
    try {
        let data = snakecaseKeys(pairs)
        let response = await homeworkClient.post(`/ftf-pairs/`, data)
        response.data = JSON.parse(response.data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}
