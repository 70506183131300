import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    GET_ANSWERS_BY_HOMEWORK_REQUESTED,
} from '../../redux/actions/homework-actions'

import AnswerItem from "./AnswerItem";

// import {useParams} from 'react-router-dom';
import Skeleton from "./Skeleton";

const AnswerTable = ({ loading, answers, getAnswersByHomework }) => {
    // const { id } = useParams();

    // useEffect(() => {
    //     getAnswersByHomework(id)
    // }, []);

   return (
        <React.Fragment>
            <div className="overflow-x-auto">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th>
                                <label>
                                    <input type="checkbox" className="checkbox" />
                                </label>
                            </th>
                            <th>Account</th>
                            <th>Results</th>
                            <th></th>
                        </tr>
                    </thead>
                    {loading ? (
                        <>
                            {[...Array(5)].map((x, i) =>
                                <Skeleton key={i} />
                            )}
                        </>
                    ) : (
                        <>
                            {answers.length === 0 ? (
                                <td colSpan={4}><h1 className="my-10 text-2xl text-center">No Data</h1></td>
                            ) : (
                                <tbody>
                                {answers && answers.map((answer, index) => (
                                    <AnswerItem answer={answer} key={index} />
                                ))}
                                </tbody>
                            )}

                        </>
                    )}
                    <tfoot>
                        <tr>
                            <th></th>
                            <th>Account</th>
                            <th>Results</th>
                            <th></th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </React.Fragment>
    );
}

AnswerTable.propTypes = {
    loading: PropTypes.bool,
    answers: PropTypes.array,
    getAnswersByHomework: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    loading: state.homework.loading,
    answers: state.homework.answers,
})

const mapDispatchToProps = (dispatch) => ({
    getAnswersByHomework: (id) => dispatch({ type: GET_ANSWERS_BY_HOMEWORK_REQUESTED, payload: id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AnswerTable)
