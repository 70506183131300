import {FaMicrophone, FaTimes, FaSave} from "react-icons/fa";
import { formatMinutes, formatSeconds } from "../../utils/format-time";
import './style.css'

export default function RecorderControls({ recorderState, handlers }) {
  const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording, cancelRecording } = handlers;

  return (
    <div className="flex flex-row items-center justify-between">
        <div className={"flex flex-row items-center"}>
            {initRecording && <div className="recording-indicator"></div>}
            <div className={'text-2xl'}>
                <span>{formatMinutes(recordingMinutes)}</span>
                <span>:</span>
                <span>{formatSeconds(recordingSeconds)}</span>
            </div>
        </div>
        <div className={"flex flex-row items-center"}>
            {initRecording && (
                <button className={"ml-5"} title="Cancel recording" onClick={cancelRecording}>
                    <FaTimes />
                </button>
            )}
            {initRecording ? (
                <button
                    title="Save recording"
                    disabled={recordingSeconds === 0}
                    onClick={saveRecording}
                >
                    <FaSave />
                </button>
            ) : (
                <button title="Start recording" onClick={startRecording}>
                    <FaMicrophone />
                </button>
            )}
        </div>

    </div>
  );
}
