import {
    put,
    call,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects'

import {
    GET_SPRINT_STUDENTS_REQUEST,
    GET_SPRINT_STUDENTS_SUCCESS,
    GET_SPRINT_STUDENTS_ERROR,
    GET_SPRINT_STUDENTS,
    ENROLL_STUDENTS_ERROR,
    ENROLL_STUDENTS,
    ENROLL_STUDENTS_SUCCESS,
    ENROLL_STUDENTS_REQUEST,
    UNENROLL_STUDENT_REQUEST,
    UNENROLL_STUDENT_SUCCESS,
    UNENROLL_STUDENT_ERROR,
    UNENROLL_STUDENT,
    UPDATE_SPRINT_STUDENT_REQUEST,
    UPDATE_SPRINT_STUDENT_SUCCESS,
    UPDATE_SPRINT_STUDENT_ERROR,
    UPDATE_SPRINT_STUDENT, GET_STUDENTS_FOR_ENROLL
} from "../actions/sprint-students-actions";

import sprintStudentsApi from "../api/sprint-students-api";
import {UPDATE_SPRINT} from "../actions/sprint-actions";

function* getSprintStudents({ payload }) {

    yield put({ type: GET_SPRINT_STUDENTS_REQUEST })

    let response = yield call(sprintStudentsApi.getStudentsBySprints, payload)

    const accounts = {
        accounts: response.data.map(student=>{
            return student.account
        })
    }

    if(response.status === 200) {

        response = yield call(sprintStudentsApi.getAccountsByIds, accounts)

        if(response.status === 200) {
            yield put({ type: GET_SPRINT_STUDENTS_SUCCESS, payload: response.data })
        } else if (response.status === 404) {
            yield put({ type: GET_SPRINT_STUDENTS_SUCCESS, payload: [] })
        } else {
            yield put({ type: GET_SPRINT_STUDENTS_ERROR })
        }

    } else if (response.status === 404) {
        yield put({ type: GET_SPRINT_STUDENTS_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_SPRINT_STUDENTS_ERROR })
    }
}

function* getStudentsForEnroll() {

    yield put({ type: GET_SPRINT_STUDENTS_REQUEST })

    const response = yield call(sprintStudentsApi.getStudentsForEnroll)

    if(response.status === 200) {
        yield put({ type: GET_SPRINT_STUDENTS_SUCCESS, payload: response.data })
    } else if (response.status === 404) {
        yield put({ type: GET_SPRINT_STUDENTS_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_SPRINT_STUDENTS_ERROR })
    }
}

function* enrollStudents({ payload }){

    yield put({ type: ENROLL_STUDENTS_REQUEST })

    let response = yield call(sprintStudentsApi.enrollStudents, payload)

    const  updateData = {
        data: {enrolled: true},
        id: payload.sprint
    }

    if (response.status === 200) {
        yield put({ type: ENROLL_STUDENTS_SUCCESS })
        yield put({ type: UPDATE_SPRINT, payload: updateData });
    }

    if(response.status >=400 && response.status < 600){
        yield put({ type: ENROLL_STUDENTS_ERROR })
    }
}

export default function* sprintStudentsSaga() {
    yield takeEvery(GET_SPRINT_STUDENTS, getSprintStudents)
    yield takeEvery(GET_STUDENTS_FOR_ENROLL, getStudentsForEnroll)
    yield takeLatest(ENROLL_STUDENTS, enrollStudents)
}
