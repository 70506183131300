import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import {
    CREATE_HOMEWORK,
} from '../../../redux/actions/homework-actions'

import {
    HIDE_MODAL
} from "../../../redux/actions/common-actions";
import {withUseFormHook} from "../../../helpers/with-form-hook";
import moment from "moment";

class CreateHomeworkModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if( prevProps.loadingCreateHomework && !this.props.loadingCreateHomework && this.props.createHomeworkError === null ) {
            this.props.hideModal('createHomework')
        }
    }

    onSubmit = (data) => {
        this.props.createHomework({
            title: data.title,
            startDate: data.startDate,
            sprintId: this.props.currentSprint.id,
            status: 'inProgress',
            link: null
        })
    }

    onCancel = (e) => {
        e.preventDefault()
        this.props.hideModal('createHomework')
    }

    validateDate = (value) => {
        const sprintStartDate = moment(this.props.currentSprint.startDate)
        const sprintEndDate = moment(this.props.currentSprint.endDate)
        const currentDate = moment(value)
        return (currentDate >= sprintStartDate && moment(value) <= sprintEndDate)
            || `The date must be between ${sprintStartDate.format('DD-MM-YYYY')} and ${sprintEndDate.format('DD-MM-YYYY')}`
    }

    render() {
        const modalStyle = this.props.modal ? 'modal modal-open' : "modal"
        const { register, handleSubmit, formState: { errors } } = this.props.form;
        const loading = this.props.loadingCreateHomework
        const error = this.props.createHomeworkError

        return (
            <React.Fragment>
                <div className={modalStyle}>
                    <div className="modal-box">
                        <div className={"text-xl font-bold"}>Add a new homework</div>
                        <div className="divider dark" />
                        <form>
                            <div className="w-full form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Date</span>
                                </label>
                                <input
                                    type="date"
                                    name="startDate"
                                    className={`w-full input input-bordered ${errors.startDate && 'input-error'}`}
                                    {...register("startDate", {
                                        required: 'Data is incorrect',
                                        validate: (value) => this.validateDate(value)
                                    })}
                                />
                                <label className="label">
                                    {errors.startDate && <span className="label-text-alt">{errors.startDate.message}</span>}
                                </label>
                            </div>
                            <div className="form-control my-2">
                                <label className="label">
                                    <span className="label-text">Title</span>
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    placeholder="What needs to be done..."
                                    className={`input input-bordered ${errors.title && 'input-error'}`}
                                    {...register("title", { required: true })}
                                />
                                <label className="label">
                                    {errors.title && <span className="label-text-alt">Data is incorrect</span>}
                                </label>
                            </div>
                        </form>
                        <div className="divider dark" />
                        {error ? (
                            <div className="alert shadow-lg alert-error my-4">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                         fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                    <span className="ml-4">Error! { error }.</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="modal-action">
                            <button onClick={handleSubmit(this.onSubmit)} className={`btn btn-primary ${loading ? 'loading' : ''}`}>Submit</button>
                            <button onClick={this.onCancel} className="btn">Close</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CreateHomeworkModal.propTypes = {
    currentSprint: PropTypes.object.isRequired,
    createHomework: PropTypes.func.isRequired,
    loadingCreateHomework: PropTypes.bool.isRequired,
    createHomeworkError: PropTypes.string.isRequired,
    modal: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    modal: state.common.modal,
    loadingCreateHomework: state.homework.loadingCreateHomework,
    createHomeworkError: state.homework.createHomeworkError,
    currentSprint: state.sprint.currentSprint,
})

const mapDispatchToProps = (dispatch) => ({
    createHomework: (homework) => dispatch({ type: CREATE_HOMEWORK, payload: homework }),
    hideModal: (modalType) => dispatch({ type: HIDE_MODAL, payload: modalType }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(CreateHomeworkModal))
