import {
    put,
    call,
    takeLatest
} from 'redux-saga/effects'

import {
    SET_LOADING,
    SET_FTF,
    DELETE_FTF_REQUESTED,
    GET_FTF_BY_SPRINT,
    GET_FTF_BY_SPRINT_REQUEST,
    GET_FTF_BY_SPRINT_SUCCESS,
    GET_FTF_BY_SPRINT_ERROR,
    CREATE_FTF_REQUEST,
    CREATE_FTF_SUCCESS,
    CREATE_FTF_ERROR,
    CREATE_FTF,
} from "../actions/ftf-actions";

import {
    getFtfBySprint,
    createNewFtf,
    deleteExistedFtf,
} from "../api/ftf-api";

function* getFtf({ payload }) {

    yield put({ type: GET_FTF_BY_SPRINT_REQUEST })

    const response = yield call(getFtfBySprint, payload)

    if (response.status === 200) {
        yield put({ type: GET_FTF_BY_SPRINT_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: GET_FTF_BY_SPRINT_ERROR })
    }
}

function* createFtf({ payload }) {

    yield put({ type: CREATE_FTF_REQUEST })

    const response = yield call(createNewFtf, payload.ftf)

    if (response.status === 200) {
        yield put({ type: CREATE_FTF_SUCCESS, payload: response.data })
    }

    if(response.status >=400 && response.status < 600){
        yield put({ type: CREATE_FTF_ERROR })
    }
}

function* deleteFtf({ payload }) {
    yield put({ type: SET_LOADING, payload: true })
    yield call(deleteExistedFtf, payload)
    yield put({ type: SET_FTF, payload: [] })
}

export default function* ftfSaga() {
    yield takeLatest(GET_FTF_BY_SPRINT, getFtf)
    yield takeLatest(CREATE_FTF, createFtf)
    yield takeLatest(DELETE_FTF_REQUESTED, deleteFtf)
}
