export const SET_LOADING = "SET_LOADING"

export const GET_HOMEWORKS_BY_SPRINT = "GET_HOMEWORKS_BY_SPRINT"
export const GET_HOMEWORKS_BY_SPRINT_REQUEST = "GET_HOMEWORKS_BY_SPRINT_REQUEST"
export const GET_HOMEWORKS_BY_SPRINT_SUCCESS = "GET_HOMEWORKS_BY_SPRINT_SUCCESS"
export const GET_HOMEWORKS_BY_SPRINT_ERROR = "GET_HOMEWORKS_BY_SPRINT_ERROR"

export const CREATE_HOMEWORK = "CREATE_HOMEWORK"
export const CREATE_HOMEWORK_REQUEST = "CREATE_HOMEWORK_REQUEST"
export const CREATE_HOMEWORK_SUCCESS = "CREATE_HOMEWORK_SUCCESS"
export const CREATE_HOMEWORK_ERROR = "CREATE_HOMEWORK_ERROR"

export const UPDATE_HOMEWORK = "UPDATE_HOMEWORK"
export const UPDATE_HOMEWORK_REQUEST = "UPDATE_HOMEWORK_REQUEST"
export const UPDATE_HOMEWORK_SUCCESS = "UPDATE_HOMEWORK_SUCCESS"
export const UPDATE_HOMEWORK_ERROR = "UPDATE_HOMEWORK_ERROR"

export const GET_HOMEWORK_BY_ID_REQUESTED = "GET_HOMEWORKS_BY_ID_REQUESTED"

export const SET_CURRENT_HOMEWORK = "SET_CURRENT_HOMEWORK"
export const SET_CURRENT_HOMEWORK_REQUESTED = "SET_CURRENT_HOMEWORK_REQUESTED"

export const SET_HOMEWORK_TITLE = "SET_HOMEWORK_TITLE"
export const SET_HOMEWORK_TITLE_REQUESTED = "SET_HOMEWORK_TITLE_REQUESTED"

export const UPDATE_HOMEWORK_REQUESTED = "UPDATE_HOMEWORK_REQUESTED"

export const DELETE_HOMEWORK = "DELETE_HOMEWORK"
export const DELETE_HOMEWORK_REQUESTED = "DELETE_HOMEWORK_REQUESTED"

export const CLEAR_HOMEWORK_FORM = "CLEAR_HOMEWORK_FORM"

export const GET_ANSWERS_BY_HOMEWORK = "GET_ANSWERS_BY_HOMEWORK"
export const GET_ANSWERS_BY_HOMEWORK_REQUESTED = "GET_ANSWERS_BY_HOMEWORK_REQUESTED"
