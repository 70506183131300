import {
    SET_CURRENT_PAGE,
    SHOW_MODAL,
    HIDE_MODAL,
} from "../actions/common-actions";
import {getNavTitle} from "../../helpers/navigation";

const initialState = {
    currentPage: 'Test',
    modal: false,
    modalType: null
}

export default function commonReducer(state = initialState, {type, payload}) {
    switch(type) {
        case SET_CURRENT_PAGE:
            let title = getNavTitle(payload)
            return {
                ...state,
                currentPage: title
            }

        case SHOW_MODAL:
            return {
                ...state,
                modal: true,
                modalType: payload
            };
        case HIDE_MODAL:
            return {
                ...state,
                modal: false,
                modalType: null
            };
        default:
            return state
    }
}
