import React from "react";
import {connect} from "react-redux";
import moment from "moment/moment";
import PropTypes from "prop-types";
import {CopyToClipboard} from "react-copy-to-clipboard";

class UserCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            copied: false,
        };
    }

    copyEffect = () => {
        this.setState({copied: true});
        setTimeout(() => {
            this.setState({copied: false});
        }, 500)
    }

    render(){

        const username = this.props.username
        const dateJoined = moment(new Date(this.props.dateJoined)).format('DD-MM-YYYY')
        const isAdmin = this.props.isAdmin
        const isStudent = this.props.isStudent
        const isStaff = this.props.isStaff
        const country = this.props.country
        const currency = this.props.currency
        const firstName = this.props.firstName
        const lastName = this.props.lastName

        return (
            <div className="w-full bg-base-100">
                <div className={"flex flex-row justify-start items-center space-x-2"}>
                    <h2 className="card-title mb-1">{username}</h2>
                    {isAdmin && <div className="badge">Admin</div>}
                    {isStudent && <div className="badge badge-primary">Student</div>}
                    {isStaff && <div className="badge badge-secondary">Staff</div>}
                    <div className="badge badge-secondary">{this.props.isActive ? 'Active' : 'Inactive'}</div>
                    {country && <div className="badge badge-accent capitalize">{country}</div>}
                    {currency && <div className="badge badge-accent capitalize">{currency}</div>}
                </div>
                <p className={"prose"}>{firstName} {lastName}</p>
                <p className={"prose"}>Data Joined: {dateJoined}</p>
                <p className={"prose"}>UID: {this.props.uid} <CopyToClipboard text={this.props.uid} onCopy={this.copyEffect}>
                    <button className={`btn btn-xs mr-2 ${this.state.copied ? "btn-accent" : ""}`}>Copy</button>
                </CopyToClipboard></p>
            </div>
        );
    }
}

UserCard.propTypes = {
    uid: PropTypes.string,
    username: PropTypes.string,
    dateJoined: PropTypes.string,
    isStudent: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isStaff: PropTypes.bool,
    isActive: PropTypes.bool,
    lastPaymentDate: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string
}

const mapStateToProps = (state) => ({
})


const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(UserCard)
