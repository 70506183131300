import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../context/AuthContext";
import {connect} from "react-redux";

function PrivateRoute({ children, userInfo }) {

    const location = useLocation();
    const pathname = location.pathname || '/'

    const { currentUser } = useAuth()

    if (!currentUser || !userInfo) {
        return <Navigate to={'/login'} state={{ from: pathname }} />
    }

    return children;
}

PrivateRoute.propTypes = {
    userInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
