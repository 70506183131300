export const GET_STUDENTS = "GET_STUDENTS"
export const GET_STUDENTS_REQUEST = "GET_STUDENTS_REQUEST"
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS"
export const GET_STUDENTS_ERROR = "GET_STUDENTS_ERROR"

export const GET_STUDENT = "GET_STUDENT"
export const GET_STUDENT_REQUEST = "GET_STUDENT_REQUEST"
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS"
export const GET_STUDENT_ERROR = "GET_STUDENT_ERROR"

export const UPDATE_STUDENT_PROFILE = "UPDATE_STUDENT_PROFILE"
export const UPDATE_STUDENT_PROFILE_REQUEST = "UPDATE_STUDENT_PROFILE_REQUEST"
export const UPDATE_STUDENT_PROFILE_SUCCESS = "UPDATE_STUDENT_PROFILE_SUCCESS"
export const UPDATE_STUDENT_PROFILE_ERROR = "UPDATE_STUDENT_PROFILE_ERROR"

export const UPDATE_STUDENT_BALANCE = "UPDATE_STUDENT_BALANCE"
export const UPDATE_STUDENT_BALANCE_REQUEST = "UPDATE_STUDENT_BALANCE_REQUEST"
export const UPDATE_STUDENT_BALANCE_SUCCESS = "UPDATE_STUDENT_BALANCE_SUCCESS"
export const UPDATE_STUDENT_BALANCE_ERROR = "UPDATE_STUDENT_BALANCE_ERROR"

export const WRITE_OFF_BALANCES = "WRITE_OFF_BALANCES"
export const WRITE_OFF_BALANCES_REQUEST = "WRITE_OFF_BALANCES_REQUEST"
export const WRITE_OFF_BALANCES_SUCCESS = "WRITE_OFF_BALANCES_SUCCESS"
export const WRITE_OFF_BALANCES_ERROR = "WRITE_OFF_BALANCES_ERROR"

export const WRITE_OFF_PROGRESS = "WRITE_OFF_PROGRESS"

export const GET_BALANCE_HISTORY = "GET_BALANCE_HISTORY"
export const GET_BALANCE_HISTORY_REQUEST = "GET_BALANCE_HISTORY_REQUEST"
export const GET_BALANCE_HISTORY_SUCCESS = "GET_BALANCE_HISTORY_SUCCESS"
export const GET_BALANCE_HISTORY_ERROR = "GET_BALANCE_HISTORY_ERROR"

export const RESET_STUDENT_DATA = "RESET_STUDENT_DATA"
export const RESET_STUDENTS_DATA = "RESET_STUDENTS_DATA"
