import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Skeleton from "./FtfSkeleton";

import {SHOW_MODAL} from "../../../../redux/actions/common-actions";
import {
    CREATE_FTF_PAIRS,
    GET_FTF_PAIRS,
    SET_FTF_PAIR_ID
} from "../../../../redux/actions/ftf-pairs-actions";
import listOfPairs from "../../../../helpers/randomPair";
import {withRouter} from "../../../../helpers/with-router";

class FtfTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.props.getFtfPairs(this.props.params.id)
    }

    editFtfPair = (ftfPairId) => {
        this.props.setFtfPairId(ftfPairId)
        this.props.showModal('editFtfPair')
    }

    handlePairUp = () => {
        let pairsResults  = listOfPairs(this.props.students)
        this.props.createFtfPairs({
            sprint: this.props.params.id,
            pairs: pairsResults.pairs
        })
    }

    render(){

        let loading = this.props.loadingFtfPairs

        return (
            <React.Fragment>
                <div className="overflow-x-auto">
                    <table className="table w-full table-compact">
                        <thead>
                        <tr>
                            <th>
                                <label>
                                    <input type="checkbox" className="checkbox" />
                                </label>
                            </th>
                            <th>Student A</th>
                            <th>Student B</th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>
                        {loading ? (
                            <>
                                {[...Array(5)].map((x, i) =>
                                    <Skeleton key={i} />
                                )}
                            </>
                        ) : (
                            <>
                                {!this.props.pairs ? (
                                    <tr>
                                        {this.props.students && this.props.students.length > 0 ? (
                                            <>
                                                {this.props.ftf ? (
                                                    <td colSpan={4} align="center">
                                                        <button className={"my-10 btn btn-primary"} onClick={this.handlePairUp}>Pair up</button>
                                                    </td>
                                                ) : (
                                                    <td colSpan={4}><h1 className="my-10 text-2xl text-center">Add FTF Task to the sprint before creating pairs</h1></td>
                                                )}
                                            </>

                                        ) : (
                                            <td colSpan={4}><h1 className="my-10 text-2xl text-center">Add students to the sprint before creating pairs</h1></td>
                                        ) }

                                    </tr>
                                ) : (
                                    <>
                                        {this.props.pairs.map((pair, index) => (
                                            <tr key={index}>
                                                <th>
                                                    <label>
                                                        <input type="checkbox" className="checkbox"  />
                                                    </label>
                                                </th>
                                                <td>
                                                    <div className="flex items-center space-x-3">
                                                        <div>
                                                            <div className="font-bold">{pair.studentA}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex items-center space-x-3">
                                                        <div>
                                                            <div className="font-bold">{pair.studentB}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <th>
                                                    <button className={"btn btn-ghost btn-xs btn-disabled"} onClick={()=>this.editFtfPair(pair.id)}> Edit </button>
                                                </th>
                                            </tr>
                                        ))}

                                    </>
                                )}

                            </>
                        )}

                        </tbody>
                        <tfoot>
                        <tr>
                            <th />
                            <th>Student A</th>
                            <th>Student B</th>
                            <th />
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}


FtfTable.propTypes = {
    loadingFtfPairs: PropTypes.bool,
    params: PropTypes.any,
    students: PropTypes.array,
    pairs: PropTypes.array,
    showModal: PropTypes.func.isRequired,
    setFtfPairId: PropTypes.func,
    createFtfPairs: PropTypes.func,
}

const mapStateToProps = (state) => ({
    loadingFtfPairs: state.ftfPairs.loadingFtfPairs,
    students: state.sprintStudents.students,
    pairs: state.ftfPairs.pairs,
    ftf: state.ftf.ftf
})

const mapDispatchToProps = (dispatch) => ({
    setFtfPairId: (ftfPairId) => dispatch({ type: SET_FTF_PAIR_ID, payload: ftfPairId }),
    getFtfPairs: (sprintId) => dispatch({ type: GET_FTF_PAIRS, payload: sprintId }),
    createFtfPairs: (pairs) => dispatch({ type: CREATE_FTF_PAIRS, payload: pairs }),
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FtfTable))
