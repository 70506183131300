import {homeworkApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const homeworkClient = client(`${homeworkApiUrl}/api/preference`)

const getOptions = async () => {
    try {
        const response = await homeworkClient.get('/option/')
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getOption = async (id) => {
    try {
        const response = await homeworkClient.get(`/option/${id}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const updateOption = async (option) => {
    try {
        let data = snakecaseKeys(option)
        const response = await homeworkClient.patch(`/option/${option.id}/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const preferenceApi = {
    getOptions,
    getOption,
    updateOption,
}

export default preferenceApi
