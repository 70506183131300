import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {GET_SPRINT} from "../redux/actions/sprint-actions";
import Stat from "../components/elements/Stat";
import {withRouter} from "../helpers/with-router";
import HomeworksTab from "../components/Sprint/Tabs/Homework/HomeworksTab";
import FtfTab from "../components/Sprint/Tabs/Ftf/FtfTab";
import StudentsTab from "../components/Sprint/Tabs/Students/StudentsTab";

class SprintContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: 'tab-1',
            id: this.props.params.id
        }
    }


    componentDidMount() {
        this.props.getSprint(this.state.id)
    }

    render(){

        return (
            <React.Fragment>
                <div className="flex flex-col drawer-content">
                    <h1 className="prose-2xl mb-2">Stat</h1>
                    <div className="w-full shadow stats mb-10">
                        <Stat
                            title={"Enrolled"}
                            number={this.props.enrolledStudentsCount}
                            desc={"To be enrolled or enrolled in a sprint"}
                        />
                        <Stat />
                        <Stat />
                    </div>
                    <div className={"flex flex-row justify-between items-center"}>
                        <div className="text-sm breadcrumbs mb-5">
                            <ul>
                                <li>
                                    <Link to={"/sprints"}>Sprints</Link>
                                </li>
                                <li>{this.props.currentSprint && this.props.currentSprint.title}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="tabs">
                        <div className="tabs">
                            <button onClick={() => this.setState({tab: 'tab-1'})} className={`tab tab-lifted ${ this.state.tab === 'tab-1' ? 'tab-active' : '' }`}>Homeworks</button>
                            <button  onClick={() => this.setState({tab: 'tab-2'})} className={`tab tab-lifted disabled ${ this.state.tab === 'tab-2' ? 'tab-active' : '' }`}>Students</button>
                            <button  onClick={() => this.setState({tab: 'tab-3'})} className={`tab tab-lifted disabled ${ this.state.tab === 'tab-3' ? 'tab-active' : '' }`}>FTF</button>
                        </div>
                    </div>

                    {/* Вкладка Задания */}
                    <HomeworksTab tab={this.state.tab} />

                    {/* Вкладка Студенты */}
                    {this.props.currentSprint ? (
                        <StudentsTab tab={this.state.tab} enrolled={this.props.currentSprint.enrolled}/>
                    ) : null}

                    {/* Вкладка FTF */}
                    <FtfTab tab={this.state.tab} />

                </div>
            </React.Fragment>
        );
    }

}

SprintContainer.propTypes = {
    params: PropTypes.any,
    currentSprint: PropTypes.object,
    getSprint: PropTypes.func,
    setCurrentSprint: PropTypes.func.isRequired,
    enrolledStudentsCount: PropTypes.number
}

const mapStateToProps = (state) => ({
    currentSprint: state.sprint.currentSprint,
    enrolledStudentsCount: state.sprintStudents.enrolledStudentsCount,
    ftf: state.ftf.ftf
})


const mapDispatchToProps = (dispatch) => ({
    getSprint: (sprintId) => dispatch({ type: GET_SPRINT, payload: sprintId }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SprintContainer))
