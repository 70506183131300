import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
    GET_SPRINTS
} from '../../redux/actions/sprint-actions'

import SprintItem from './SprintItem'
import Skeleton from "../Sprints/Skeleton";

class SprintsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.props.getSprints()
    }

    render() {
        let loading = this.props.loadingSprints
        let sprints = this.props.sprints
        let error = this.props.getSprintsError

        return (
            <React.Fragment>
                <div className="overflow-x-auto">
                    <table className="table w-full">
                        <thead>
                        <tr>
                            <th>
                                <label>
                                    <input type="checkbox" className="checkbox" />
                                </label>
                            </th>
                            <th>Sprint Title</th>
                            <th>Category</th>
                            <th>Period</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <>
                                    {[...Array(5)].map((x, i) =>
                                        <Skeleton key={i} />
                                    )}
                                </>
                            ) :
                                <>

                                    {sprints && !error ? (
                                        sprints.length > 0 ? sprints.map((sprint) => (
                                            <SprintItem sprint={sprint} key={sprint.id} />
                                        )) : (
                                            <tr>
                                                <td colSpan={6}><h1 className="my-10 text-2xl text-center">{ 'No Data' }</h1></td>
                                            </tr>
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={6}><h1 className="my-10 text-2xl text-center">{ error }</h1></td>
                                        </tr>
                                    )}

                                </>
                            }
                        </tbody>
                        <tfoot>
                        <tr>
                            <th></th>
                            <th>Sprint Title</th>
                            <th>Category</th>
                            <th>Period</th>
                            <th></th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </React.Fragment>
        )

    }
}

SprintsTable.propTypes = {
    loadingSprints: PropTypes.bool,
    sprints: PropTypes.array,
    getSprintsError: PropTypes.string,
    getSprints: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    loadingSprints: state.sprint.loadingSprints,
    sprints: state.sprint.sprints,
    getSprintsError: state.sprint.getSprintsError
})

const mapDispatchToProps = (dispatch) => ({
    getSprints: () => dispatch({ type: GET_SPRINTS }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SprintsTable)

