import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import {
    HIDE_MODAL
} from "../../../redux/actions/common-actions";
import {UPDATE_FTF_PAIR} from "../../../redux/actions/ftf-pairs-actions";


class EditFtfPairModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            ftfId: '',
            studentA: '',
            studentB: '',
            roleA: '',
            roleB: ''
        }
    }

    componentDidMount() {
        const pair = this.props.pairs.find(pair => pair.id === this.props.ftfPairId)

        this.setState({
            id: pair.id,
            ftfId: pair.ftfId,
            studentA: pair.studentA,
            studentB: pair.studentB,
            roleA: pair.roleA,
            roleB: pair.roleB
        })
    }

    onChange = (e) => {

        if(e.target.name === 'studentA'){
            return this.setState({studentA: e.target.value})
        } else if(e.target.name === 'studentB') {
            return this.setState({studentB: e.target.value})
        } else if(e.target.name === 'roleA') {
            return this.setState({roleA: e.target.value})
        } else if(e.target.name === 'roleB') {
            return this.setState({roleB: e.target.value})
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.props.updatePair({
            id: this.state.id,
            ftfId: this.state.ftfId,
            studentA: {student: this.state.studentA, role: this.state.roleA},
            studentB: {student: this.state.studentB, role: this.state.roleB}
        })
        this.props.hideModal('editFtfPair')
    }

    onCancel = (e) => {
        e.preventDefault()
        this.props.hideModal('editFtfPair')
    }

    render() {
        const modalStyle = this.props.modal ? 'modal modal-open' : "modal"

        return (
            <React.Fragment>
                <div className={modalStyle}>
                    <div className="modal-box">
                        <div className={"text-xl font-bold"}>Update pair</div>
                        <div className="divider" />
                        <div className={"my-10"}>
                            <form>
                                <div className="flex flex-row justify-between">
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Student A</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="studentA"
                                            placeholder="Set instagram account without @"
                                            onChange={this.onChange}
                                            value={this.state.studentA}
                                            className="input input-bordered"
                                        />
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Student B</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="studentB"
                                            placeholder="Set instagram account without @"
                                            onChange={this.onChange}
                                            value={this.state.studentB}
                                            className="input input-bordered"
                                        />
                                    </div>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Role A</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="roleA"
                                        placeholder="Set role for Student A"
                                        onChange={this.onChange}
                                        value={this.state.roleA}
                                        className="input input-bordered"
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Role B</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="roleB"
                                        placeholder="Set role for Student B"
                                        onChange={this.onChange}
                                        value={this.state.roleB}
                                        className="input input-bordered"
                                    />
                                </div>

                            </form>
                        </div>

                        <div className="divider" />
                        <div className="modal-action">
                            <button onClick={this.onSubmit} className="btn btn-primary">Submit</button>
                            <button onClick={this.onCancel} className="btn">Close</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

EditFtfPairModal.propTypes = {
    pairs: PropTypes.array,
    ftfPairId: PropTypes.string,
    modal: PropTypes.bool,
    updatePair: PropTypes.func,
    hideModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    pairs: state.ftf.pairs,
    ftfPairId: state.ftf.ftfPairId,
    modal: state.common.modal,
})

const mapDispatchToProps = (dispatch) => ({
    updatePair: (pair) => dispatch({ type: UPDATE_FTF_PAIR, payload: pair }),
    hideModal: (modalType) => dispatch({ type: HIDE_MODAL, payload: modalType }),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditFtfPairModal)
