import React from "react";
import * as PropTypes from "prop-types";

function ToastMessage({ text, withSupportButton, withTryAgain, code, closeToast, toastProps }) {

    let body;

    const mainText = <span>{text} </span>

    const supportText = <span>get in touch with <a className={"underline underline-offset-2"} href={'https://instagram.com/eis_support'}>technical support.</a> </span>

    const errorCodeText = <span>Error code {code}. </span>

    const tryAgainText = <span>try again. </span>

    if(withSupportButton && withTryAgain){
        body = <div className={"leading-tight text-sm"}>{mainText} Please, {supportText} or {tryAgainText} {code && errorCodeText}</div>
    } else if(withSupportButton && !withTryAgain) {
        body = <div className={"leading-tight text-sm"}>{mainText} Please, {supportText} {code && errorCodeText}</div>
    } else if(withTryAgain && !withSupportButton) {
        body = <div className={"leading-tight text-sm"}>{mainText} Please, {tryAgainText} {code && errorCodeText}</div>
    } else {
        body = <div className={"leading-tight text-sm"}>{mainText} {code && errorCodeText}</div>
    }
    return (
        <div className={"flex flex-col justify-start items-start"}>
            {body}
        </div>
    )
}

ToastMessage.propTypes = {
    text: PropTypes.string,
    withSupportButton: PropTypes.bool,
    code: PropTypes.number
};

export default ToastMessage
