import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import HomeworkItem from "./HomeworkItem";
import HomeworkSkeleton from "./HomeworkSkeleton";

import {
    GET_HOMEWORKS_BY_SPRINT
} from '../../../../redux/actions/homework-actions'
import {withRouter} from "../../../../helpers/with-router";



class HomeworksTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.props.getHomeworks(this.props.params.id)
    }

    render() {

        let loading = this.props.loadingHomeworks
        let homeworks = this.props.homeworks
        let error = this.props.getHomeworksError

        return (
            <React.Fragment>
                <div className="overflow-x-auto">
                    <table className="table w-full">
                        <thead>
                        <tr>
                            <th>
                                <label>
                                    <input type="checkbox" className="checkbox" />
                                </label>
                            </th>
                            <th>Homework Title</th>
                            <th>Date</th>
                            <th>Link</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>
                        {loading ? (
                                <>
                                    {[...Array(5)].map((x, i) =>
                                        <HomeworkSkeleton key={i} />
                                    )}
                                </>
                            ) :
                            <>

                                {homeworks && !error ? (
                                    homeworks.length > 0 ? homeworks.map((homework) => (
                                        <HomeworkItem homework={homework} key={homework.id} />
                                    )) : (
                                        <tr>
                                            <td colSpan={6}><h1 className="my-10 text-2xl text-center">{ 'No Data' }</h1></td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={6}><h1 className="my-10 text-2xl text-center">{ error }</h1></td>
                                    </tr>
                                )}

                            </>
                        }
                        </tbody>

                        <tfoot>
                        <tr>
                            <th></th>
                            <th>Homework Title</th>
                            <th>Date</th>
                            <th>Link</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}

HomeworksTable.propTypes = {
    loadingHomeworks: PropTypes.bool,
    params: PropTypes.any,
    homeworks: PropTypes.array,
    getHomeworksError: PropTypes.string,
    getHomeworks: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    loadingHomeworks: state.homework.loadingHomeworks,
    homeworks: state.homework.homeworks,
    getHomeworksError: state.homework.getHomeworksError
})

const mapDispatchToProps = (dispatch) => ({
    getHomeworks: (sprintId) => dispatch({ type: GET_HOMEWORKS_BY_SPRINT, payload: sprintId })
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeworksTable))
