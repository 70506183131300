import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import AdminSection from "./Settings/AdminSection";
import {CLEAN_LOGIN_DATA} from "../redux/actions/auth-actions";
import {GET_OPTIONS} from "../redux/actions/preference-actions";

const Settings = ({ userInfo, getOptions }) => {

    const tabs = [
        {title: 'Sprints', disable: false, show: true, content: <AdminSection />, role: 'admin'},
        {title: 'Students', disable: true, show: true, content: <p>Test</p>, role: 'staff'}
    ]

    const [currentTabContent, setCurrentTabContent] = useState(tabs[0].content);
    const [currentTab, setCurrentTab] = useState(0)

    const onClick = (index) => {
        setCurrentTab(index)
        setCurrentTabContent(tabs[index].content)
    }

    useEffect(()=> {
        setCurrentTabContent(tabs[currentTab].content);
        getOptions();
    }, [])

    return (
        <React.Fragment>
            <h1 className={"text-5xl mb-10"}>Settings</h1>
            <div className="tabs">
                {tabs.map((tab, index) => {
                    if(tab.show && (userInfo.isAdmin && tab.role === 'admin') || (userInfo.isStaff && tab.role === 'staff')) {
                        return (
                            <a
                                key={'tab-'+index}
                                className={`tab tab-bordered ${index === currentTab ? "tab-active" : null} ${tab.disable ? 'pointer-events-none' : null}`}
                                onClick={() => onClick(index)}
                            >
                                {tab.title}
                            </a>
                        )
                    }
                })}
            </div>
            {currentTabContent}
        </React.Fragment>
    );
};

Settings.propTypes = {
    userInfo: PropTypes.object,
    getOptions: PropTypes.func
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = (dispatch) => ({
    getOptions: () => dispatch({ type: GET_OPTIONS }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
