import React from "react";
import SprintsTable from "../components/Sprints/SprintsTable";
import PropTypes from "prop-types";
import {SHOW_MODAL} from "../redux/actions/common-actions";
import {connect} from "react-redux";
import PaginationBack from "../components/elements/pagination/Pagination";
import Stat from "../components/elements/Stat";
import {GET_CATEGORIES} from "../redux/actions/sprint-actions";

class SprintsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    openModal = () => {
        this.props.showModal('createSprint')
        this.props.getCategories()
    }

    render() {
      return (
              <div className="flex flex-col drawer-content">
                  <h1 className="prose-2xl mb-2">Stat</h1>
                  <div className="w-full shadow stats mb-10">
                      <Stat />
                      <Stat />
                      <Stat />
                  </div>
                  <div className={"flex flex-row items-center mb-2"}>
                      <h1 className="prose-2xl mr-3">Sprints</h1>
                      <button onClick={this.openModal} className="btn btn-primary btn-xs">Add sprint</button>
                  </div>
                  <div className="shadow">
                      <SprintsTable />
                  </div>
                  <div className="flex flex-grow-1 justify-center mt-4">
                      <PaginationBack />
                  </div>
              </div>
      );
    }
}

SprintsContainer.propTypes = {
    modal: PropTypes.bool.isRequired,
    getCategories: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    modal: state.common.modal
})


const mapDispatchToProps = (dispatch) => ({
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
    getCategories: () => dispatch({ type: GET_CATEGORIES }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SprintsContainer)
