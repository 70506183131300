import React from 'react'
import PropTypes from "prop-types";

class FtfCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <div className="card shadow-lg compact side bg-base-100 mr-2 w-1/2">
                    <div className="flex-row justify-between space-x-4 card-body">
                        <div className="avatar placeholder">
                            <div
                                className="bg-neutral-focus text-neutral-content rounded-full w-20 h-20">
                                <span className="text-xl">{this.props.variant}</span>
                            </div>
                        </div>
                        <div className="container">
                            <h2 className="card-title">Task</h2>
                            <p className="break-all text-base-content text-opacity-40">{this.props.task}</p>
                            <div className="divider dark my-2" />
                            <h2 className="card-title">Role</h2>
                            <p className="text-base-content text-opacity-40">{this.props.role}</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

FtfCard.propTypes = {
    variant: PropTypes.string,
    task: PropTypes.string,
    role: PropTypes.string,
}

export default FtfCard
