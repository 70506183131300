import { useForm, useFormContext } from 'react-hook-form';
import React from "react";

export const withUseFormHook = WrappedComponent => props => {
    const form = useForm();

    return (
        <WrappedComponent
            {...props}
            form={form}
        />
    );
};

export const ConnectedForm = ({ children }) => {
    const methods = useFormContext();
    return children({ ...methods });
};

