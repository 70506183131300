import React from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";

import {
    SET_HOMEWORK_CONFIG,
    SET_HOMEWORK_CONFIG_MANY,
} from "../../redux/actions/configurator-actions";

import {connect} from "react-redux";
import ImageUpload from "./ImageUpload";
import {ConnectedForm} from "../../helpers/with-form-hook";

class Media extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        console.log(this.props.config)
    }

    setMediaType = (event, type) => {
        event.preventDefault()

        switch (type) {
            case 'tab-1':
                this.props.setHomeworkConfigMany([
                    {'isVideo': true},
                    {'isImage': false}
                ])
                break;
            case 'tab-2':
                this.props.setHomeworkConfigMany([
                    {'isVideo': false},
                    {'isImage': true}
                ])
                break;
            default:
                this.props.setHomeworkConfigMany([
                    {'isVideo': true},
                    {'isImage': false}
                ])
                break;
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="mb-10 h-50">
                    <div className="tabs bg-brandYellow pl-0">
                        <button
                            onClick={(event) => this.setMediaType(event, 'tab-1')}
                            className={`tab tab-bordered ${ this.props.config.isVideo ? 'tab-active' : '' }`}
                        >
                            Video
                        </button>
                        <button
                            onClick={(event) => this.setMediaType(event, 'tab-2')}
                            className={`tab tab-bordered ${ this.props.config.isImage ? 'tab-active' : '' }`}
                        >
                            Image
                        </button>
                    </div>
                    {/* Video */}
                    <div className={ `${this.props.config.isVideo ? '' : 'hidden'} mt-5` }>
                        <ConnectedForm>
                            {({ register, formState: { errors } }) =>
                                <>
                                    <div className={"flex flex-row w-full mb-3"}>
                                        <div className="form-control w-full">
                                            <input
                                                className="whitespace-pre-wrap bg-brandYellow mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                                type="text"
                                                name="videoLink"
                                                defaultValue={this.props.config.videoLink}
                                                placeholder="Link to video"
                                                {...register("videoLink", {
                                                    required: this.props.config.withMedia && this.props.config.isVideo,
                                                    onChange: (event) => {
                                                        this.props.setHomeworkConfig({'videoLink': event.target.value})
                                                    }
                                                })}
                                            />
                                            <label className="label">
                                                {errors.videoLink && <span className="label-text-alt">Data is incorrect</span>}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-control mb-2 flex-row items-center">
                                        <h3 className="mr-2 w-1/3">Start Time</h3>
                                        <input
                                            className="bg-brandYellow mt-0 mr-3 block w-1/3 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black "
                                            type="number"
                                            step="1"
                                            name="startTimeMin"
                                            defaultValue={this.props.config.startTimeMin.toString()}
                                            placeholder="Min"
                                            {...register("startTimeMin", {
                                                min: 0,
                                                onChange: (event) => {
                                                    let value = !isNaN(event.target.value) ? parseInt(event.target.value) : 0
                                                    this.props.setHomeworkConfig({'startTimeMin': value})
                                                    this.props.setHomeworkConfig({'startTime': value * 60 + this.props.config.startTimeSec})
                                                }
                                            })}
                                        />
                                        <input
                                            className="bg-brandYellow mt-0 block w-1/3 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black "
                                            type="number"
                                            step="1"
                                            name="startTimeSec"
                                            defaultValue={this.props.config.startTimeSec.toString()}
                                            placeholder="Sec"
                                            {...register("startTimeSec", {
                                                min: 0,
                                                max: 59,
                                                onChange: (event) => {
                                                    let value = !isNaN(event.target.value) ? parseInt(event.target.value) : 0
                                                    this.props.setHomeworkConfig({'startTimeSec': value})
                                                    this.props.setHomeworkConfig({'startTime' : this.props.config.startTimeMin * 60 + value})
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className="form-control mb-2 flex-row items-center">
                                        <h3 className="mr-2 w-1/3">End Time</h3>
                                        <input
                                            className="bg-brandYellow mt-0 mr-3 block w-1/3 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black "
                                            type="number"
                                            step="1"
                                            name="endTimeMin"
                                            defaultValue={this.props.config.endTimeMin.toString()}
                                            placeholder="Min"
                                            {...register("endTimeMin", {
                                                min: 0,
                                                onChange: (event) => {
                                                    let value = !isNaN(event.target.value) ? parseInt(event.target.value) : 0
                                                    this.props.setHomeworkConfig({'endTimeMin': value})
                                                    this.props.setHomeworkConfig({'endTime': value * 60 + this.props.config.endTimeSec})
                                                }
                                            })}
                                        />
                                        <input
                                            className="bg-brandYellow mt-0 block w-1/3 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                            type="number"
                                            step="1"
                                            name="endTimeSec"
                                            defaultValue={this.props.config.endTimeSec.toString()}
                                            placeholder="Sec"
                                            {...register("endTimeSec", {
                                                min: 0,
                                                max: 59,
                                                onChange: (event) => {
                                                    let value = !isNaN(event.target.value) ? parseInt(event.target.value) : 0
                                                    this.props.setHomeworkConfig({'endTimeSec': value})
                                                    this.props.setHomeworkConfig({'endTime': this.props.config.endTimeMin * 60 + value})
                                                }
                                            })}
                                        />
                                    </div>
                                </>
                            }
                        </ConnectedForm>
                    </div>

                    {/* Image */}
                    <div className={ `${this.props.config.isImage ? '' : 'hidden'} mt-5` }>
                        <ImageUpload />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Media.propTypes = {
    loading: PropTypes.bool,
    config: PropTypes.object,
    setHomeworkConfig: PropTypes.func,
    setHomeworkConfigMany: PropTypes.func
}

const mapStateToProps = (state) => ({
    config: state.config.config,
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfig: (params) => dispatch({type: SET_HOMEWORK_CONFIG, payload: params}),
    setHomeworkConfigMany: (params) => dispatch({type: SET_HOMEWORK_CONFIG_MANY, payload: params})
})

// To make those two function works register it using connect
export default connect(mapStateToProps, mapDispatchToProps)(Media)
