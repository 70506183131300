import {homeworkApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const homeworkClient = client(`${homeworkApiUrl}/api`)

const getAllSprints = async () => {
    try {
        const response = await homeworkClient.get('/sprints/')
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getSprintCategories = async () => {
    try {
        const response = await homeworkClient.get('/sprint-category/')
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const createNewSprint = async (sprint) => {
    try {
        let data = snakecaseKeys(sprint)
        const response = await homeworkClient.post('/sprints/', data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getSprint = async (id) => {
    try {
        const response = await homeworkClient.get(`/sprints/${id}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const updateSprint = async (sprint) => {
    try {
        let data = snakecaseKeys(sprint.data)
        const response = await homeworkClient.patch(`/sprints/${sprint.id}/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const deleteExistedSprint = async (id) => {
    try {
        await homeworkClient.delete(`/sprints/${id}/`)
        return id
    } catch(err) {
        return console.error(err)
    }
}

const sprintApi = {
    getAllSprints,
    getSprintCategories,
    createNewSprint,
    getSprint,
    updateSprint,
    deleteExistedSprint
}

export default sprintApi
