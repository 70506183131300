import React from 'react'
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {SHOW_MODAL} from "../../redux/actions/common-actions";
import moment from "moment";
import {UPDATE_STUDENT_PROFILE} from "../../redux/actions/students-actions";
import {Link} from "react-router-dom";

class StudentItem extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            check: false,
        };

    }

    componentDidMount() {
    }

    render() {

        const uid = this.props.student.uid
        const username = this.props.student.username
        const instagram = this.props.student.profile ? this.props.student.profile.instagram : 'not set'
        const weeks = this.props.student.balance ? this.props.student.balance.weeks : 'not set'

        const dataJoined = this.props.student.dateJoined ? moment(this.props.student.dateJoined).format('YYYY-MM-DD') : null
        const lastActivity = this.props.student.lastActivity ? moment(this.props.student.lastActivity).format('YYYY-MM-DD') : null

        return (
            <tr>
                <th>
                    <label>
                        <input type="checkbox" className="checkbox"  />
                    </label>
                </th>
                <td>
                    <div className="flex items-center space-x-3">
                        <div>
                            <div className="font-bold">{instagram && <Link className={"link link-neutral"} to={`/students/${uid}`}>{instagram}</Link>}</div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="flex items-center space-x-3">
                        <div>
                            {!instagram ? (
                                <div className="font-bold"><Link className={"link link-neutral"} to={`/students/${uid}`}>{username}</Link></div>
                            ) : (
                                <div className="font-bold">{username}</div>
                            )}
                        </div>
                    </div>
                </td>
                <td>
                    <div className="flex items-center space-x-3">
                        <div>
                            <div className="font-bold">{weeks}</div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="flex items-center space-x-3">
                        <div>
                            <div className="font-bold">{dataJoined}</div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="flex items-center space-x-3">
                        <div>
                            <div className="font-bold">{lastActivity}</div>
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

}


StudentItem.propTypes = {
    student: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    updateStudentProfile: PropTypes.func.isRequired,
    loadingUpdateProfile: PropTypes.bool
}

const mapStateToProps = (state) => ({
    loadingUpdateProfile: state.students.loadingUpdateProfile
})

const mapDispatchToProps = (dispatch) => ({
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
    updateStudentProfile: (profile) => dispatch({ type: UPDATE_STUDENT_PROFILE, payload: profile })
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentItem)
