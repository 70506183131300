import React from 'react'
import PropTypes from 'prop-types'
import moment from "moment";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {SET_CURRENT_HOMEWORK} from "../../../../redux/actions/homework-actions";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';

class HomeworkItem extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            copied: false,
            redirect: null
        };
    }

    setHomework = () => {
        this.props.setCurrentHomework(this.props.homework)
    }


    render() {
        const homework = this.props.homework
        const startDate = moment(homework.startDate).format('LL')

        const copyEffect = () => {
            this.setState({copied: true});
            setTimeout(() => {
                this.setState({copied: false});
            }, 500)
        }

        return (
            <tr>
                <th>
                    <label>
                        <input type="checkbox" className="checkbox"  />
                    </label>
                </th>
                <td>
                    <div className="flex items-center space-x-3">
                        <div>
                            <div className="font-bold">{homework.title}</div>
                            {/*<div className="text-sm opacity-50">Current</div>*/}
                        </div>
                    </div>
                </td>
                <td>{startDate}</td>
                <td>
                    <div data-tip="hello" className="tooltip tooltip-open">
                        <button className="btn">Hover me</button>
                    </div>
                    { homework.link ?
                        <>
                            <CopyToClipboard text={homework.link} onCopy={copyEffect}>
                                <button className={`btn btn-xs mr-2 ${this.state.copied ? "btn-accent" : ""}`}>Copy</button>
                            </CopyToClipboard>

                            <a className="btn btn-primary btn-xs" href={homework.link} target="_blank" rel="noreferrer">
                                Preview
                            </a>
                        </> : <>-</>
                    }

                </td>
                <td>{homework.status}</td>
                <th>
                    <Link to={`/homeworks/${this.props.homework.id}/view`}>
                        <button onClick={this.setHomework} className="btn btn-ghost btn-xs">View</button>
                    </Link>
                    <Link to={`/homeworks/${this.props.homework.id}/editor`}>
                        <button onClick={this.setHomework} className="btn btn-ghost btn-xs">Edit</button>
                    </Link>
                </th>
            </tr>
        )
    }

}


HomeworkItem.propTypes = {
    homework: PropTypes.object.isRequired,
    setCurrentHomework: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    loading: state.config.loading,
})

const mapDispatchToProps = (dispatch) => ({
    setCurrentHomework: (homework) => dispatch({ type: SET_CURRENT_HOMEWORK, payload: homework }),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeworkItem)
