let listOfPairs = (accounts, roleA, roleB) => {
    let splitAt = function(i, xs) {
        let a = xs.slice(0, i);
        let b = xs.slice(i, xs.length);
        return [a, b];
    };

    let shuffle = function(xs) {
        return xs.slice(0).sort(function() {
            return .5 - Math.random();
        });
    };

    let zip = function(xs) {

        let needVolunteer = null;

        if(accounts.length % 2 === 1){
            if(xs[1].length % 2 === 0 && xs[0].length % 2 === 1 && xs[0].length > 1) {
                needVolunteer = xs[0].pop()
            } else {
                needVolunteer = xs[1].pop()
            }
        }

        let pairs = xs[0].map(function(_,i) {
            return xs.map(function(x) {
                return x[i];
            });
        });

        return {pairs: pairs, needVolunteer: needVolunteer}
    }

    let pairsResults = zip(splitAt(accounts.length/2, shuffle(accounts)));


    pairsResults.pairs = pairsResults.pairs.map((pair) => {
        pair = {
            studentA: pair[0].account,
            studentB: pair[1].account,
        }
        return pair
    })

    if(pairsResults.needVolunteer){
        pairsResults.pairs.unshift({
            studentA: pairsResults.needVolunteer.account,
            studentB: "bluebabushka",
        })
    }

    return pairsResults
}

export default listOfPairs

