import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
// import { Switch } from '@headlessui/react'
import SprintScheduleConfirmationModal from "./modals/SprintScheduleConfirmationModal";
import {stringToBoolean} from "../../helpers/validation";
const AdminSection = ({ options }) => {

    const [enabled, setEnabled] = useState(false)
    const [openModal, setOpenModalState] = useState(false)
    const [adminOptions, setAdminOptions] = useState([])

    const [currentOption, setCurrentOption] = useState(null)

    useEffect(()=>{
        setAdminOptions(options.length > 0 ? options.filter((option) => option.section === 'admin') : [])
    }, [options])

    return (
        <React.Fragment>
            <div className="grid grid-cols-3 gap-x-5 gap-y-10 mt-5 w-full">
                {adminOptions.map((option)=> {
                    const checked = stringToBoolean(option.value)
                    if(option.code === 'sprint_schedule'){
                        return (
                            <>
                                <div key={option.id} className="card bg-base-100 shadow-md">
                                    <div className="card-body">
                                        <div className={"flex flex-row items-center justify-between mb-3"}>
                                            <h2 className="card-title text-xl font-bold">{option.name}</h2>
                                            <div className={"form-control"}>
                                                <input
                                                    type="checkbox"
                                                    onChange={() => {
                                                        setOpenModalState(true)
                                                        setCurrentOption(option)
                                                    }}
                                                    className={`toggle toggle-info`}
                                                    checked
                                                />
                                            </div>
                                        </div>
                                        <p>If the switch is on, the sprints are scheduled. To stop the sprints for a while, you must turn off the switch</p>
                                        <p>
                                            <span className={"text-error font-bold"}>Warning</span>:
                                            If you turn off the sprints, it will stop all student subscriptions. Subscriptions will resume when this switch is turned on
                                        </p>
                                    </div>
                                </div>
                                <SprintScheduleConfirmationModal
                                    openModal={openModal}
                                    setOpenModalState={setOpenModalState}
                                    enabled={checked}
                                    setEnabled={setEnabled}
                                    option={currentOption}
                                />
                            </>
                        )
                    }
                })}
            </div>
        </React.Fragment>
    );
};

AdminSection.propTypes = {
    options: PropTypes.array,
}

const mapStateToProps = (state) => ({
    options: state.preference.options,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminSection)
