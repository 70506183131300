import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard";

import FtfCard from "./FtfCard";
import FtfTable from "./FtfTable";
import PaginationBack from "../../../elements/pagination/Pagination";

import {GET_FTF_BY_SPRINT} from "../../../../redux/actions/ftf-actions";
import {SHOW_MODAL} from "../../../../redux/actions/common-actions";
import {withRouter} from "../../../../helpers/with-router";

class FtfTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            copied: false
        }
    }

    componentDidMount() {
        this.props.getFtf(this.props.params.id)
    }

    openModal(modalType) {
        this.props.showModal(modalType)
    }

    copyEffect = () => {
        this.setState({copied: true});
        setTimeout(() => {
            this.setState({copied: false});
        }, 500)
    }

    render(){

        const ftf = this.props.ftf

        return (
            <React.Fragment>
                <div className={ `${this.props.tab === 'tab-3' ? '' : 'hidden'} mt-5` }>
                    <div className={"flex flex-row items-center"}>
                        { ftf ? (
                            <h1 className="prose-2xl mb-2 mr-3">{ ftf.title }</h1>
                        ) : null }
                        <button
                            onClick={() => this.openModal('createFtf')}
                            className={`btn btn-xs mb-2 mr-3 ${ ftf && ftf.sprint !== undefined ? 'btn-brandGray btn-disabled' : 'btn-primary '}`}
                        >
                            Add FTF
                        </button>
                        <CopyToClipboard text={`${process.env.REACT_APP_STUDENT_APP_BASE_URL}/talks`} onCopy={this.copyEffect}>
                            <button className={`btn btn-xs mb-2 ${this.state.copied ? "btn-accent" : ""} ${ !ftf || ftf.sprint === undefined ? 'btn-disabled' : ''} `}
                            >
                                Copy FTF link
                            </button>
                        </CopyToClipboard>
                    </div>
                    {this.props.ftf ? (
                        <div className="flex flex-row justify-between mb-5">
                            <FtfCard variant="A" task={ftf.taskA} role={ftf.roleA}/>
                            <FtfCard variant="B" task={ftf.taskB} role={ftf.roleB}/>
                        </div>
                    ) : null}

                    <div className={"shadow"}>
                        <FtfTable />
                    </div>
                    <div className="flex flex-grow-1 justify-center mt-4">
                        <PaginationBack />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

FtfTab.propTypes = {
    tab: PropTypes.string,
    params: PropTypes.any,
    ftf: PropTypes.object,
    showModal: PropTypes.func.isRequired,
    getFtf: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    ftf: state.ftf.ftf,
})

const mapDispatchToProps = (dispatch) => ({
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
    getFtf: (sprintId) => dispatch({ type: GET_FTF_BY_SPRINT, payload: sprintId }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FtfTab))
