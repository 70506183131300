import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar";

import {mainNav, settingsNav} from "./data/navigation";
import ModalContainer from "./components/elements/modals/ModalContainer";
import Login from "./components/Login";
import SprintsContainer from "./containers/SprintsContainer";
import Profile from "./components/Profile";
import SprintContainer from "./containers/SprintContainer";
import HomeworkContainer from "./containers/HomeworkContainer";
import EditorContainer from "./containers/EditorContainer";
import DashboardContainer from "./containers/DashboardContainer";
import StudentsContainer from "./containers/StudentsContainer";
import StudentContainer from "./containers/StudentContainer";
import Player from "./components/Preview/Player";
import PrivateRoute from "./routers/PrivateRoute";
import {useAuth} from "./context/AuthContext";
import {GET_USER_INFO} from "./redux/actions/user-actions";
import Logout from "./components/Logout";
import ToastMessage from "./components/elements/toast";
import {toast} from "react-toastify";
import {ServiceWorkerUpdateListener} from "./ServiceWorkerUpdateListener";
import {FiMenu} from "react-icons/fi";
import MediaQuery from 'react-responsive';
import Settings from "./components/Settings";

const App = ({ getUserInfo, userInfo }) => {

    const [updateWaiting, setUpdateWaiting] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [swListener, setSwListener] = useState({});

    const { currentUser } = useAuth()
    const location = useLocation()
    const navigate = useNavigate()
    const pathname = location.pathname
    const [backgroundStyle, setBackgroundStyle] = useState('bg-base-100')

    useEffect(()=>{
        if(pathname === '/login'){
            setBackgroundStyle('brandYellow')
        } else {
            setBackgroundStyle('bg-base-100')
        }
    }, [pathname])

    useEffect(() => {

        if(!userInfo && currentUser){
            currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (!!idTokenResult.claims.admin || !!idTokenResult.claims.staff) {
                        getUserInfo(currentUser.uid);
                    } else {
                        toast.error(
                            <ToastMessage text={`Access denied.`} withSupportButton={true} />,
                            {autoClose: false}
                        )
                        navigate('/logout');
                    }
                })
                .catch((error) => {
                    toast.error(
                        <ToastMessage text={`An error occurred ${error.code}.`} withSupportButton={true} />,
                        {autoClose: false}
                    )
                });

        }
    }, [userInfo, currentUser])

    useEffect(() => {
        async function updater(){
            if(process.env.NODE_ENV !== "development"){
                const listner = await new ServiceWorkerUpdateListener()
                setSwListener(listner)

                listner.onupdateinstalling = (installingEvent) => {
                    console.log("SW installed", installingEvent);
                };
                listner.onupdatewaiting = (waitingEvent) => {
                    console.log("new update waiting", waitingEvent);
                    setUpdateWaiting(true);
                };
                listner.onupdateready = () => {
                    console.log("updateready event");
                    window.location.reload();
                };
                navigator.serviceWorker.getRegistration().then((reg) => {
                    listner.addRegistration(reg);
                    setRegistration(reg);
                });

                return () => listner.removeEventListener();
            }

        }
        updater()

    }, [])

    useEffect(()=>{
        if(updateWaiting){
            toast.info(<UpdateWaiting />, {
                autoClose: false,
                toastId: 'update'
            })
        } else {
            toast.dismiss('update')
        }

    }, [updateWaiting])

    const UpdateWaiting = () => {
        return (
            <div className={"flex flex-row justify-between items-center"}>
                <div>Update waiting!</div>
                <button className={"btn btn-xs bg-brandGray ml-2"} onClick={() => swListener.skipWaiting(registration.waiting)}>Update</button>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="drawer drawer-mobile">
                <input id="main-menu" type="checkbox" className="drawer-toggle" />
                <div className={`flex-grow block overflow-x-hidden ${backgroundStyle} text-base-content drawer-content`}>
                    <MediaQuery maxDeviceWidth={1224}>
                        <div className="sticky top-0 z-30 flex h-16 w-full justify-center bg-opacity-90 backdrop-blur transition-all duration-100 bg-base-100 text-base-content shadow-sm">
                            <nav className="navbar w-full">
                                <div className="flex flex-1">
                                    <label htmlFor="main-menu" className="btn btn-square btn-ghost drawer-button"><FiMenu /></label>
                                    <div className="flex items-center gap-2">
                                        <a href="/" className="px-2 flex-0 btn btn-ghost md:px-4 nuxt-link-active" aria-label="Homepage">
                                            <div className="inline-block text-xl font-title text-primary"><span className="lowercase">homework</span>
                                                <span className="uppercase text-lg text-base-content">NINJA</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </MediaQuery>
                    <div className={"p-4 lg:p-10"}>
                        <Routes>
                            {['/', '/sprints'].map((path, index) => (
                                <Route path={path} element={<PrivateRoute><SprintsContainer /></PrivateRoute>} key={index} />
                            ))}
                            <Route exact path="/login" element={<Login />} />
                            <Route exact path="/logout" element={<Logout />} />
                            <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                            <Route exact path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                            <Route exact path="/sprints/:id" element={<PrivateRoute><SprintContainer /></PrivateRoute>} />
                            <Route exact path="/homeworks/:id/view" element={<PrivateRoute><HomeworkContainer /></PrivateRoute>} />
                            <Route exact path="/homeworks/:id/editor" element={<PrivateRoute><EditorContainer /></PrivateRoute>} />
                            <Route exact path="/dashboard" element={<PrivateRoute><DashboardContainer /></PrivateRoute>} />
                            <Route exact path="/students" element={<PrivateRoute><StudentsContainer /></PrivateRoute>} />
                            <Route exact path="/students/:id" element={<PrivateRoute><StudentContainer /></PrivateRoute>} />
                            <Route exact path="/editor" element={<PrivateRoute><EditorContainer /></PrivateRoute>} />
                            <Route exact path="/player" element={<PrivateRoute><Player /></PrivateRoute>} />
                            <Route path="*" element={ <div style={{ padding: "1rem" }}>
                                        <p>There's nothing here!</p>
                                    </div>} />
                        </Routes>
                    </div>
                </div>
                <Sidebar settingsNav={settingsNav} mainNav={mainNav} />
            </div>
            <ModalContainer />
        </React.Fragment>
    )
}

App.propTypes = {
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    getUserInfo: (uid) => dispatch({ type: GET_USER_INFO, payload: uid }),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
