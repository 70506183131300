import React from 'react'
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {Link} from 'react-router-dom';

class AnswerItem extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {

        const color = () => {
            if(this.props.answer){
                let p = this.props.answer.correctAnswer / this.props.answer.allAssignments

                if (p === 1){
                    return '#AEF9C3'
                }
                if(p >= 0.5 && p < 1){
                    return '#FFDBB8'
                }
                if (p < 0.5) {
                    return '#FFB8B8'
                }
            }
        }

        return (
            <tr>
                <th>
                    <label>
                        <input type="checkbox" className="checkbox"  />
                    </label>
                </th>
                <td>
                    <div className="flex items-center space-x-3">
                        <div>
                            <div className="font-bold">{this.props.answer.student.username}</div>
                            {/*<div className="text-sm opacity-50">Current</div>*/}
                        </div>
                    </div>
                </td>
                <td style={{ background: color() }}><p>{this.props.answer.correctAnswer} of {this.props.answer.allAssignments}</p></td>
                <th>
                    <Link to={`/homeworks/${this.props.answer.homework}/student/${this.props.answer.student.username}`}>
                        <button className="btn btn-ghost btn-xs">Details</button>
                    </Link>
                </th>
            </tr>
        )
    }

}


AnswerItem.propTypes = {
    answer: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    loading: state.config.loading,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AnswerItem)
