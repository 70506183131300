import React, {useEffect} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {GET_BALANCE_HISTORY} from "../../redux/actions/students-actions";
import Loading from "../Loading";
import BalanceHistoryTable from "./BalanceHistoryTable";
import {useParams} from "react-router-dom"

const BalanceHistory = ({ balanceHistory, getBalanceHistory, loadingBalanceHistory, student  }) => {

    const { id } = useParams();

    useEffect(()=>{
        getBalanceHistory(id)
    }, [])

    if(loadingBalanceHistory){
        return <Loading />
    } else {
        return (
            <div className="w-full bg-base-100">
                <div className={"flex flex-col justify-start items-start mb-4 space-y-4"}>
                    <h2 className="inline mr-2">Balance History</h2>
                    {balanceHistory && <BalanceHistoryTable balanceHistory={balanceHistory} student={student}/>}
                </div>
            </div>

        );
    }

}

BalanceHistory.propTypes = {
    loadingBalanceHistory: PropTypes.bool,
    updateStudentBalance: PropTypes.func,
    balanceHistory: PropTypes.array,
    student: PropTypes.object,
    getBalanceHistory: PropTypes.func,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    loadingBalanceHistory: state.students.loadingBalanceHistory,
    balanceHistory: state.students.balanceHistory,
})


const mapDispatchToProps = (dispatch) => ({
    getBalanceHistory: (uid) => dispatch({ type: GET_BALANCE_HISTORY, payload: uid })
})

export default connect(mapStateToProps, mapDispatchToProps)(BalanceHistory)
