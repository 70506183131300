import {
    GET_OPTIONS_ERROR,
    GET_OPTIONS_REQUEST,
    GET_OPTIONS_SUCCESS, UPDATE_OPTION_ERROR, UPDATE_OPTION_REQUEST, UPDATE_OPTION_SUCCESS
} from "../actions/preference-actions";

import {toast} from "react-toastify";
import ToastMessage from "../../components/elements/toast";
import React from "react";

const initialState = {
    options: [],
    loadingOptions: false,
    loadingUpdateOption: false
}

export default function preferenceReducer(state = initialState, {type, payload}) {
    switch(type) {
        case GET_OPTIONS_REQUEST: {
            return {
                ...state,
                loadingOptions: true,
            }
        }

        case GET_OPTIONS_SUCCESS:
            return {
                ...state,
                options: payload,
                loadingOptions: false,
            }

        case GET_OPTIONS_ERROR:
            toast.error(<ToastMessage text={"Cant get preference options"} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                options: null,
                loadingOptions: false,
            }

        case UPDATE_OPTION_REQUEST:
            return {
                ...state,
                loadingUpdateOption: true
            }

        case UPDATE_OPTION_SUCCESS:

            return {
                ...state,
                options: state.options.map(
                    (option) => option.id === payload.id ? payload : option
                ),
                loadingUpdateOption: false
            }
        case UPDATE_OPTION_ERROR:
            toast.error(<ToastMessage text={"Cant update preference options"} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingUpdateOption: false,
            }

        default:
            return state
    }
}
