import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects'

import {
    GET_OPTIONS,
    GET_OPTIONS_ERROR,
    GET_OPTIONS_REQUEST,
    GET_OPTIONS_SUCCESS,
    UPDATE_OPTION,
    UPDATE_OPTION_ERROR,
    UPDATE_OPTION_REQUEST,
    UPDATE_OPTION_SUCCESS
} from "../actions/preference-actions";

import preferenceApi from "../api/preference-api";

function* getOptions() {

    yield put({ type: GET_OPTIONS_REQUEST })

    const response = yield call(preferenceApi.getOptions)

    if(response.status === 200) {
        yield put({type: GET_OPTIONS_SUCCESS, payload: response.data })
    } else if(response.status === 404) {
        yield put({type: GET_OPTIONS_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_OPTIONS_ERROR })
    }
}

function* updateOption({ payload }) {

    yield put({ type: UPDATE_OPTION_REQUEST })

    const response = yield call(preferenceApi.updateOption, payload)

    if (response.status === 200) {
        yield put({type: UPDATE_OPTION_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: UPDATE_OPTION_ERROR })
    }
}

export default function* commonSaga() {
    yield takeLatest(GET_OPTIONS, getOptions)
    yield takeLatest(UPDATE_OPTION, updateOption)
}
