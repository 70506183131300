import React from 'react'
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {
    SET_CURRENT_HOMEWORK,
    UPDATE_HOMEWORK,
} from "../../redux/actions/homework-actions";
import {
    CREATE_HOMEWORK_CONFIG,
    UPDATE_HOMEWORK_CONFIG,
} from "../../redux/actions/configurator-actions";

class PublishButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            buttonState: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.loadingCreateUpdateHomeworkConfig === true && this.props.loadingCreateUpdateHomeworkConfig === false){
            this.setState({
                buttonState: null
            })
        }
    }

    saveAndPublishConfig = (e) => {
        e.preventDefault();

        this.setState({
            buttonState: 'loading'
        })

        let homeworkNeedUpdate = false

        let assignment = this.props.assignment.map((row) => {
            return row.map((element) => {
                if (element.isAnswer) {
                    return {
                        color: element.color,
                        isAnswer: element.isAnswer,
                        result: "",
                        studentAnswer: "",
                        text: element.text,
                        correct: element.correct ? element.correct : null
                    }
                } else {
                    return element
                }
            })

        })

        const homework = {...this.props.currentHomework}

        let media = {}
        if(this.props.config.withMedia && this.props.config.isVideo) {
            media = {
                type: 'video',
                videoLink: this.props.config.videoLink,
                startTimeMin: this.props.config.startTimeMin,
                startTimeSec: this.props.config.startTimeSec,
                endTimeMin: this.props.config.endTimeMin,
                endTimeSec: this.props.config.endTimeSec
            }
        }

        if(this.props.config.withMedia &&this.props.config.isImage) {
            media = {
                type: 'image',
                image: this.props.config.image,
            }
        }

        let config = {
            type: this.props.config.type,
            media: media,
            background: this.props.config.background,
            assignment: assignment,
            editorRawData: this.props.editorRawData,
            homework: homework.id
        }

        if (this.props.config.isExist) {
            this.props.updateHomeworkConfig(config)
        } else {
            this.props.createHomeworkConfig(config)
        }

        if (this.props.currentHomework.title !== this.props.config.title) {
            homework.title = this.props.config.title
            homeworkNeedUpdate = true
        }

        if (!this.props.currentHomework.link) {

            homework.link = `${process.env.REACT_APP_STUDENT_APP_BASE_URL}/homework/${config.homework}`
            homework.status = 'published'
            homeworkNeedUpdate = true
        }

        if (homeworkNeedUpdate) {
            this.props.setCurrentHomework(homework)
            this.props.updateHomework(homework)
        }
    }

    render() {

        let loading = this.props.loadingCreateUpdateHomeworkConfig

        return (
            <React.Fragment>
                <button onClick={this.saveAndPublishConfig} className={`${loading && this.state.buttonState === 'loading' ? "loading" : null} btn btn-outline btn-lg`}>Publish</button>
            </React.Fragment>
        );
    }
}

PublishButton.propTypes = {
    loadingCreateUpdateHomeworkConfig: PropTypes.bool,
    currentHomework: PropTypes.object,
    title: PropTypes.string,
    homeworkId: PropTypes.string,
    assignment: PropTypes.array,
    config: PropTypes.object,
    editorRawData: PropTypes.object,
    updateHomework: PropTypes.func,
    updateHomeworkConfig: PropTypes.func,
    createHomeworkConfig: PropTypes.func,
    setCurrentHomework: PropTypes.func
}

const mapStateToProps = (state) => ({
    loadingCreateUpdateHomeworkConfig: state.config.loadingCreateUpdateHomeworkConfig,
    currentHomework: state.homework.currentHomework,
    title: state.homework.title,
    homeworkId: state.config.homeworkId,
    editorRawData: state.config.editorRawData,
    assignment: state.config.assignment,
    config: state.config.config,
})

const mapDispatchToProps = (dispatch) => ({
    updateHomework: (currentHomework) => dispatch({ type: UPDATE_HOMEWORK, payload: currentHomework }),
    setCurrentHomework: (currentHomework) => dispatch({ type: SET_CURRENT_HOMEWORK, payload: currentHomework }),
    updateHomeworkConfig: (config) => dispatch({ type: UPDATE_HOMEWORK_CONFIG, payload: config }),
    createHomeworkConfig: (config) => dispatch({ type: CREATE_HOMEWORK_CONFIG, payload: config }),
})

export default connect(mapStateToProps, mapDispatchToProps)(PublishButton)
