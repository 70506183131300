import React from 'react'

class HomeworkSkeleton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <tr>
                        <th>
                            <label>
                                <input type="checkbox" className="checkbox"  />
                            </label>
                        </th>
                        <td>
                            <div className="flex items-center">
                                <div className="animate-pulse w-24 bg-indigo-200 h-6 rounded" />
                            </div>
                        </td>
                        <td>
                            <div className="flex items-center">
                                <div className="animate-pulse w-36 bg-indigo-200 h-6 rounded" />
                            </div>

                        </td>
                        <td>
                            <div className="flex items-center">
                                <div className="animate-pulse w-10 bg-indigo-200 h-6 rounded mr-2 " />
                            </div>

                        </td>
                        <td>
                            <div className="flex items-center">
                                <div className="animate-pulse w-24 bg-indigo-200 h-6 rounded" />
                            </div>
                        </td>
                        <th>
                            <div className="flex items-center">
                                <div className="animate-pulse w-10 bg-indigo-200 h-6 rounded" />
                            </div>
                        </th>
                    </tr>
            </React.Fragment>
        );
    }
}

export default HomeworkSkeleton
